import { CalendarRecord, MedcardDocument } from 'src/common/types';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { DocumentUploadedByWhomType } from 'src/common/types/enums';
import { DocumentsList } from 'src/common/components/DocumentsList/DocumentsList';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { Plus } from 'src/common/icons';
import { Row, Typography } from 'antd';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetTmkChatMutation } from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import styles from './styles.module.scss';

interface AppointmentDocumentsModalProps {
  documents?: MedcardDocument[];
  appointment: Partial<CalendarRecord>;
  removeDocument: (docId: string) => void;
  refetch: () => void;
  deleteDocLoading: boolean;
  openFormCallback: () => void;
  idMedOrganization: string;
}

export const AppointmentDocumentsModal = ({
  documents,
  appointment,
  deleteDocLoading,
  idMedOrganization,
  removeDocument,
  refetch,
  openFormCallback,
}: AppointmentDocumentsModalProps) => {
  const navigate = useNavigate();
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const [setChat, chatInfo] = useSetTmkChatMutation();

  const clinicDocs = useMemo(
    () =>
      documents && Array.isArray(documents) && documents.length > 0
        ? documents.filter((doc) => doc.uploadedByWhomType === DocumentUploadedByWhomType.Clinic)
        : [],
    [documents],
  );

  const userDocs = useMemo(
    () =>
      documents && Array.isArray(documents) && documents.length > 0
        ? documents.filter((doc) => doc.uploadedByWhomType === DocumentUploadedByWhomType.Patient)
        : [],
    [documents],
  );

  const goToChatWithClinic = () => {
    setChat(idMedOrganization);
  };

  useEffect(() => {
    const roomId = chatInfo?.data?.roomId;
    if (roomId) navigate(`/settings/userProfile/chats?room=${roomId}`);
  }, [chatInfo?.data, navigate]);

  return (
    <Row className={styles.DocumentsModalRecordWrapper}>
      <Row>
        <Typography className={styles.DocumentsModalRecordTitle}>Загрузить документы</Typography>
      </Row>
      <Row className={styles.MilaMessageWrapper}>
        <MilaMessage title="Все загружаемые документы аккуратно сохраню в медкарту" />
      </Row>

      <Row className={styles.ClinicDocsBlock}>
        <Typography className={styles.DocumentsUploadedTitle}>Документы из клиники</Typography>
        <DocumentsList
          docsList={clinicDocs}
          appointment={appointment}
          removeDocument={removeDocument}
          refetch={refetch}
        />
        <Typography className={styles.DocumentsFromClinicText}>
          Если после приема вы не получили заключение {!whiteLabel?.isServiceEnabled ? 'в Mila' : ''} - отправьте запрос в клинику в чате
        </Typography>
        {/*<CustomButton isPrimal={true} className={styles.RequestDocuments} onClick={goToChatWithClinic}>*/}
        {/*  <Typography>Написать клинике в чат</Typography>*/}
        {/*</CustomButton>*/}
      </Row>

      <Row className={styles.UserDocsBlock}>
        <Typography className={styles.DocumentsUploadedTitle}>Документы от пользователя</Typography>

        {deleteDocLoading ? (
          <CustomSpinner />
        ) : (
          <Row className={styles.DocsListContainer}>
            <DocumentsList
              docsList={userDocs}
              appointment={appointment}
              removeDocument={removeDocument}
              refetch={refetch}
            />
          </Row>
        )}

        <CustomButton className={styles.UploadDocButton} onClick={openFormCallback}>
          <Row className={styles.AddDocButton}>
            <Plus />
            <Typography>Загрузить документ</Typography>
          </Row>
        </CustomButton>
      </Row>
    </Row>
  );
};
