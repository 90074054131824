import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface KeycloakState {
    keycloakObj?: any;
}

const initialState: KeycloakState = {
};

export const keycloakSlice = createSlice({
    name: 'keycloak',
    initialState,
    reducers: {
        setKeycloakObj: (state, action: PayloadAction<any>) => {
            state.keycloakObj = action.payload;
        },
    },
});

export const { setKeycloakObj} = keycloakSlice.actions;

export const keycloakReducer = keycloakSlice.reducer;
