import Keycloak from 'keycloak-js';
import { CachedMessagesHandler } from './common/redux/api/apiStreamSlice';

interface IKeyCloakService {
  login: (onAuthenticatedCallback: Function, onTokenExpired: Function) => Promise<void>;
  logOut: () => void;
  getUserName: () => string;
  getAuthToken: () => string | undefined;
  getAccessToken: () => string | undefined;
}

class KeyCloakServiceClass implements IKeyCloakService {
  private readonly _keycloakObj: Keycloak;
  public constructor(keycloakObj: Keycloak) {
    this._keycloakObj = keycloakObj;
  }
  public getAccessToken(): string | undefined {
    return this._keycloakObj.token;
  }

  public getAuthToken(): string | undefined {
    return this._keycloakObj.idToken;
  }

  public async login(onAuthenticatedCallback: Function, onTokenExpired: Function): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);

    this._keycloakObj.onTokenExpired = () => {
      if (this._keycloakObj.isTokenExpired(5) && !this._keycloakObj.authenticated && !this._keycloakObj.sessionId) {
        onTokenExpired();
      } else {
        this._keycloakObj.updateToken(5).then((updated) => {
          if (!updated) {
            onTokenExpired();
          }
        });
      }
    };
    
    const keycloakObj = this._keycloakObj;

    this._keycloakObj
      .init({
        idToken: urlParams.get('id_token') ?? undefined,
        token: urlParams.get('access_token') ?? undefined,
        refreshToken: urlParams.get('refresh_token') ?? undefined,
        onLoad: 'login-required',
        flow: 'standard',
        responseMode: 'query',
        enableLogging: true,
        checkLoginIframe: false,
      })
      .then(function (authenticated) {
        if (authenticated) {
          CachedMessagesHandler.getInstance('DataIsReady', keycloakObj);
          onAuthenticatedCallback();
        } else alert('non authenticated');
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`keycloak init exception: ${e}`);
      });
  }

  public logOut(): void {
    this._keycloakObj.logout({
      redirectUri: `${window.__RUNTIME_CONFIG__.REACT_APP_HOST}${!window.__RUNTIME_CONFIG__.REACT_APP_HOST.endsWith('/') ? '/' : ''}`,
    });
  }

  public getUserName(): string {
    return this._keycloakObj?.tokenParsed?.preferred_username as string;
  }
}

const KeyCloakService = (keycloakObj: any) => new KeyCloakServiceClass(keycloakObj);

export type KeyCloakServiceType = KeyCloakServiceClass;

export default KeyCloakService;
