import { ChildrenItem } from 'src/common/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import KeyCloakService from '../../../keycloak';

export const apiEsiaAuthSlice = createApi({
  reducerPath: 'milaEsiaAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}/auth/esiaauth`,
    prepareHeaders: async (headers, api) => {
      const keycloakObj = (api.getState() as any)?.keycloak?.keycloakObj;
      if (keycloakObj) {
        headers.set('Authorization', 'Bearer ' + (KeyCloakService(keycloakObj).getAuthToken() || ''));
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getChildrens: builder.query<ChildrenItem[], any>({
      query: (keycloakObj) => ({ url: `/children`, params: { accessToken: KeyCloakService(keycloakObj).getAccessToken() } }),
    }),
  }),
});

export const { useGetChildrensQuery } = apiEsiaAuthSlice;
