import { Image, Tabs, TabsProps } from 'antd';
import React from 'react';
import TabMoreIcon from '../../../assets/TabMoreIcon.svg';
import styles from './styles.module.scss';
interface Tab {
  key: string;
  label: string;
}

interface CustomTabsProps {
  items: Tab[];
  className?: string;
  containerClassName?: string;
  onChange?: (tab: string) => void;
  activeKey?: string;
}

export const CustomTabs = ({
  items,
  className,
  onChange,
  activeKey,
  containerClassName = '',
  ...props
}: CustomTabsProps & TabsProps) => {
  return (
    <div className={`${styles.TabContainer} ${containerClassName}`}>
      <Tabs
        className={className}
        items={items}
        onChange={onChange}
        activeKey={activeKey}
        moreIcon={<Image src={TabMoreIcon} preview={false} />}
        {...props}
      />
    </div>
  );
};
