import { AppointmentStatus } from '../../../common/types/enums';
import { Layout, Typography } from 'antd';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { TipAppointment } from 'src/common/types';
import styles from './styles.module.scss';

interface InfoBlockProps {
  tips?: TipAppointment[];
  status?: AppointmentStatus;
}

export const InfoBlock = ({ tips = [], status = AppointmentStatus.Upcoming }: InfoBlockProps) => (
  <Layout className={styles.PrepWrapper}>
    {status === AppointmentStatus.Past ? (
      <Typography className={styles.Title}>Итоги приема</Typography>
    ) : (
      <Typography className={styles.Title}>Подготовка к приему</Typography>
    )}
    {tips?.length &&
      tips.map((tip) => (
        <MilaMessage className={styles.Tip} title={tip.tipTitle} text={tip.tipBodyHtml} key={tip.tipTitle} />
      ))}
  </Layout>
);
