import { Avatar } from 'src/common/components/Avatar/Avatar';
import { CaretRight } from 'src/common/icons';
import { Col, List, Row, Typography } from 'antd';
import { Doctor } from 'src/common/types';
import { ListComponent } from 'src/common/components/List/List';
import { useAppSelector } from 'src/app/hooks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface DoctorListProps {
  doctors: Doctor[];
}

export const DoctorList = ({ doctors }: DoctorListProps) => {
  const navigate = useNavigate();
  const isChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[]>();

  useEffect(() => {
    if (isChildren) {
      setFilteredDoctors(
        doctors.filter(
          ({ specialitiesTags }) =>
            specialitiesTags.filter(
              ({ serveChildren, serveChildrenFrom, serveChildrenTo }) =>
                serveChildren &&
                childrenAge !== undefined &&
                serveChildrenFrom <= childrenAge &&
                serveChildrenTo >= childrenAge,
            ).length > 0,
        ),
      );
    } else {
      setFilteredDoctors(
        doctors.filter(({ specialitiesTags }) => specialitiesTags.filter(({ serveAdults }) => serveAdults).length > 0),
      );
    }
  }, [doctors, isChildren, childrenAge]);

  const handleMenuClick = (item: Doctor) => {
    navigate(`/records/aboutDoctor/${item.idMilaDoctor}`);
  };

  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={filteredDoctors?.length && filteredDoctors?.length > 0 ? filteredDoctors : []}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.SearchItem}>
          <Row className={styles.SearchRow}>
            <Col>
              <Avatar
                idProfile={item.idMilaDoctor}
                idPhoto={item.photoId}
                className={styles.DoctorPhoto}
                name={item.fio}
                isDoctor
              />
            </Col>
            <Col className={styles.TextCol}>
              <Typography className={styles.Text}>{item.fio}</Typography>
              <Row className={styles.TagRow}>
                {item.specialitiesTags.length > 0 &&
                  item.specialitiesTags.map((tag) => (
                    <Typography key={tag?.idFerSpeciality} className={styles.Tag}>
                      {tag.specialityName}
                    </Typography>
                  ))}
              </Row>
            </Col>
          </Row>
          <CaretRight className={styles.Icon} />
        </List.Item>
      )}
    />
  );
};
