import { Clinic } from 'src/common/types';
import { Placemark, useYMaps } from '@pbe/react-yandex-maps';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';

interface ClinicPlacemarkProps {
  latitude: string;
  longitude: string;
  handleClick?: (e: Event, branch: Clinic) => void;
  branch?: Clinic;
}

export const ClinicPlacemark = ({ latitude, longitude, branch, handleClick }: ClinicPlacemarkProps) => {
  const ymaps = useYMaps();

  const iconLayout = useCallback(() => {
    if (ymaps) {
      const animatedLayout = ymaps.templateLayoutFactory.createClass<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        build: () => void;
      }>(
        `<svg width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg" class=${styles.Placemark}>
        <path d="M15.3464 0C7.07649 0 0.351562 6.79646 0.351562 15.1532C0.351562 17.4488 1.30331 19.9695 2.63794 22.5898C3.97257 25.2103 5.71968 27.9113 7.46406 30.3623C10.9528 35.2648 14.4331 39.1862 14.4331 39.1862V39.1865C14.6654 39.4495 14.9977 39.6 15.3468 39.6C15.6956 39.6 16.0279 39.4495 16.2603 39.1865C16.2603 39.1865 19.7405 35.2651 23.2293 30.3626C24.9736 27.9115 26.7303 25.2104 28.0652 22.5901C29.3998 19.9696 30.3516 17.449 30.3516 15.1535C30.3516 6.79624 23.6166 0.000276194 15.3469 0.000276194L15.3464 0Z" fill="#7F49DE"/>
        <circle cx="15.8873" cy="14.464" r="4.82143" fill="white"/>
        </svg>`,
        {
          build() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (animatedLayout as any).superclass.build.call(this);
            const shape = {
              type: 'Rectangle',
              coordinates: [
                [-31, -40],
                [12, 3],
              ],
            };
            this.getData().options.set('shape', shape);
          },
        },
      );
      return animatedLayout;
    }
    return null;
  }, [ymaps]);

  const balloonLayout = useCallback(() => {
    if (ymaps && branch && handleClick) {
      const animatedLayout = ymaps.templateLayoutFactory.createClass<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        build: () => void;
      }>(
        `<div class=${styles.BalloonContainer}>
            <span class=${styles.BranchName}>${branch.shortName}</span>
          <button id="clinic-info" class=${styles.Button}>Выбрать</button>
        </div>`,
        {
          build() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (animatedLayout as any).superclass.build.call(this);
            var temp = document.getElementById('clinic-info');
            temp?.addEventListener('click', (e: Event) => {
              handleClick(e, branch);
            });
          },
        },
      );
      return animatedLayout;
    }
    return null;
  }, [branch, handleClick, ymaps]);

  return (
    <Placemark
      geometry={[latitude, longitude]}
      options={{
        iconLayout: iconLayout()!,
        balloonContentLayout: balloonLayout()!,
        balloonPanelMaxMapArea: 0,
        hideIconOnBalloonOpen: true,
      }}
    />
  );
};
