import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { Layout, Row, Typography } from 'antd';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { WaitingCard } from './WaitingCard/WaitingCard';
import { WaitingListInfo } from '../../../common/types';
import { useGetWaitingListInfoQuery } from '../../../common/redux/api/apiPatientSlice';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface WaitingListProps {
  patientId: string;
}

export const WaitingList = ({ patientId }: WaitingListProps) => {
  const {
    data: waitingListData,
    isLoading,
    isError,
  } = useGetWaitingListInfoQuery(patientId === 'all' ? '' : patientId);

  const sortedRecords = useMemo(
    () =>
      waitingListData
        ? [...waitingListData].sort((a, b) => {
            return b.created > a.created ? 1 : b.created < a.created ? -1 : 0;
          })
        : [],
    [waitingListData],
  );

  return !isError && waitingListData?.length !== 0 ? (
    <Layout className={styles.WaitingListContainer}>
      <Typography className={styles.WaitingListTitle}>Записи в лист ожидания</Typography>

      <Row>
        <MilaMessage
          title={
            'В лист ожидания попадают ваши заявки на запись, по которым мы не получили подтверждение в режиме онлайн'
          }
          text={
            'Такие заявки обрабатываются вручную администраторами клиники. Ориентируйтесь на дату и время приема, о которых вы договорились с клиникой по телефону.'
          }
          className={styles.MilaMessageBlock}
        />
      </Row>

      {isLoading ? (
        <CustomSpinner />
      ) : (
        sortedRecords.map((item: WaitingListInfo) => (
          <WaitingCard waitingRecord={item} key={item.idWaitingListAppointment} />
        ))
      )}
    </Layout>
  ) : null;
};
