import { GreenCheckIcon } from '../../icons';
import { Tag } from 'antd';
import styles from './styles.module.scss';

interface PriceTagProps {
  appointmentPaymentStatusName?: string;
  isPaid?: boolean;
  paymentType?: string;
  price?: string;
}

export const PriceTag = ({ appointmentPaymentStatusName, isPaid, paymentType, price }: PriceTagProps) => {
  return (
    <Tag
      className={isPaid ? styles.PaidPrice : paymentType === 'онлайн' ? styles.WaitingPaymentPrice : styles.Price}
      icon={isPaid ? <GreenCheckIcon className={styles.TagIcon} /> : null}
    >
      {`${paymentType === 'онлайн' ? appointmentPaymentStatusName : 'Оплата в клинике'} ${price} Р`}
    </Tag>
  );
};
