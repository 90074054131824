import { Image, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface StatisticsTabProps {
  icon: string;
  tabName: string;
  tabNumber: number;
  tabKey?: number;
  onClick?: (tabName: string, tabKey?: number) => void;
  className?: string;
  href?: string;
}

export const StatisticsTab = ({
  className = '',
  icon,
  tabName,
  tabNumber,
  tabKey,
  onClick,
  href = '',
}: StatisticsTabProps) => {
  const content = useMemo(
    () => (
      <>
        <Image preview={false} src={icon} />
        <Typography.Text className={styles.SmallText}>{tabName}</Typography.Text>
        <Typography.Text className={styles.RoundNumber}>{tabNumber}</Typography.Text>
      </>
    ),
    [icon, tabName, tabNumber],
  );
  return onClick ? (
    <Row className={`${styles.StatisticsCol} ${className}`} onClick={() => onClick && onClick(tabName, tabKey)}>
      {content}
    </Row>
  ) : (
    <Link className={`${styles.StatisticsCol} ${className}`} to={href}>
      {content}
    </Link>
  );
};
