import { ClinicList } from './ClinicList';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { DoctorList } from './DoctorList';
import { Layout, Row, Typography } from 'antd';
import { NoDataStub } from 'src/common/components/NoDataStub/NoDataStub';
import { PromoBlock } from 'src/common/components/PromoBlock/PromoBlock';
import { SearchData, Specialty } from 'src/common/types';
import { ServiceRequestType } from 'src/common/types/enums';
import { SpecialtiesList } from '../SpecialtiesChoice/SpecialtiesList/SpecialtiesList';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface SearchProps {
  data: SearchData | undefined;
  searchInput: string;
  isLoading: boolean;
}

export const GlobalSearch = ({ data, searchInput, isLoading }: SearchProps) => {
  const [onsiteSpecialties, setOnsiteSpecialties] = useState<Specialty[]>([]);
  const [onlineSpecialties, setOnlineSpecialties] = useState<Specialty[]>([]);

  useEffect(() => {
    if (data?.onSite) {
      setOnsiteSpecialties(data.onSite);
    }
    if (data?.online) {
      setOnlineSpecialties(data.online);
    }
  }, [data]);

  const resultsFound = useMemo(() => {
    let temp = false;
    for (const property in data) {
      if (data[property as keyof typeof data]?.length > 0) {
        temp = true;
      }
    }
    return temp;
  }, [data]);

  return isLoading ? (
    <CustomSpinner />
  ) : resultsFound && data ? (
    <>
      <Layout className={styles.ScrollWrapper}>
        <Layout className={styles.SearchWrapper}>
          {data.onSite.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Приём в клинике</Typography>
              </Row>
              <SpecialtiesList specialties={onsiteSpecialties} serviceType={ServiceRequestType.OnSite} />
            </>
          )}
          {data.online.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Онлайн консультации</Typography>
              </Row>
              <SpecialtiesList specialties={onlineSpecialties} serviceType={ServiceRequestType.Online} />
            </>
          )}
          {data.clinics.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Клиники</Typography>
              </Row>
              <ClinicList branches={data.clinics} />
            </>
          )}
          {data.doctors.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Врачи</Typography>
              </Row>
              <DoctorList doctors={data.doctors} />
            </>
          )}

          {data.promotions.length > 0 && (
            <>
              <Row className={styles.TitleRow}>
                <Typography className={styles.SearchTitle}>Акции</Typography>
              </Row>
              <PromoBlock promotions={data.promotions} promoClassName={styles.PromoSearch} />
            </>
          )}
        </Layout>
      </Layout>
    </>
  ) : searchInput ? (
    <NoDataStub />
  ) : (
    <></>
  );
};
