import { Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

interface Tab {
  key: string;
  label: string;
}

interface NavTabsProps {
  items: Tab[];
  basicPath: string;
  activeTabKey?: string;
}

export const NavTabs = ({ items, basicPath, activeTabKey }: NavTabsProps) => {
  const navigate = useNavigate();
  const handleTabClick = (tabName: string) => {
    const url = basicPath ? `/${basicPath}/${tabName}` : `/${tabName}`;
    navigate(url);
  };

  return (
    <Row className={styles.NavTabsRow}>
      {items.map((item) => (
        <Typography
          key={item.key}
          className={`${styles.DefaultTab} ${activeTabKey && item.key.includes(activeTabKey) && styles.ActiveTab}`}
          onClick={() => handleTabClick(item.key)}
        >
          {item.label}
        </Typography>
      ))}
    </Row>
  );
};
