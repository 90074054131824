import { Doctor, DoctorClinic, Profile, Service, Slot } from 'src/common/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ServiceRequestType } from 'src/common/types/enums';

export interface AppointmentState {
  specialtyId?: string;
  doctor?: Doctor;
  slot?: Slot;
  medOrganization?: DoctorClinic;
  service?: Service;
  appointmentId?: string;
  waitingListAppointmentId?: string;
  rescheduleAppointmentId?: string;
  patient?: Profile;
  serviceRequestType?: ServiceRequestType;
}

const initialState: AppointmentState = {};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setSpecialty: (state, action: PayloadAction<string>) => {
      state.specialtyId = action.payload;
    },
    setDoctor: (state, action: PayloadAction<Doctor>) => {
      state.doctor = action.payload;
    },
    setSlot: (state, action: PayloadAction<Slot>) => {
      state.slot = action.payload;
    },
    setMedOrganization: (state, action: PayloadAction<DoctorClinic>) => {
      state.medOrganization = action.payload;
    },

    setService: (state, action: PayloadAction<Service>) => {
      state.service = action.payload;
    },

    setAppointmentId: (state, action: PayloadAction<string>) => {
      state.appointmentId = action.payload;
    },

    setWaitingListAppointmentId: (state, action: PayloadAction<string>) => {
      state.waitingListAppointmentId = action.payload;
    },

    setRescheduleAppointmentId: (state, action: PayloadAction<string>) => {
      state.rescheduleAppointmentId = action.payload;
    },

    setPatient: (state, action: PayloadAction<Profile>) => {
      state.patient = action.payload;
    },

    setServiceRequestType: (state, action: PayloadAction<ServiceRequestType>) => {
      state.serviceRequestType = action.payload;
    },

    resetAppointmentData: () => {
      return {} as AppointmentState;
    },
  },
});

export const {
  setSpecialty,
  setDoctor,
  setSlot,
  setMedOrganization,
  setService,
  setAppointmentId,
  setWaitingListAppointmentId,
  setRescheduleAppointmentId,
  setPatient,
  setServiceRequestType,
  resetAppointmentData,
} = appointmentSlice.actions;

export const appointmentReducer = appointmentSlice.reducer;
