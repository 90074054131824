import { Link } from 'react-router-dom';
import { Popover, Typography } from 'antd';
import { RefreshCircle, RefreshGreenCircle } from 'src/common/icons';
import React from 'react';
import styles from './styles.module.scss';

interface ConfirmedCircleProps {
  className?: string;
  isConfirmed?: boolean;
  idProfile?: string;
}

export const ConfirmedCircle = ({ className, isConfirmed = false, idProfile }: ConfirmedCircleProps) => {
  const handlePopoverLink = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();
  return (
    <Popover
      content={
        <Typography className={styles.PaleText}>
          {isConfirmed ? (
            'Получение документов настроено'
          ) : (
            <>
              Получение документов не настроено. Для{' '}
              <Link to={`/consent${idProfile ? `?profileId=${idProfile}` : ''}`} onClick={handlePopoverLink}>
                профиля
              </Link>{' '}
              не подключены Госуслуги или выключен доступ на просмотр медицинских сведений
            </>
          )}
        </Typography>
      }
      trigger="hover"
    >
      {isConfirmed ? <RefreshGreenCircle className={className} /> : <RefreshCircle className={className} />}
    </Popover>
  );
};
