import { AddProfileBlock } from 'src/common/components/ProfileSidebar/AddProfileBlock/AddProfileBlock';
import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ChooseRegion } from 'src/common/components/ChooseRegion/ChooseRegion';
import { CloseModalMobileButton, MilaIconMobile, Search } from 'src/common/icons';
import { Col, Image, Layout, Row, Typography } from 'antd';
import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { CustomSearch } from 'src/common/components/CustomSearch/CustomSearch';
import { GlobalSearch } from '../GlobalSearch/GlobalSearch';
import { ImportantBlock } from './ImportantBlock/ImportantBlock';
import { Link } from 'react-router-dom';
import { MedcardBlock } from './MedcardBlock/MedcardBlock';
import { MyClinicsPreview } from './MyClinicsPreview/MyClinicsPreview';
import { MyDoctorsPreview } from './MyDoctorsPreview/MyDoctorsPreview';
import { NotificationIcon } from 'src/common/components/NotificationIcon/NotificationIcon';
import { ProfilesRow } from 'src/common/components/ProfilesRow/ProfilesRow';
import { Services } from './Services/Services';
import { StatisticsBlock } from './StatisticsBlock/StatisticsBlock';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { useAppSelector } from 'src/app/hooks';
import { useDebounce } from 'src/common/hooks/useDebounce';
import {
  useGetProfilesQuery,
  useLazyGetSearchQuery,
} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery,
} from 'src/common/redux/api/apiWhiteLabelSlice';
import keycloak from '../../keycloak';
import { adultAge } from '../../common/contants/constatns';
import styles from './styles.module.scss';

const MAX_CLINICS_SHOW = 3;
const MAX_DOCTORS_SHOW = 3;

export const Home = () => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const [searchInput, setSearchInput] = useState<string>();
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchInput?.trim() || '', 300);
  const region = useAppSelector((state) => state.filter.region);
  const isChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const { data: profiles } = useGetProfilesQuery();
  const name = useMemo(() => profiles?.find((profile) => profile.itSelf)?.name, [profiles]);
  const milaName = useMemo(() => profiles?.find((profile) => profile.itSelf)?.milaName, [profiles]);
  const isEsiaConnectedProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.isConfirmed, [profiles]);

  const [search, searchQuery] = useLazyGetSearchQuery();

  useEffect(() => {
    if (debouncedSearch) {
      search({
        query: debouncedSearch,
        region: region ?? 'Все регионы',
        serveAge: isChildren ? childrenAge : adultAge
      });
    }
  }, [debouncedSearch, region, search, isChildren, childrenAge]);

  const handleSearch = async (input: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(input.target.value);
  };

  const handleSearchClick = () => {
    setSearchInput(' ');
    setSearchOpen(true);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchInput('');
  };

  return (
    <Layout className={styles.MainWrapper}>
      {isSearchOpen && (
        <>
          <Row className={styles.SearchBackground} />
          <WhiteRow className={styles.SearchRow}>
            <CustomButton onClick={handleSearchClose} className={styles.CloseSearchButton}>
              <CloseModalMobileButton />
            </CustomButton>
            <Row className={styles.SearchFiltersRow}>
              <CustomSearch
                focus={true}
                className={styles.Search}
                floatLabel="Специализация, врач, клиника"
                value={searchInput}
                handleSearch={handleSearch}
                allowClear
              />
              <Row className={styles.RegionRow}>
                <Typography className={styles.RegionChoice}>Поиск в регионе</Typography>
                <ChooseRegion className={styles.RegionChoice} />
              </Row>

              <AgeToggle containerClassName={styles.AgeToggleContainer} />
            </Row>
            <Row className={styles.SearchWrapper}>
              <GlobalSearch
                data={searchQuery.currentData}
                isLoading={searchQuery.isFetching}
                searchInput={debouncedSearch}
              />
            </Row>
          </WhiteRow>
        </>
      )}
      <Row className={styles.NameProfileRowContainer}>
        <Row className={styles.NameRow}>
          <Col className={styles.NameCol}>
            {isWhiteLabelEnabled ? (
              <Image
                className={styles.MilaIcon}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idIconFile}`}
                preview={false}
              />
            ) : (
              <MilaIconMobile />
            )}
            <Typography className={styles.Name}>Привет, {milaName || name || keycloak(keycloakObj).getUserName()}!</Typography>
          </Col>

          <Col onClick={handleSearchOpen}>
            <Row className={styles.MobileSearchCol}>
              <NotificationIcon iconClass={styles.SearchIcon} />
              <Search className={styles.SearchIcon} onClick={handleSearchClick} />
            </Row>
            <CustomSearch
              floatLabel={'Специализация, врач, клиника'}
              handleSearch={handleSearch}
              className={styles.SpecialSearch}
              value={searchInput}
            />
          </Col>
        </Row>
        <ProfilesRow />
      </Row>
      <Row className={styles.StatsMedcardContainer}>
        <StatisticsBlock />

        <MedcardBlock className={styles.MobileHide} />
      </Row>
      <Row className={styles.ImportantBlockContainer}>
        <Typography.Title className={styles.Title}>Важное</Typography.Title>
        <ImportantBlock />
      </Row>
      <WhiteRow className={styles.ServicesRow}>
        <Typography.Title className={styles.Title}>Сервисы</Typography.Title>
        <Services arrowClass={styles.Arrows} />
      </WhiteRow>

      <MedcardBlock className={styles.MobileShow} />

      <Row className={styles.BottomRow}>
        <WhiteRow className={styles.Col}>
          <Row className={styles.ColRow}>
            <Typography.Title className={styles.Title}>Мои врачи</Typography.Title>
            <Link className={styles.Link} to="/settings/userProfile/myDoctors">
              Смотреть
            </Link>
          </Row>
          <MyDoctorsPreview maxShow={MAX_DOCTORS_SHOW} />
        </WhiteRow>
        <WhiteRow className={styles.Col}>
          <Row className={styles.ColRow}>
            <Typography.Title className={styles.Title}>Мои клиники</Typography.Title>
            <Link className={styles.Link} to="/settings/userProfile/myClinics">
              Смотреть
            </Link>
          </Row>
          <MyClinicsPreview maxShow={MAX_CLINICS_SHOW} />
        </WhiteRow>
        {!isEsiaConnectedProfile && (
          <Row className={styles.MobileBlocksRow}>
            <AddProfileBlock />
          </Row>
        )}
      </Row>
    </Layout>
  );
};
