import { Image, Layout, Row, Typography } from 'antd';
import { RubleSign } from 'src/common/icons';
import { ServiceRequestType } from 'src/common/types/enums';
import { Specialty } from 'src/common/types';
import { setServiceRequestType, setSpecialty } from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/app/hooks';
import { useNavigate } from 'react-router-dom';
import Baby from 'src/assets/SpecialtyIcons/baby.png';
import Bag from 'src/assets/SpecialtyIcons/bag.png';
import Ball from 'src/assets/SpecialtyIcons/ball.png';
import Brain from 'src/assets/SpecialtyIcons/brain.png';
import Duck from 'src/assets/SpecialtyIcons/duck.png';
import Ear from 'src/assets/SpecialtyIcons/ear.png';
import Eyes from 'src/assets/SpecialtyIcons/eyes.png';
import Flower from 'src/assets/SpecialtyIcons/flower.png';
import Gynecology from 'src/assets/SpecialtyIcons/gynecology.png';
import Hand from 'src/assets/SpecialtyIcons/hand.png';
import Head from 'src/assets/SpecialtyIcons/head.png';
import Heart from 'src/assets/SpecialtyIcons/heart.png';
import Lab from 'src/assets/SpecialtyIcons/lab.png';
import Lips from 'src/assets/SpecialtyIcons/lips.png';
import Med from 'src/assets/SpecialtyIcons/med.png';
import Med2 from 'src/assets/SpecialtyIcons/med2.png';
import Medical from 'src/assets/SpecialtyIcons/medical.png';
import Medical2 from 'src/assets/SpecialtyIcons/medical2.png';
import Operations from 'src/assets/SpecialtyIcons/operations.png';
import Operations1 from 'src/assets/SpecialtyIcons/operations 1.png';
import Ribbon from 'src/assets/SpecialtyIcons/ribbon.png';
import Soska from 'src/assets/SpecialtyIcons/soska.png';
import Stomach from 'src/assets/SpecialtyIcons/stomach.png';
import Stones from 'src/assets/SpecialtyIcons/stones.png';
import Tooth from 'src/assets/SpecialtyIcons/tooth.png';
import Tooth2 from 'src/assets/SpecialtyIcons/tooth2.png';
import Virus from 'src/assets/SpecialtyIcons/virus 1.png';
import Water from 'src/assets/SpecialtyIcons/water.png';
import Xray from 'src/assets/SpecialtyIcons/x-ray.png';
import styles from './styles.module.scss';
import А from 'src/assets/SpecialtyIcons/A.svg';
import Б from 'src/assets/SpecialtyIcons/Б.svg';
import В from 'src/assets/SpecialtyIcons/В.svg';
import Г from 'src/assets/SpecialtyIcons/Г.svg';
import Д from 'src/assets/SpecialtyIcons/Д.svg';
import Е from 'src/assets/SpecialtyIcons/Е.svg';
import Ж from 'src/assets/SpecialtyIcons/Ж.svg';
import З from 'src/assets/SpecialtyIcons/З.svg';
import И from 'src/assets/SpecialtyIcons/И.svg';
import Й from 'src/assets/SpecialtyIcons/Й.svg';
import К from 'src/assets/SpecialtyIcons/К.svg';
import Л from 'src/assets/SpecialtyIcons/Л.svg';
import М from 'src/assets/SpecialtyIcons/М.svg';
import Н from 'src/assets/SpecialtyIcons/Н.svg';
import О from 'src/assets/SpecialtyIcons/О.svg';
import П from 'src/assets/SpecialtyIcons/П.svg';
import Р from 'src/assets/SpecialtyIcons/Р.svg';
import С from 'src/assets/SpecialtyIcons/С.svg';
import Т from 'src/assets/SpecialtyIcons/Т.svg';
import У from 'src/assets/SpecialtyIcons/У.svg';
import Ф from 'src/assets/SpecialtyIcons/Ф.svg';
import Х from 'src/assets/SpecialtyIcons/Х.svg';
import Ц from 'src/assets/SpecialtyIcons/Ц.svg';
import Ч from 'src/assets/SpecialtyIcons/Ч.svg';
import Ш from 'src/assets/SpecialtyIcons/Ш.svg';
import Щ from 'src/assets/SpecialtyIcons/Щ.svg';
import Э from 'src/assets/SpecialtyIcons/Э.svg';
import Ю from 'src/assets/SpecialtyIcons/Ю.svg';
import Я from 'src/assets/SpecialtyIcons/Я.svg';

export interface SpecialtyIconProps {
  specialty: Specialty;
  color: string;
  serviceType: ServiceRequestType;
  inClinic?: boolean;
}

const specialtyLetters: Record<string, string> = {
  А: А,
  Б: Б,
  В: В,
  Г: Г,
  Д: Д,
  Е: Е,
  Ж: Ж,
  З: З,
  И: И,
  Й: Й,
  К: К,
  Л: Л,
  М: М,
  Н: Н,
  О: О,
  П: П,
  Р: Р,
  С: С,
  Т: Т,
  У: У,
  Ф: Ф,
  Х: Х,
  Ц: Ц,
  Ч: Ч,
  Ш: Ш,
  Щ: Щ,
  Э: Э,
  Ю: Ю,
  Я: Я,
};

const specialtyIcons: Record<string, string> = {
  '8': Gynecology,
  '14': Brain,
  '79': Stomach,
  '118': Heart,
  '19': Ear,
  '10': Hand,
  '20': Eyes,
  '27': Medical,
  '98': Stones,
  '171': Tooth,
  '17': Ribbon,
  '207': Soska,
  '24': Xray,
  '97': Med,
  '252': Lips,
  '30': Operations,
  '28': Operations1,
  '32': Virus,
  '59': Ball,
  '80': Lab,
  '84': Water,
  '25': Med2,
  '1': Medical2,
  '219': Bag,
  '77': Flower,
  '15': Baby,
  '221': Duck,
  '22': Head,
  '174': Tooth2,
  '81': Heart,
  '41': Ribbon,
  '11': Operations,
};

export const SpecialtyIcon = ({ specialty, color, serviceType, inClinic = false }: SpecialtyIconProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSpecialtyClick = () => {
    dispatch(setSpecialty(specialty.idFerSpeciality));
    dispatch(setServiceRequestType(serviceType));
    if (inClinic) {
      navigate(`?activeTab=doctors&specialty=${specialty.milaSpecialityName}`);
    } else {
      navigate(`/records/doctorChoice/${specialty.idFerSpeciality}`);
    }
  };

  return (
    <Row className={styles.ServiceCardRow}>
      <Layout className={styles.ServiceCard} style={{ background: color }} onClick={handleSpecialtyClick}>
        <Typography className={styles.SpecialtyName}> {specialty.milaSpecialityName}</Typography>
        <Image
          src={
            specialtyIcons[specialty.idFerSpeciality]
              ? specialtyIcons[specialty.idFerSpeciality]
              : specialtyLetters[specialty.milaSpecialityName[0]]
          }
          preview={false}
          rootClassName={styles.ServiceIcon}
        />
      </Layout>
      <Typography className={styles.Price}>
        {`от ${specialty.minServiceCost}`} <RubleSign />
      </Typography>
    </Row>
  );
};
