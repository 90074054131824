import { Switch, Typography } from 'antd';
import styles from './styles.module.scss';

interface CustomSwitchProps {
  checked?: boolean;
  disabled?: boolean;
  text?: string;
  checkedText?: string;
  onChange?: (checked: boolean) => void;
}

export const CustomSwitch = ({
  checked,
  disabled = false,
  text = 'Сделано',
  checkedText = '',
  onChange,
}: CustomSwitchProps) => {
  const switchText = checked && checkedText ? checkedText : text;

  return (
    <Switch
      className={styles.Switch}
      disabled={!!disabled}
      checked={!!checked}
      onChange={onChange}
      checkedChildren={<Typography>{switchText}</Typography>}
    />
  );
};
