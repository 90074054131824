import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CalendarRecordsList } from './CalendarRecordsList/CalendarRecordsList';
import { CreateAppointmentModalContent } from '../NewAppointment/CreateAppointmentModal/CreateAppointmentModal';
import React, { useCallback, useState } from 'react';

export const CalendarPage = () => {
  const [isCancelModalVisible, setModalVisibility] = useState(false);

  const handleAddingAppointment = useCallback(() => setModalVisibility(true), [setModalVisibility]);

  const handleCloseModal = () => {
    setModalVisibility(false);
  };

  return (
    <>
      <BackHeader title="Записи" addButtonText="Добавить запись" handleAddClick={handleAddingAppointment} />
      <CalendarRecordsList handleAddNewRecordClick={handleAddingAppointment} />
      <CreateAppointmentModalContent isOpen={isCancelModalVisible} onClose={handleCloseModal} />
    </>
  );
};
