import { CalendarRecord, MedcardCalendarYearRecord, MedcardDocument, SharedMedcardInfo } from 'src/common/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSharedSlice = createApi({
  reducerPath: 'milaSharedApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}`,
    prepareHeaders: async (headers) => {
      headers.set('X-Mila-Origin', window.location.origin);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    //queries

    getSharedMedcardInfo: builder.query<SharedMedcardInfo, string>({
      query: (link) => `/shared/medcard/${link}/info`,
    }),

    getSharedMedcardCalendar: builder.query<MedcardCalendarYearRecord[], string>({
      query: (link) => `/shared/medcard/${link}/calendar`,
    }),

    getSharedMedcardCases: builder.query<CalendarRecord[], string>({
      query: (link) => `/shared/medcard/${link}/list`,
    }),

    getSharedMedcardDocuments: builder.query<MedcardDocument[], string>({
      query: (link) => `/shared/medcard/${link}/documents`,
    }),

    getSharedMedcardDocument: builder.query<
      MedcardCalendarYearRecord[],
      { sharedId: string; idDocument: string; idFile: string }
    >({
      query: ({ sharedId, idDocument, idFile }) => ({
        url: `/shared/medcard/${sharedId}/documents/${idDocument}/attachment/${idFile}`,
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),

    getExternalSharedMedcardDocument: builder.query<
      MedcardCalendarYearRecord[],
      { sharedId: string; idDocument: string; idFile: string }
    >({
      query: ({ sharedId, idDocument, idFile }) => ({
        url: `/shared/medcard/${sharedId}/documents/external/${idDocument}/attachment/${idFile}`,
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetSharedMedcardInfoQuery,
  useGetSharedMedcardCalendarQuery,
  useGetSharedMedcardCasesQuery,
  useLazyGetSharedMedcardDocumentQuery,
  useLazyGetExternalSharedMedcardDocumentQuery,
  useGetSharedMedcardDocumentsQuery,
} = apiSharedSlice;
