import { Layout } from 'antd';

import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { DoctorReviewsBlock } from '../../../common/components/DoctorReviewsBlock/DoctorReviewsBlock';
import { useGetClinicReviewsQuery } from '../../../common/redux/api/apiPatientSlice';
import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

export const ClinicReviews = () => {
  const { clinicId } = useParams();
  const { data: reviewsInfo, isLoading } = useGetClinicReviewsQuery({ medOrganizationId: clinicId!, patientId: '' });

  const reviewsData = useMemo(
    () => ({
      ...reviewsInfo,
    }),
    [reviewsInfo],
  );

  return (
    <Layout className={styles.ClinicReviewsWrapper}>
      {isLoading ? <CustomSpinner /> : <DoctorReviewsBlock isClinicReviews reviewsList={reviewsData} />}
    </Layout>
  );
};
