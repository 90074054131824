import { Button } from 'antd';
import React, { ReactElement, useMemo } from 'react';
import styles from './styles.module.scss';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';

interface CustomButtonProps {
  isPrimal?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactElement;
  htmlType?: 'submit' | 'button';
  disabled?: boolean;
}

export const CustomButton = ({
  isPrimal = false,
  htmlType,
  onClick,
  children,
  className = '',
  disabled = false,
}: CustomButtonProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  return (
    <Button
      htmlType={htmlType}
      className={`${isPrimal ? styles.PrimalButton : styles.SecondaryButton} ${className} ${
        isWhiteLabelEnabled && isPrimal && styles.labQuestPrimary
      } `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
