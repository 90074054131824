import { Avatar } from '../../../common/components/Avatar/Avatar';
import { CaretRight } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { ReactElement } from 'react';
import styles from './styles.module.scss';

interface ProfileBlockProps {
  id: string;
  avatar?: string;
  fio?: string;
  role?: string;
  fioTextClassName?: string;
  roleTextClassName?: string;
  href?: string;
  children?: ReactElement;
}

export const ProfileBlock = ({
  id,
  avatar = '',
  fio = '',
  role = '',
  fioTextClassName,
  roleTextClassName,
  children,
}: ProfileBlockProps) => {
  const navigate = useNavigate();

  const handleDoctorClick = () => {
    navigate(`/records/aboutDoctor/${id}`);
  };

  return (
    <Row className={styles.ProfileRow}>
      <Row className={styles.SubRow}>
        <Col>
          <Avatar
            idPhoto={avatar}
            className={`${styles.Avatar} ${role === 'Пациент' ? styles.AvatarPatient : ''}`}
            name={fio}
            idProfile={id}
            isDoctor={!(role === 'Пациент')}
          />
        </Col>
        <Col>
          {id && role !== 'Пациент' ? (
            <Typography className={`${styles.DoctorFioText} ${fioTextClassName}`} onClick={handleDoctorClick}>
              {fio} <CaretRight className={styles.Caret} />
            </Typography>
          ) : (
            <Typography className={`${styles.FioText} ${fioTextClassName}`}>{fio}</Typography>
          )}

          <Typography className={`${styles.RoleText} ${roleTextClassName}`}>{role}</Typography>
        </Col>
      </Row>
      {children}
    </Row>
  );
};
