import { BottomArrow } from 'src/common/icons';
import { Select, SelectProps } from 'antd';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface SelectorProps {
  onChange?: (key: string) => void;
  placeholder?: string;
  className?: string;
  floatLabel?: string;
  inputName?: string;
  options?: { value: string; label: string }[];
  value?: string;
  children?: ReactNode;
  onClear?: () => void;
}

export const CustomSelector = ({
  onChange,
  placeholder,
  className,
  floatLabel,
  options,
  value,
  children,
  onClear,
  ...rest
}: SelectorProps & SelectProps) => (
  <FloatLabelInput label={floatLabel} value={value?.toString()} className={`${styles.FormItemWrapper} ${className}`}>
    <Select
      suffixIcon={<BottomArrow />}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      value={value}
      onClear={onClear}
      {...rest}
      allowClear
    >
      {children}
    </Select>
  </FloatLabelInput>
);
