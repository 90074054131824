import { ClinicCard } from 'src/features/Clinic/ClinicCard/ClinicCard';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Image, Row, Typography } from 'antd';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { useAppSelector } from 'src/app/hooks';
import { useGetClinicsQuery } from 'src/common/redux/api/apiPatientSlice';
import MedCase from 'src/assets/MedCase.png';
import styles from './styles.module.scss';

export const ClinicCatalog = () => {
  const region = useAppSelector((state) => state.filter.region);

  const { data: clinics, isLoading } = useGetClinicsQuery(
    {
      region: region ?? 'Все регионы',
    },
    { skip: !region },
  );

  return (
    <Row className={styles.ClinicCatalogWrapper}>
      {isLoading ? (
        <CustomSpinner />
      ) : clinics?.length && clinics?.length > 0 ? (
        clinics?.map((clinic) => <ClinicCard clinicInfo={clinic} key={clinic.idMedOrganization} />)
      ) : (
        <Row className={styles.EmptyListRow}>
          <Row className={styles.ImageRow}>
            <Image className={styles.Icon} src={MedCase} preview={false} />
          </Row>
          <Typography className={styles.Text}>Здесь будут ваши клиники</Typography>
          <MilaMessage
            className={styles.MilaMessage}
            title="Как пригласить клинику"
            hiddenText="Чтобы получать из нее свои медицинские данные "
          />
        </Row>
      )}
    </Row>
  );
};
