import { Row } from 'antd';
import { SliderArrows } from '../SliderArrows/SliderArrows';
import React, { PropsWithChildren, useRef, useState } from 'react';
import styles from './styles.module.scss';

interface SliderRowProps {
  className?: string;
  arrowsClass?: string;
  arrowsHide?: boolean;
}

export const SliderRow = ({
  children,
  className,
  arrowsClass,
  arrowsHide = false,
}: PropsWithChildren & SliderRowProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  const gotoNext = () => {
    container.current?.scrollBy(200, 0);
    setOffset(offset + 100);
  };

  const gotoPrev = () => {
    container.current?.scrollBy(-200, 0);
    setOffset(offset - 100);
  };

  return (
    <Row ref={container} className={`${styles.SliderRow} ${className}`}>
      {children}
      {!arrowsHide && (
        <SliderArrows
          gotoNext={gotoNext}
          gotoPrev={gotoPrev}
          className={arrowsClass}
          leftArrowDisable={container.current?.scrollLeft === 0}
          rightArrowDisable={
            !!container.current &&
            container.current?.offsetWidth + container.current?.scrollLeft >= container.current?.scrollWidth
          }
        />
      )}
    </Row>
  );
};
