import { Checkbox, Form, Layout, Radio, Row, Typography } from 'antd';
import { CustomDatePicker } from 'src/common/components/CustomDatePicker/CustomDatePicker';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { Gender } from 'src/common/types/enums';
import { PhoneInput } from 'src/common/components/PhoneInput/PhoneInput';
import { forwardRef, useImperativeHandle } from 'react';
import { useCreateProfileMutation } from 'src/common/redux/api/apiPatientSlice';
import { useForm } from 'antd/es/form/Form';
import FloatLabelInput from 'src/common/components/FloatLabelInput/FloatLabelInput';
import styles from './styles.module.scss';

export const PatientForm = forwardRef((props, ref) => {
  const [createProfile] = useCreateProfileMutation();

  const [form] = useForm();

  const phoneValue = Form.useWatch('phone', form);

  useImperativeHandle(
    ref,
    () => ({
      createPatient: async () => {
        await form.validateFields();
        return await createProfile({ ...form.getFieldsValue() }).unwrap();
      },
    }),
    [createProfile, form],
  );

  return (
    <Layout className={styles.PatientFormContainer}>
      <Typography className={styles.Title}>Заполните данные о пациенте</Typography>
      <Form form={form} className={styles.Form} validateTrigger={['onBlur', 'onChange']}>
        <Form.Item
          name={'surname'}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите фамилию',
            },
          ]}
        >
          <CustomInput floatLabel="Фамилия пациента*" />
        </Form.Item>
        <Form.Item
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите имя',
            },
          ]}
        >
          <CustomInput floatLabel="Имя пациента*" />
        </Form.Item>

        <Form.Item name={'middleName'}>
          <CustomInput floatLabel="Отчество пациента" />
        </Form.Item>
        <Form.Item
          name={'birthday'}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите дату рождения',
            },
          ]}
        >
          <CustomDatePicker floatLabel="Дата рождения пациента*" />
        </Form.Item>
        <Form.Item
          name={'gender'}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите пол',
            },
          ]}
        >
          <Row className={styles.GenderRow}>
            <Typography>Пол</Typography>
            <Radio.Group>
              <Radio value={Gender.Female}>Женский</Radio>
              <Radio value={Gender.Male}>Мужской</Radio>
            </Radio.Group>
          </Row>
        </Form.Item>

        <FloatLabelInput label="Телефон*" value={phoneValue}>
          <Form.Item
            name={'phone'}
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите корректный номер телефона',
                pattern: /(\d|\+\d) \(\d{3}\) \d{3}-\d{4}/,
              },
            ]}
          >
            <PhoneInput value={phoneValue} name="phone" />
          </Form.Item>
        </FloatLabelInput>
        <Form.Item
          name={'email'}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите корректный email',
              pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
            },
          ]}
        >
          <CustomInput floatLabel="E-mail*" />
        </Form.Item>
        <Form.Item
          name={'address'}
          rules={[
            {
              required: false,
              message: 'Пожалуйста, введите адрес',
            },
          ]}
        >
          <CustomInput floatLabel="Адрес пациента" />
        </Form.Item>
        <Form.Item
          name={'snils'}
          rules={[
            {
              required: false,
              message: 'Некорректный формат',
              pattern: /\d{3}-\d{3}-\d{3}-\d{2}/,
            },
          ]}
        >
          <CustomInput floatLabel="СНИЛС" mask="999-999-999-99" />
        </Form.Item>
        <Form.Item
          name={'getAgreement'}
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, подтвердите, что согласны на обработку',
            },

            () => ({
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Подтвердите согласие'))),
            }),
          ]}
        >
          <Checkbox>Получено согласие третьих лиц на передачу и обработку их персональных данных</Checkbox>
        </Form.Item>
      </Form>
    </Layout>
  );
});
