import { CustomButton } from '../../CustomButton/CustomButton';
import { Row, Typography } from 'antd';
import styles from './styles.module.scss';

interface ButtonsBlockProps {
  buttonOneText?: string;
  buttonTwoText?: string;
  buttonThreeText?: string;
  buttonOneOnClick?: () => void;
  buttonTwoOnClick?: () => void;
  buttonThreeOnClick?: () => void;
}

export const ButtonsBlock = ({
  buttonOneText,
  buttonTwoText,
  buttonThreeText,
  buttonOneOnClick,
  buttonTwoOnClick,
  buttonThreeOnClick,
}: ButtonsBlockProps) => {
  return (
    <Row className={styles.ButtonsBlock}>
      {buttonOneText && (
        <CustomButton isPrimal={true} className={styles.Button} onClick={buttonOneOnClick}>
          <Typography>{buttonOneText}</Typography>
        </CustomButton>
      )}
      {buttonTwoText && (
        <CustomButton className={styles.Button} onClick={buttonTwoOnClick}>
          <Typography>{buttonTwoText}</Typography>
        </CustomButton>
      )}
      {buttonThreeText && (
        <CustomButton className={styles.Button} onClick={buttonThreeOnClick}>
          <Typography>{buttonThreeText}</Typography>
        </CustomButton>
      )}
    </Row>
  );
};
