import { CalendarRecord, MedcardDocument } from 'src/common/types';
import { ClinicLabel } from 'src/common/components/ClinicLabel/ClinicLabel';
import { Col, Divider, Row } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { DiagnosisLabel } from 'src/common/components/DiagnosisLabel/DiagnosisLabel';
import { DoctorLabel } from 'src/common/components/DoctorLabel/DoctorLabel';
import { DocumentUploadedByWhomType } from 'src/common/types/enums';
import { Info, Plus } from 'src/common/icons';
import { MedCardCalendarIcon } from 'src/common/components/MedCardCalendarIcon/MedCardCalendarIcon';
import { MedcardDocumentLoadForm } from '../MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { useState } from 'react';
import Typography from 'antd/es/typography/Typography';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface CalendarAppointmentProps {
  appointment: CalendarRecord;
  idShared?: string;
}

export const CalendarAppointment = ({ appointment, idShared }: CalendarAppointmentProps) => {
  const [isDocumentOpen, setDocumentOpen] = useState(false);
  const [document, setDocument] = useState<MedcardDocument>();

  const handleDocumentOpen = () => {
    setDocument(undefined);
    setDocumentOpen(true);
  };
  const handleDocumentClose = () => setDocumentOpen(false);

  const handleShowDocument = (document: MedcardDocument) => {
    setDocument(document);
    setDocumentOpen(true);
  };

  return (
    <WhiteRow className={styles.CalendarAppointmentRow}>
      <Row className={styles.TopRow}>
        <MedCardCalendarIcon type={appointment.serviceDeliveryType!} />
        <Col>
          <Typography className={styles.TextGrey}>{appointment.serviceDeliveryTypeName}</Typography>
          <Row>
            <Typography className={styles.DateText}>{dayjs(appointment.created).format('DD.MM.YYYY, ddd')}</Typography>
          </Row>
        </Col>
      </Row>
      {appointment.doctor && (
        <>
          <Divider className={styles.Divider} />
          <DoctorLabel
            id={appointment.doctor.idMilaDoctor}
            name={appointment.doctor.fullName}
            specialty={appointment.doctor.specializationName.slice(
              appointment.doctor.specializationName.indexOf(')') + 1,
            )}
          />
        </>
      )}
      {appointment.medOrganization && (
        <ClinicLabel id={appointment.medOrganization.idMedOrganization} name={appointment.medOrganization.shortName} />
      )}

      {appointment.diagnosisMkb10Code && (
        <>
          <Divider className={styles.Divider} />
          <DiagnosisLabel name={appointment.diagnosisMkb10Code} />
        </>
      )}

      {appointment.documents ? (
        appointment.documents.map((document) => (
          <Row className={styles.DocumentRow}>
            <Row className={styles.FileNameRow}>
              <Typography className={styles.FileName}>{document.documentName}</Typography>
              <CustomButton className={styles.ShowButton} onClick={() => handleShowDocument(document)}>
                <Typography>Показать</Typography>
              </CustomButton>
            </Row>
            <Typography className={styles.TextGrey}>
              {dayjs(document.documentDate).format('D MMMM YYYY, dd')}
            </Typography>
            {document.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ? (
              <Typography className={styles.RoundedLabel}>Документ загружен клиникой</Typography>
            ) : (
              <Typography className={styles.RoundedLabel}>Документ загружен пользователем</Typography>
            )}
          </Row>
        ))
      ) : (
        <>
          <Divider className={styles.Divider} />
          <Typography className={`${styles.RoundedLabel} ${styles.NoDocumentRow}`}>
            <Info className={styles.WarnIcon} />
            Документ из клиники не получен
          </Typography>
        </>
      )}
      <Divider className={styles.Divider} />
      {!idShared && appointment.idAppointment && (
        <CustomButton className={styles.Button} onClick={handleDocumentOpen}>
          <Row className={styles.TopRow}>
            <Plus />
            <Typography>Добавить документ</Typography>
          </Row>
        </CustomButton>
      )}
      <CustomModal isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
        <MedcardDocumentLoadForm
          document={document}
          onFinish={handleDocumentClose}
          appointment={appointment}
          idMedcardProfile={appointment.patient?.idPatient}
          idShared={idShared}
        />
      </CustomModal>
    </WhiteRow>
  );
};
