import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Image, Layout, Row, Typography } from 'antd';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface ImportantBlockModalProps {
  modalContent: ReactNode;
  modalTitle: string;
  boldText: string;
  buttonText?: string;
  buttonHandler?: () => void;
  icon: string;
}

export const ImportantBlockModal = ({
  modalContent,
  buttonText,
  buttonHandler,
  modalTitle,
  boldText,
  icon,
}: ImportantBlockModalProps) => (
  <Layout className={styles.ModalRowWrapper}>
    <Row className={styles.ModalRow}>
      <Typography className={styles.ModalTitle}>{modalTitle}</Typography>
      <Row className={styles.ImageRow}>
        <Image src={icon} preview={false} className={styles.ModalIcon} />
      </Row>

      <MilaMessage title={boldText} textHtmlElement={modalContent} className={styles.ModalMessage} />
      {buttonText && (
        <Row className={styles.ButtonRow}>
          <CustomButton isPrimal className={styles.AddNewButton} onClick={buttonHandler}>
            <Row>
              <Typography.Text>{buttonText}</Typography.Text>
            </Row>
          </CustomButton>
        </Row>
      )}
    </Row>
  </Layout>
);
