import { Col, Image, Row, Typography, Upload, notification } from 'antd';
import { CustomButton } from '../../../common/components/CustomButton/CustomButton';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { DeleteOutlined } from '@ant-design/icons';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { WhiteRow } from '../../../common/components/WhiteRow/WhiteRow';
import { useDeleteAvatarMutation, useUploadAvatarMutation } from '../../../common/redux/api/apiPatientSlice';
import React, { memo, useEffect, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface AvatarUploadFormProps {
  isModalOpen: boolean;
  profileId?: string;
  photoId: string | null;
  refetchFunc: () => void;
  handleCloseModal: () => void;
}

type FileType = {
  type: string;
  size: number;
};

export const AvatarUploadForm = memo(
  ({ isModalOpen, handleCloseModal, refetchFunc, photoId, profileId = '' }: AvatarUploadFormProps) => {
    const [imageUrl, setImageUrl] = useState<string | null>('');
    const [newAvatar, setNewAvatar] = useState<RcFile | null>(null);
    const [isAvatarError, setAvatarError] = useState(false);
    const [uploadAvatar] = useUploadAvatarMutation();
    const [deleteAvatar] = useDeleteAvatarMutation();

    useEffect(() => setImageUrl(photoId), [photoId]);

    const handleRemoveAvatar = () => {
      setImageUrl(null);
    };

    const handleCancel = () => {
      setImageUrl(photoId);

      setNewAvatar(null);
      handleCloseModal();
    };

    const checkFile = (file: FileType) => {
      const formats = ['image/jpeg', 'image/png', 'image/jpg'];
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isFormatProper = formats.includes(file.type);
      if (!isLt5M) notification.error({ message: 'Размер фотографии не должен превышать 5МБ' });
      if (!isFormatProper) notification.error({ message: 'Формат фотографии не поддерживается' });
      if (isLt5M && isFormatProper) {
        setAvatarError(false);
      } else {
        setAvatarError(true);
      }
      return false;
    };

    const handleAvatarUpload = (event: UploadChangeParam) => {
      if (event.fileList[0]?.originFileObj) {
        setNewAvatar(event.fileList[0]?.originFileObj);
        setImageUrl(null);
      } else {
        setNewAvatar(null);
      }
    };

    const handleSave = () => {
      if (!imageUrl && !newAvatar) {
        if (photoId) {
          deleteAvatar(profileId).then(() => {
            refetchFunc();
            handleCloseModal();
          });
        } else {
          handleCloseModal();
        }
      } else if (newAvatar) {
        uploadAvatar({ profileId, file: newAvatar })
          .unwrap()
          .then(() => {
            setNewAvatar(null);
            refetchFunc();
            handleCloseModal();
          });
      }
    };

    return (
      <CustomModal isOpen={isModalOpen} className={styles.Modal} onCancelClick={handleCancel}>
        <Row className={styles.ModalWrapper}>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage
              title={'У вас отличные фотографии!'}
              text={'Выберите какую-нибудь размером до 5 Мб. Подойдут jpg, jpeg, png.'}
            />
          </Row>

          <WhiteRow className={styles.AvatarFormWrapper}>
            <Typography.Text className={styles.AvatarFormTitle}>Фото профиля</Typography.Text>
            {imageUrl ? (
              <Col className={styles.UploadAvatar}>
                <Col className={styles.RemoveAvatar}>
                  <DeleteOutlined onClick={handleRemoveAvatar} />
                </Col>
                <Image
                  preview={false}
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${imageUrl}`}
                  alt="avatar"
                />
              </Col>
            ) : (
              <Upload
                name="avatar"
                listType="picture-card"
                className={styles.AvatarUploader}
                showUploadList={isAvatarError || !newAvatar ? false : { showPreviewIcon: false }}
                accept={'.jpg, .jpeg, .png'}
                maxCount={1}
                onChange={handleAvatarUpload}
                beforeUpload={(file) => checkFile(file)}
              >
                {!newAvatar && (
                  <Row className={styles.AvatarTextWrapper}>
                    <Typography.Text className={styles.AvatarFormPrimaryText}>Выберите файл</Typography.Text>
                    <Typography.Text className={styles.AvatarFormText}>или перетащите его сюда</Typography.Text>
                  </Row>
                )}
              </Upload>
            )}
            <Row className={styles.ButtonsWrapper}>
              <CustomButton className={styles.CancelButton} onClick={handleCancel}>
                <Typography.Text>Отмена</Typography.Text>
              </CustomButton>
              <CustomButton className={styles.SaveButton} onClick={handleSave} isPrimal>
                <Typography.Text>Сохранить</Typography.Text>
              </CustomButton>
            </Row>
          </WhiteRow>
        </Row>
      </CustomModal>
    );
  },
);
