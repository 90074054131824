import { CaretRight, Location } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { RoutingButton } from 'src/common/components/RoutingButton/RoutingButton';
import styles from './styles.module.scss';

interface ClinicBlockProps {
  name?: string;
  address?: string;
  phones?: string[];
  id?: string;
  latitude?: string;
  longitude?: string;
}

export const ClinicBlock = ({ name = '', address = '', phones, id, latitude, longitude }: ClinicBlockProps) => {
  const navigate = useNavigate();

  const handleClinicClick = () => {
    navigate(`/catalog/${id}`);
  };

  return (
    <Row className={styles.ClinicBlockRow}>
      <Col>
        <Row className={styles.ClinicRow}>
          <Location className={styles.Icon} />
          <Col className={styles.ClinicCol}>
            <Typography className={styles.ClinicName} onClick={handleClinicClick}>
              {name} {<CaretRight className={styles.Caret} />}
            </Typography>
            <Typography className={styles.AddressText}>{address}</Typography>
            {phones &&
              phones.map((phone) => (
                <Link className={styles.PhoneText} to={`tel:${phone.replace(/[\s)(-]/g, '')}`} key={phone}>
                  {phone}
                </Link>
              ))}
          </Col>
        </Row>
      </Col>
      <RoutingButton latitude={latitude} longitude={longitude} />
    </Row>
  );
};
