import { Spin } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

type CustomSpinnerProps = {
  className?: string;
};

export const CustomSpinner = ({ className = '' }: CustomSpinnerProps) => {
  return <Spin className={`${styles.Spinner} ${className}`} size={'large'} />;
};
