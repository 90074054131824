import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import KeyCloakService from '../../../keycloak';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { AppointmentStatusChangeByDoctor } from '../../types/appointment.types';
import { apiPatientSlice } from './apiPatientSlice';

export const apiAppointmentSignalrSlice = createApi({
  reducerPath: 'milaAppointmentSignalrApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),

  endpoints: (builder) => ({
    getSignalr: builder.query({
      queryFn: (data: Array<AppointmentStatusChangeByDoctor>) => {
        return { data };
      },
      async onCacheEntryAdded(_, { updateCachedData, dispatch, getState }) {
        const keycloakObj = (getState() as any)?.keycloak?.keycloakObj;
        const token = KeyCloakService(keycloakObj).getAccessToken();
        const options = {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          ...(token ? { accessTokenFactory: () => token } : {}),
        };
        const connection = new HubConnectionBuilder()
          .withUrl(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/signalr`, options)
          .build();
        connection.start();
        connection.on('AppointmentStateChanged', (message) => {
          updateCachedData((currentCacheData) => {
            currentCacheData.push(message);
          });
          dispatch(apiPatientSlice.util.invalidateTags(['Appointments']));
        });
      },
    }),
  }),
});

export const { useGetSignalrQuery } = apiAppointmentSignalrSlice;
