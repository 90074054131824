import {
    WhiteLabelData,
} from '../../types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiWhiteLabelSlice = createApi({
    reducerPath: 'milaWhiteLabelApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}/patientaccount`,
        prepareHeaders: async (headers) => {            
            headers.set('X-Mila-Origin', window.location.origin);
            return headers;
        },
    }),
    tagTypes: [
    ],
    endpoints: (builder) => ({
        //queries

        getWhiteLabelInfo: builder.query<WhiteLabelData, string>({
            query: () => '/whitelabel',
        }),
    }),
});

export const {
    useGetWhiteLabelInfoQuery,
} = apiWhiteLabelSlice;
