import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Col, Divider, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomTag } from 'src/common/components/CustomTag/CustomTag';
import { DoctorLabel } from 'src/common/components/DoctorLabel/DoctorLabel';
import { HomeClinicIcon, Phone } from 'src/common/icons';
import { Link } from 'react-router-dom';
import { RoutingButton } from 'src/common/components/RoutingButton/RoutingButton';
import { WaitingListInfo } from 'src/common/types';
import { getSpecialtyName, getWaitingListCardDateLabel } from 'src/common/utils';
import React from 'react';
import styles from './styles.module.scss';

interface WaitingCardProps {
  waitingRecord: WaitingListInfo;
}

export const WaitingCard = ({ waitingRecord }: WaitingCardProps) => {
  const handleRoutingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(
      `https://yandex.ru/maps/?rtext=~${waitingRecord.medOrganization.latitude}%2C${waitingRecord.medOrganization.longitude}`,
      '_blank',
    );
  };
  return (
    <Row className={styles.CalendarWaitingListCardWrapper}>
      <Row className={styles.DateRow}>
        <Col className={styles.PatientAvatarContainer}>
          <Avatar
            idPhoto={waitingRecord.patientPhotoId}
            className={styles.Avatar}
            name={waitingRecord.patientFullName}
            idProfile={waitingRecord.idMilaUserPatient}
          />
        </Col>
        <Col className={styles.ServiceNameAndTime}>
          <Typography className={styles.ServiceName}>{waitingRecord.serviceName}</Typography>
          <Typography className={styles.Time}>Лист ожидания</Typography>
        </Col>
      </Row>
      <Divider />

      <Col className={styles.CardContentContainer}>
        <Col className={styles.ProfileCol}>
          {waitingRecord.doctor && (
            <DoctorLabel
              id={waitingRecord.doctor?.idMilaDoctor}
              name={waitingRecord.doctor?.fio}
              specialty={getSpecialtyName(waitingRecord.idFedSpecialty, waitingRecord.doctor?.specialitiesTags)}
            />
          )}
          <Row className={styles.ClinicBlockRow}>
            <HomeClinicIcon className={styles.ClinicIcon} />
            <Col className={styles.ClinicNameBlock}>
              <Link to={`/catalog/${waitingRecord.medOrganization.idMedOrganization}`}>
                {waitingRecord.medOrganization.shortName}
              </Link>
              <Typography className={styles.AddressText}>{waitingRecord.medOrganization.address}</Typography>
            </Col>
            <Col onClick={handleRoutingClick}>
              <RoutingButton
                latitude={waitingRecord.medOrganization.latitude}
                longitude={waitingRecord.medOrganization.longitude}
              />
            </Col>
          </Row>
          <CustomTag className={styles.CustomTag}>{`Заявка передана в клинику ${getWaitingListCardDateLabel(
            waitingRecord.created,
          )}`}</CustomTag>
        </Col>
        <Col className={styles.PhoneButtonWrapper}>
          {waitingRecord.medOrganization.phones?.map((phone) => (
            <CustomButton className={styles.PhoneButton} key={phone}>
              <>
                <Phone />
                <Typography.Link href={`tel:${phone}`} className={styles.PhoneNumber}>
                  {phone}
                </Typography.Link>
              </>
            </CustomButton>
          ))}
        </Col>
      </Col>
    </Row>
  );
};
