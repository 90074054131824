import { BriefcaseBig, CaretRight, Note, Play } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { ServiceRequestType } from 'src/common/types/enums';
import { setServiceRequestType } from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/app/hooks';
import {useGetAboutClinicInfoQuery} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery
} from 'src/common/redux/api/apiWhiteLabelSlice';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { MilaService } from '../../../../common/types';

interface ServiceTypeChoiceProps {
  idMedOrganization?: string;
}

export const ServiceTypeChoice = ({ idMedOrganization }: ServiceTypeChoiceProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: clinicAboutInfo } = useGetAboutClinicInfoQuery(idMedOrganization!, { skip: !idMedOrganization });
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const items = useMemo(() => {
    let tempArr = [];
    if ((!clinicAboutInfo || clinicAboutInfo.inClinicSpecializations.length > 0) && (!whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.OnSite))) {
      tempArr.push({
        icon: <Note />,
        text: 'Записаться на прием в клинику',
        key: ServiceRequestType.OnSite,
      });
    }
    if ((!clinicAboutInfo || clinicAboutInfo.onlineSpecializations.length > 0) && (!whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.Online))) {
      tempArr.push({
        icon: <Play />,
        text: 'Записаться на онлайн-консультацию',
        key: ServiceRequestType.Online,
      });
    }
    if ((!clinicAboutInfo || clinicAboutInfo.atHomeSpecializations.length > 0) && (!whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.AtHome))) {
      tempArr.push({
        icon: <BriefcaseBig />,
        text: 'Вызвать врача на дом',
        key: ServiceRequestType.AtHome,
      });
    }
    return tempArr;
  }, [clinicAboutInfo]);

  const handleClick = (key: ServiceRequestType) => {
    dispatch(setServiceRequestType(key));
    navigate(`/records/specialtyChoice${idMedOrganization ? `?branchId=${idMedOrganization}` : ''}`);
  };

  return (
    <Row className={styles.ServiceTypesWrapper}>
      {items.map((service) => (
        <CustomButton className={styles.ServiceTypeButton} key={service.key} onClick={() => handleClick(service.key)}>
          <Row className={styles.ServiceTypeRow}>
            <Col className={styles.ServiceTypeSubCol}>
              {service.icon}
              <Typography className={styles.ServiceTypeText}>{service.text}</Typography>
            </Col>
            <Col className={styles.ServiceTypeCaretCol}>
              <CaretRight className={styles.ServiceTypeCaret} />
            </Col>
          </Row>
        </CustomButton>
      ))}
    </Row>
  );
};
