import { Divider, Row, Typography } from 'antd';

import { Clinic } from '../../../common/types';
import { ClinicContacsBlock } from '../../../common/components/ClinicContactsBlock/ClinicContacsBlock';
import { CreateAppointmentModalContent } from '../../NewAppointment/CreateAppointmentModal/CreateAppointmentModal';
import { CustomButton } from '../../../common/components/CustomButton/CustomButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useSetTmkChatMutation } from '../../../common/redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';

interface ClinicPromoCardProps {
  clinic: Clinic;
}

export const ClinicPromoCard = ({ clinic }: ClinicPromoCardProps) => {
  const navigate = useNavigate();
  const [setChat, chatInfo] = useSetTmkChatMutation();
  const [isCancelModalVisible, setModalVisibility] = useState(false);

  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
  };

  const handleGetTmkChat = useCallback(
    (idMedOrganization: string) => () => {
      setChat(idMedOrganization);
    },
    [setChat],
  );

  const buttons = useMemo(
    () => (
      <>
        {/*<CustomButton className={styles.ChatButton} onClick={handleGetTmkChat(clinic.idMedOrganization)}>*/}
        {/*  <Typography>Чат с клиникой</Typography>*/}
        {/*</CustomButton>*/}
        <CustomButton isPrimal={true} className={styles.AppointmentButton} onClick={handleOpenModal}>
          <Typography>Записаться</Typography>
        </CustomButton>
      </>
    ),
    [clinic.idMedOrganization, handleGetTmkChat],
  );

  useEffect(() => {
    const roomId = chatInfo?.data?.roomId;
    if (roomId) navigate(`/settings/userProfile/chats?room=${roomId}`);
  }, [chatInfo?.data, navigate]);

  return (
    <Row className={styles.BlockRow}>
      <Row>
        <Typography className={styles.ClinicName}>{clinic.shortName}</Typography>
      </Row>
      <Divider className={styles.Divider} />
      <Row className={styles.CardContentWrapper}>
        <ClinicContacsBlock clinic={clinic} />
        <Row className={styles.ButtonsWrapper}>{buttons}</Row>
      </Row>
      <CreateAppointmentModalContent isOpen={isCancelModalVisible} onClose={handleCloseModal} clinic={clinic} />
    </Row>
  );
};
