import { CaretDown } from 'src/common/icons';
import { Col, Collapse, Divider, Layout, Row, Timeline, Typography } from 'antd';
import { DoctorInfo } from 'src/common/types';
import { FileItem } from 'src/common/components/FileItem/FileItem';
import { useMemo } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export const DoctorEducation = ({ educations, about, documents }: DoctorInfo) => {
  const timelineItmes = useMemo(
    () =>
      educations.map((education) => ({
        children: (
          <Row className={styles.EducationDescriptionRow} key={education.specialization}>
            <Col className={styles.EducationYearCol}>
              <Typography className={styles.Year}>{education.year}</Typography>
            </Col>
            <Col className={styles.EducationDescriptionCol}>
              <Typography className={styles.Type}>{education.specialization}</Typography>
              <Typography className={styles.Description}>{education.description}</Typography>
            </Col>
          </Row>
        ),
        color: '#E79E44',
      })),
    [educations],
  );
  return (
    <Layout className={styles.DoctorEducationWrapper}>
      <Typography>{about}</Typography>
      <Divider />
      {educations.length > 0 && (
        <Col className={styles.EducationCol}>
          <Typography className={styles.Title}>Образование</Typography>
          <Timeline items={timelineItmes} />
        </Col>
      )}
      <Collapse
        expandIconPosition={'end'}
        ghost
        className={styles.DocumentsCollapse}
        expandIcon={({ isActive }) =>
          isActive ? <CaretDown /> : <CaretDown style={{ transform: 'rotate(180deg)' }} />
        }
      >
        <Collapse.Panel className={styles.SubTitle} header="Документы" key="1">
          <Row className={styles.DocsContainer}>
            {documents.map((document) => (
              <FileItem fileName={document.name} fileId={document.staticFileId} key={document.staticFileId} />
            ))}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Layout>
  );
};
