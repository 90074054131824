import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Image, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import HelpPict from 'src/assets/Help.png';
import React from 'react';
import styles from './styles.module.scss';

export const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const goToHelp = () => {
    navigate('/help');
  };

  return (
    <Row className={styles.ErrorPageContainer}>
      <BackHeader title="Что-то пошло не так" />
      <Image className={styles.ErrorPageImage} src={HelpPict} preview={false} />
      <Typography className={styles.ErrorPageTitle}>Тут что-то пошло не так</Typography>
      <Typography className={styles.ErrorPageDescription}>Уже знаю об этом и спешу все исправить </Typography>
      <CustomButton isPrimal className={styles.ErrorButton} onClick={goToHelp}>
        <Typography>Написать в техподдержку</Typography>
      </CustomButton>
      <Typography className={styles.ErrorPageText}>
        Предлагаю пока вернуться{' '}
        <Link
          className={styles.ErrorPageLink}
          to=""
          onClick={(e) => {
            handleGoBack(e);
          }}
        >
          {' '}
          назад{' '}
        </Link>
        или на
        <Link className={styles.ErrorPageLink} to="/home">
          {' '}
          главную{' '}
        </Link>
      </Typography>
    </Row>
  );
};
