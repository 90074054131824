import { Layout } from 'antd';

import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { DoctorReviewsBlock } from '../../../common/components/DoctorReviewsBlock/DoctorReviewsBlock';
import { useGetDoctorReviewsQuery } from '../../../common/redux/api/apiPatientSlice';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface DoctorReviewsProps {
  doctorId: string;
}

export const DoctorReviews = ({ doctorId }: DoctorReviewsProps) => {
  const { data: doctorReviewsInfo, isLoading } = useGetDoctorReviewsQuery(doctorId!);

  const reviewsData = useMemo(
    () => ({
      ...doctorReviewsInfo,
    }),
    [doctorReviewsInfo],
  );

  return (
    <Layout className={styles.DoctorReviewsWrapper}>
      {isLoading ? <CustomSpinner /> : <DoctorReviewsBlock reviewsList={reviewsData} />}
    </Layout>
  );
};
