import { ClinicLabel } from 'src/common/components/ClinicLabel/ClinicLabel';
import { Col, Divider, Image, Row, Typography } from 'antd';
import { Cross, GreenCheckIcon } from 'src/common/icons';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { DiagnosisLabel } from 'src/common/components/DiagnosisLabel/DiagnosisLabel';
import { DoctorLabel } from 'src/common/components/DoctorLabel/DoctorLabel';
import { DocumentUploadedByWhomType } from 'src/common/types/enums';
import { MedcardDocument } from 'src/common/types';
import { MedcardDocumentLoadForm } from '../MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { getIconByDocumentType } from '../MedCardDocuments/MedCardDocuments';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface MedCardDocumentProps {
  document: MedcardDocument;
  idShared?: string;
}

export const MedCardDocument = ({ document, idShared }: MedCardDocumentProps) => {
  const [isDocumentOpen, setDocumentOpen] = useState(false);

  const handleShowDocument = useCallback(() => {
    setDocumentOpen(true);
  }, []);

  const accessLabel = useMemo(
    () =>
      document.isPublic ? (
        <Typography className={`${styles.RoundedLabel} ${styles.GreenBorder}`}>
          <GreenCheckIcon className={styles.Icon} />
          Доступ открыт
        </Typography>
      ) : (
        <Typography className={`${styles.RoundedLabel} ${styles.RedBorder}`}>
          <Cross className={styles.Icon} />
          Доступ закрыт
        </Typography>
      ),
    [document],
  );

  const showButton = useMemo(
    () => (
      <CustomButton className={styles.DocumentButton} onClick={handleShowDocument}>
        <Typography>Показать документ</Typography>
      </CustomButton>
    ),
    [handleShowDocument],
  );
  
  const getDiagnosis = (document: any) => {
      let diagnosis;
      if (document.diagnosisMkb10Code && document.diagnosisMkb10Code !== 'undefined') {
          diagnosis = document.diagnosisMkb10Code;
      }
      
      if (document.diagnosisName?.length > 0 && document.diagnosisName !== 'undefined') {
          diagnosis = diagnosis?.length > 0 ? `${diagnosis} ${document.diagnosisName}` : document.diagnosisName;
      }
      
      return diagnosis;
  };

  const handleDocumentClose = () => setDocumentOpen(false);

  return (
    <WhiteRow className={styles.DocumentWrapper}>
      <Row className={styles.TitleRow}>
        <Row className={styles.Subrow}>
          <Col className={styles.IconCol}>
            <Image src={getIconByDocumentType(document.documentType)} preview={false} />
          </Col>
          <Col>
            <Typography className={styles.SmallText}>
              {dayjs(document.documentDate).format('D MMMM YYYY, ddd')}
            </Typography>
            <Typography className={styles.Title}>{document.documentName}</Typography>
          </Col>
        </Row>

        <Row className={styles.AccessDesktop}>{accessLabel}</Row>
      </Row>
      <Divider className={styles.Divider} />
      <Row className={styles.TitleRow}>
        <Col className={styles.DocumentContainer}>
          {document.doctorFullName && document.doctorFullName !== 'undefined' && (
            <DoctorLabel
              name={document.doctorFullName}
              specialty={document.doctorSpecialtyName?.slice(document.doctorSpecialtyName.indexOf(')') + 1)}
            />
          )}
          {getDiagnosis(document)?.length > 0 && (
            <DiagnosisLabel name={getDiagnosis(document)} />
          )}
          {document.medOrganizationName && document.medOrganizationName !== 'undefined' && (
            <ClinicLabel name={document.medOrganizationName} />
          )}
          {document && (
            <>
              {document.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ? (
                <Typography className={styles.RoundedLabel}>Документ загружен клиникой</Typography>
              ) : (
                <Typography className={styles.RoundedLabel}>Документ загружен пользователем</Typography>
              )}
              <Row className={styles.AccessMobile}>{accessLabel}</Row>
              <Col className={styles.ShowButtonMobile}>
                <Divider className={styles.Divider} />
                {showButton}
              </Col>
            </>
          )}
        </Col>
        <Col className={styles.ShowButtonDesktop}>{showButton}</Col>
      </Row>
      <CustomModal className='UploadDocWide' isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
        <MedcardDocumentLoadForm document={document} onFinish={handleDocumentClose} idShared={idShared} />
      </CustomModal>
    </WhiteRow>
  );
};
