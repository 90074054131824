import { EsiaLoginServiceResult } from '../../../types';
import { externalWindowResult, openExternalProportionalWindow } from 'src/common/utils/window';
import { getEsiaLoginUrl } from './api/loginWithEsia';
import React from 'react';

const esiaLoginEndMessage = 'mila-esia-login-end-message';

const EsiaLoginService = {
  Login: async (cancelRef?: React.MutableRefObject<(() => void) | undefined>): Promise<string> => {
    const wnd = openExternalProportionalWindow();

    if (!wnd) {
      throw new Error();
    }

    try {
      wnd.location.href = await getEsiaLoginUrl();

      const { accessToken } = await externalWindowResult<EsiaLoginServiceResult>(wnd, esiaLoginEndMessage, cancelRef);

      return accessToken;
    } catch (e) {
      wnd.close();
      throw e;
    }
  },
};

export default EsiaLoginService;
