import { CalendarAppointment } from '../CalendarAppointment/CalendarAppointment';
import { CalendarRecord, MedcardCalendarYearRecord, MedcardRecommedation } from 'src/common/types';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Layout, Row, Typography } from 'antd';
import { MedCardCalendar } from '../MedCardCalendar/MedCardCalendar';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { Recommendation } from '../Recommendation/Recommendation';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface AnalysisProps {
  calendarData?: MedcardCalendarYearRecord[];
  calendarFetching?: boolean;
  calendarCases?: CalendarRecord[];
  calendarCasesFetching?: boolean;
  recommendations?: MedcardRecommedation[];
  recommendationsFetching?: boolean;
  idShared?: string;
}

export const Analysis = ({
  calendarData,
  calendarCases,
  recommendations,
  calendarFetching = false,
  calendarCasesFetching = false,
  recommendationsFetching = false,
  idShared,
}: AnalysisProps) => {
  const [showAppointments, setShowAppointments] = useState(false);
  const [filteredCalendarCases, setFilteredCalendarCases] = useState<CalendarRecord[]>([]);

  useEffect(() => {
    if (calendarCases) {
      setFilteredCalendarCases(calendarCases);
    }
  }, [calendarCases]);

  const handleShowAppointments = () => {
    setShowAppointments(!showAppointments);
  };

  const handleCalendarClick = (year: number, month: number) => {
    if (calendarCases) {
      setShowAppointments(true);
      setFilteredCalendarCases(
        calendarCases?.filter((calendarCase) => {
          let date = dayjs(calendarCase.created);
          return date.get('year') === year && date.get('month') + 1 === month;
        }),
      );
    }
  };

  return (
    <Layout className={styles.AnalysisWrapper}>
      <Typography.Title level={4} className={styles.Title}>
        Календарь обращений
      </Typography.Title>
      <Typography.Text className={styles.TreatmentRow}>
        Всего {calendarCases?.length} обращений
        <Typography.Text className={styles.ShowAll} onClick={handleShowAppointments}>
          {showAppointments ? 'Скрыть' : 'Раскрыть все'}
        </Typography.Text>
      </Typography.Text>

      <Row className={styles.CalendarRow}>
        {calendarFetching ? (
          <CustomSpinner />
        ) : (
          <MedCardCalendar data={calendarData} onCalendarClick={handleCalendarClick} />
        )}
        <Row className={styles.CasesRow}>
          {showAppointments &&
            (calendarCasesFetching ? (
              <CustomSpinner />
            ) : (
              filteredCalendarCases &&
              filteredCalendarCases.map((appointment, key) => (
                <CalendarAppointment
                  appointment={appointment}
                  key={appointment.idAppointment ? appointment.idAppointment : key}
                  idShared={idShared}
                />
              ))
            ))}
        </Row>
      </Row>

      <Typography.Title level={3} className={`${styles.Title} ${styles.RecommendationsTitle}`}>
        Рекомендации и назначения
      </Typography.Title>

      <Row className={styles.RecommendationsRow} id="RecommendationsBlock">
        {recommendationsFetching ? (
          <CustomSpinner />
        ) : recommendations && recommendations.length > 0 ? (
          recommendations.map((recommendation) => <Recommendation {...recommendation} key={recommendation.name} />)
        ) : (
          <MilaMessage
            title="Рекомендации и назначения не найдены"
            text="Или их нет, или клиника передала данные в неструктурированном виде "
          />
        )}
      </Row>
    </Layout>
  );
};
