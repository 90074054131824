import { CalendarRecord } from '../../types';
import { CalendarRecordCard } from '../CalendarRecordCard/CalendarRecordCard';
import { Col, Row, Typography } from 'antd';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { NoRecordsDump } from '../../../features/CalendarPage/NoRecordsDump';
import { capitalizeFirstLetter, groupRecordsByDate, sortCalendarRecords } from '../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface RecordsWrapperProps {
  selectedRecordType: string;
  records?: CalendarRecord[];
  isLoading: boolean;
  refetchData: () => void;
  handleAddNewRecordClick?: () => void;
  handleNext: () => void;
  maxCount: number;
}

export const RecordsWrapper = ({
  selectedRecordType,
  handleAddNewRecordClick,
  records,
  refetchData,
  isLoading,
  handleNext,
  maxCount,
}: RecordsWrapperProps) => {
  const formattedRecords = useMemo(() => {
    const sortedRecords = sortCalendarRecords(records, selectedRecordType === 'past');
    const groupedRecords = groupRecordsByDate(sortedRecords);
    return groupedRecords;
  }, [records, selectedRecordType]);

  return (
    <>
      {isLoading ? (
        <CustomSpinner />
      ) : formattedRecords.length !== 0 && records ? (
        <InfiniteScroll
          dataLength={records.length}
          loader={<CustomSpinner />}
          next={handleNext}
          hasMore={formattedRecords && records ? records.length < maxCount : false}
          className={styles.RecordsScroll}
        >
          {formattedRecords.map((formattedRecord, index) => (
            <Row className={styles.RecordCardsContainer} key={index}>
              <Col className={styles.CalendarCardTitleWrapper}>
                <Typography className={styles.CalendarCardTitle}>
                  {capitalizeFirstLetter(formattedRecord.date.format('dd, D MMMM YYYY'))}
                </Typography>
              </Col>
              <Row className={styles.SameDateRecordsContainer}>
                {formattedRecord.recordsData?.map((record) => (
                  <CalendarRecordCard appointment={record} refetchData={refetchData} key={record.idAppointment} />
                ))}
              </Row>
            </Row>
          ))}
        </InfiniteScroll>
      ) : (
        <NoRecordsDump addNewRecordClick={handleAddNewRecordClick} />
      )}
    </>
  );
};
