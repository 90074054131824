import { Avatar } from '../Avatar/Avatar';
import { ConfirmedCircle } from '../ConfirmedCircle/ConfirmedCircle';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { Link } from 'react-router-dom';
import { Plus } from 'src/common/icons';
import { Row, Typography } from 'antd';
import { getProfileNameLabel, sortProfilesByItself } from '../../utils';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import styles from './styles.module.scss';

export const ProfilesRow = () => {
  const { data: profiles, isLoading } = useGetProfilesQuery();
  const sortedProfiles = useMemo(() => sortProfilesByItself(profiles!), [profiles]);

  return (
    <Row className={styles.ProfilesRow}>
      <Row className={styles.AddProfile}>
        <Link to="/settings/userProfile">
          <Plus className={styles.PlusIcon} />
        </Link>
      </Row>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          {sortedProfiles?.map((profile) => (
            <Link
              to={`/settings/userProfile/${profile.idProfile}`}
              className={styles.ProfileLink}
              key={profile.idProfile}
            >
              <Row className={styles.AvatarWrapper}>
                <Avatar
                  idPhoto={profile.idPhoto}
                  className={styles.Avatar}
                  idProfile={profile?.idProfile!}
                  name={`${profile?.surname} ${profile.name}`}
                />
                <ConfirmedCircle
                  className={styles.Circle}
                  isConfirmed={profile.isConfirmed && profile.isDataReceptionAllowed}
                />
              </Row>
              <Typography.Text className={styles.UserName}>{getProfileNameLabel(profile)}</Typography.Text>
            </Link>
          ))}
        </>
      )}
    </Row>
  );
};
