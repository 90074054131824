import { RenderPageProps, Viewer, Worker } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import { Typography } from 'antd';
import { pdfWorkerUrl } from 'src/common/contants/constatns';
import { CustomButton } from '../CustomButton/CustomButton';

import styles from './styles.module.scss';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props {
  url: string;
}

export const PdfViewer = ({ url }: Props) => {
  const renderPageOfDocument = (props: RenderPageProps) => {
    return <>
        {props.canvasLayer.children}
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>;
  };

  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;

  return (
    <Worker workerUrl={pdfWorkerUrl}>
      <Viewer
        theme="light" 
        fileUrl={url} 
        renderPage={renderPageOfDocument} 
        plugins={[printPluginInstance]}
      />         
      <CustomButton
        isPrimal={true}
        className={`${styles.PrintBtn} ${styles.SaveButton}`}
        onClick={print}
      >
        <Typography>Распечатать</Typography>
      </CustomButton>
    </Worker>
  );
};
