import { CustomAutocompleteSearch } from '../CustomAutocompleteSearch/CustomAutocompleteSearch';
import { CustomModal } from '../CustomModal/CustomModal';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { Layout, Row, Typography } from 'antd';
import { setRegion } from 'src/common/redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetRegionsQuery } from 'src/common/redux/api/apiPatientSlice';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

type ChooseRegionProps = {
  className?: string;
};

export const ChooseRegion = React.memo(({ className = '' }: ChooseRegionProps) => {
  const dispatch = useAppDispatch();
  const region = useAppSelector((state) => state.filter.region);

  const { data: regions, isFetching } = useGetRegionsQuery();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const searchOptions = useMemo(
    () =>
      regions
        ? regions.map((region, index) => ({
            key: index.toString(),
            value: region,
            label: region,
          }))
        : [],
    [regions],
  );

  useEffect(() => {
    if (regions && !region) {
      dispatch(setRegion('Все регионы'));
    }
  }, [dispatch, region, regions]);

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const handleRowSetValue = (value: string) => () => {
    dispatch(setRegion(value));
    setSearchValue('');
    handleCloseModal();
  };

  const handleSetValue = (value: string) => {
    dispatch(setRegion(value));
    setSearchValue('');
    handleCloseModal();
  };

  return (
    <>
      <Typography.Text className={`${styles.Title} ${className}`} onClick={handleOpenModal}>
        {region || 'Все регионы'}
      </Typography.Text>
      <CustomModal isOpen={isShowModal} onCancelClick={handleCloseModal}>
        <Layout className={styles.Card}>
          <Row>
            <Typography.Title level={2} className={styles.CardTitle}>
              Выберите регион поиска
            </Typography.Title>
          </Row>
          <Row>
            <CustomAutocompleteSearch
              className={styles.Search}
              floatLabel="Города и регионы, где работает сервис"
              inputName="cities"
              value={searchValue}
              options={searchOptions}
              onChange={handleSearchValue}
              handleChangeValue={handleSetValue}
            />
          </Row>
          {region && (
            <Row className={styles.ChosenCity}>
              <Typography.Text>{region}</Typography.Text>
            </Row>
          )}
          {isFetching ? (
            <CustomSpinner />
          ) : (
            <Row className={styles.Cities}>
              {region && region !== 'Все регионы' && <Row className={styles.City} onClick={handleRowSetValue('Все регионы')}>
                <Typography.Text>Все регионы</Typography.Text>
              </Row>}
              {regions &&
                regions
                  .filter((item) => item !== region)
                  .map((region) => (
                    <Row className={styles.City} onClick={handleRowSetValue(region)} key={region}>
                      <Typography.Text>{region}</Typography.Text>
                    </Row>
                  ))}
            </Row>
          )}
        </Layout>
      </CustomModal>
    </>
  );
});
