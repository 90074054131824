import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { Row, Typography } from 'antd';
import { useEffect } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export interface ButtonsBlockProps {
  acceptButtonText?: string;
  declineButtonText?: string;
  isAcceptButtonDisabled?: boolean;
  isDeclineButtonDisabled?: boolean;
  acceptOnClick?: () => void;
  declineOnClick?: () => void;
  isFirstBlock?: boolean;
}

export const ButtonsBlock = ({
  acceptButtonText,
  declineButtonText,
  isAcceptButtonDisabled,
  isDeclineButtonDisabled,
  acceptOnClick,
  declineOnClick,
  isFirstBlock = false,
}: ButtonsBlockProps) => {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!isFirstBlock) {
      timer = setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 0);
    } else {
      timer = setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 2000);
    }
    return () => clearTimeout(timer);
  }, [isFirstBlock]);

  return (
    <Row className={styles.ButtonsWrapper}>
      {declineButtonText && !isAcceptButtonDisabled && (
        <Row className={styles.DeclineButtonWrapper}>
          <CustomButton
            className={`${styles.DeclineButton} ${isDeclineButtonDisabled && styles.DisabledButton}`}
            onClick={declineOnClick}
            disabled={isDeclineButtonDisabled}
          >
            <Typography className={styles.DeclineButtonText}>{declineButtonText}</Typography>
          </CustomButton>
        </Row>
      )}
      {acceptButtonText && !isDeclineButtonDisabled && (
        <Row className={styles.AcceptButtonWrapper}>
          <CustomButton
            isPrimal
            className={`${styles.AcceptButton} ${isAcceptButtonDisabled && styles.DisabledButton}`}
            onClick={acceptOnClick}
            disabled={isAcceptButtonDisabled}
          >
            <Typography className={styles.AcceptButtonText}>{acceptButtonText}</Typography>
          </CustomButton>
        </Row>
      )}
    </Row>
  );
};
