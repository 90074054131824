import { Input, InputRef } from 'antd';
import InputMask from 'react-input-mask';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

type OnChangeEventType =
  | ChangeEvent<HTMLInputElement>
  | React.KeyboardEvent<HTMLElement>
  | { target: HTMLInputElement };

interface PhoneInputProps {
  value?: string;
  onChange?: (e: OnChangeEventType) => void;
  name: string;
  className?: string;
  defaultMask?: string;
  disabled?: boolean;
}

export const PhoneInput = React.memo(
  ({ value, onChange, name, className = '', defaultMask = '+9 (999) 999-9999', disabled = false }: PhoneInputProps) => {
    const [phoneMask, setPhoneMask] = useState(defaultMask);
    const phoneRef = useRef<InputRef>(null);
    const [pos, setPos] = useState(0);
    const [isNeedChangeMask, setIsNeedChangeMask] = useState(false);

    const handleChangePhoneMask = (e: React.KeyboardEvent<HTMLElement>) => {
      const target = e.target as HTMLInputElement;
      if (e.key === '+' && target.selectionStart === 0) {
        setIsNeedChangeMask(true);
        handleOnChange(e);
      }
    };

    const handleOnChange = useCallback(
      (e: OnChangeEventType) => {
        const target = e.target as HTMLInputElement;
        let value = target.value.replace('+', '');
        if (isNeedChangeMask) {
          setPhoneMask('+9 (999) 999-9999');
          setIsNeedChangeMask(false);
          value = value[0] === '_' ? `+7${value.slice(1, value.length)}` : `+7${value.slice(1, value.length)}`;
          setPos(4);
        } else if (target.value.indexOf('+8') > -1) {
          setPhoneMask('9 (999) 999-9999');
          setPos(3);
        } else if (target.value[0] === '7') {
          setPhoneMask('+9 (999) 999-9999');
          setPos(4);
        } else if (value[0] === '_') {
          value = phoneMask[0] === '+' ? `+_${value.slice(1, value.length)}` : `_${value.slice(0, value.length)}`;
        } else {
          value = phoneMask[0] === '+' ? `+7${value.slice(1, value.length)}` : `8${value.slice(1, value.length)}`;
        }
        if (onChange) onChange({ ...e, ...{ target: { ...target, value } } });
      },
      [isNeedChangeMask, onChange, phoneMask],
    );

    useEffect(() => {
      if (value && phoneRef.current?.input) {
        if (value[0] === '+' && phoneMask[0] !== '+') setPhoneMask('+9 (999) 999-9999');
        handleOnChange({ target: phoneRef.current?.input });
      }
      if (pos) {
        setTimeout(() => phoneRef.current?.setSelectionRange(pos, pos, 'forward'), 0);
        setPos(0);
      }
    }, [value, isNeedChangeMask, pos, phoneMask, handleOnChange]);

    return (
      <InputMask
        mask={phoneMask}
        value={value}
        name={name}
        onChange={handleOnChange}
        onKeyPress={handleChangePhoneMask}
        disabled={disabled}
      >
        <Input ref={phoneRef} disabled={disabled} className={`${styles.input} ${className}`} name="phone" />
      </InputMask>
    );
  },
  (oldProps, newProps) => oldProps.value === newProps.value,
);
