import { Tag } from 'antd';
import styles from './styles.module.scss';

interface CancelAppointmentTagProps {
  price?: string;
  isMoneyBack: boolean;
  isTmk?: boolean;
  canceledPayment: boolean;
}

export const CancelAppointmentTag = ({
  price = '',
  isMoneyBack = true,
  isTmk = false,
  canceledPayment = false,
}: CancelAppointmentTagProps) => {
  if (isTmk && !canceledPayment) {
    return <Tag className={styles.TagWrapper}>{`Отменена, ${isMoneyBack ? 'возврат' : 'списание'} ${price} Р`}</Tag>;
  } else {
    return <Tag className={styles.TagWrapper}>{`Отменена`}</Tag>;
  }
};
