import { Calendar } from 'src/common/icons';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import React from 'react';
import styles from './styles.module.scss';

interface DatePickerProps {
  onChange?: (val: Dayjs | null) => void;
  className?: string;
  placeholder?: string;
  value?: Dayjs | null;
  disabled?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  floatLabel?: string;
  inputName?: string;
}

export const CustomDatePicker = ({
  onChange,
  className = '',
  placeholder,
  value,
  disabled,
  disabledDate,
  floatLabel = '',
}: DatePickerProps) => (
  <FloatLabelInput label={floatLabel} value={value?.toString()} className={`${styles.FormItemWrapper} ${className}`}>
    <DatePicker
      placeholder={placeholder}
      onChange={onChange}
      suffixIcon={<Calendar />}
      value={value || undefined}
      disabled={disabled}
      allowClear={false}
      disabledDate={disabledDate}
      format={'DD.MM.YYYY'}
    />
  </FloatLabelInput>
);
