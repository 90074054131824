import { CaretRight, RubleSign } from 'src/common/icons';
import { Col, List, Row, Typography } from 'antd';
import { ListComponent } from 'src/common/components/List/List';
import { ServiceRequestType } from 'src/common/types/enums';
import { Specialty } from 'src/common/types';
import { getDoctorsWithLabel } from 'src/common/utils';
import { setServiceRequestType, setSpecialty } from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

interface SpecialtiesListProps {
  specialties: Specialty[];
  serviceType: ServiceRequestType;
  listItemClass?: string;
}

export const SpecialtiesList = ({ specialties, serviceType, listItemClass }: SpecialtiesListProps) => {
  const [searchParams] = useSearchParams();
  const branchId = useMemo(() => (searchParams.get('branchId') ? searchParams.get('branchId')! : ''), [searchParams]);
  const dispatch = useAppDispatch();
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const navigate = useNavigate();
  const [filteredSpecialties, setFilteredData] = useState<Specialty[]>([]);

  useEffect(() => {
    setFilteredData(specialties);
  }, [childrenAge, specialties]);

  const handleMenuClick = (item: Specialty) => {
    dispatch(setSpecialty(item.idFerSpeciality));
    dispatch(setServiceRequestType(serviceType));
    navigate(`/records/doctorChoice/${item.idFerSpeciality}${branchId && `?branchId=${branchId}`}`);
  };

  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={filteredSpecialties}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={`${styles.ListItem} ${listItemClass}`}>
          <Row className={styles.SpecialtyRow}>
            <Col className={styles.LeftCol}>
              <Typography className={styles.SpecialtyName}>{item.milaSpecialityName}</Typography>
              <Row className={styles.InnerRow}>
                <Typography className={styles.DoctorAmount}>{getDoctorsWithLabel(item.doctorCount)}</Typography>
                {/* <Col className={styles.TagContainer}>
                    <CustomTag className={styles.DateTag}>{getDateLabel(item.earliestDate)}</CustomTag>
                  </Col> */}
              </Row>
            </Col>
            <Col className={styles.RightCol}>
              <Typography className={styles.Price}>
                от {item.minServiceCost}
                <RubleSign className={styles.Ruble} />
                <CaretRight className={styles.Caret} />
              </Typography>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};
