import { Col, Divider, Row, Typography } from 'antd';
import { WhiteRow } from '../WhiteRow/WhiteRow';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

import { ReactNode } from 'react';

import { PaymentTag } from './PaymentTag';

interface PaymentCardProps {
  paymentDate: ReactNode;
  paymentStatus: string;
  price: number;
  medOrganizationName: string;
  paymentDescription: string;
  serviceDescription: string;
  isMoneyBack?: boolean;
}

export const PaymentCard = ({
  paymentDate,
  paymentStatus,
  price,
  medOrganizationName,
  paymentDescription,
  serviceDescription,
  isMoneyBack = false,
}: PaymentCardProps) => (
  <WhiteRow className={styles.CardContainer}>
    <Row className={styles.TitleRow}>
      <Col className={styles.CardTextBlock}>
        <Typography className={styles.TitleText}>{paymentDate}</Typography>
        <Typography className={styles.GreySmallText}>{`${
          isMoneyBack ? 'из клиники' : 'в клинику'
        }  ${medOrganizationName}`}</Typography>
      </Col>
      <Col>
        <PaymentTag status={paymentStatus} price={price} />
      </Col>
    </Row>
    <Divider className={styles.Divider} />

    <Row className={styles.CardContent}>
      <Typography className={styles.GreySmallText}>{paymentDescription}</Typography>
      <Typography className={styles.ServiceDescriptionText}>{serviceDescription}</Typography>
    </Row>
  </WhiteRow>
);
