import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Layout } from 'antd';
import { PromoBlock } from '../../../common/components/PromoBlock/PromoBlock';
import { useGetPromotionsQuery } from 'src/common/redux/api/apiPatientSlice';
import styles from './styles.module.scss';

interface PromoBlockProps {
  doctorId: string;
}

export const PromoBlockDoctor = ({ doctorId }: PromoBlockProps) => {
  const { data: promos, isLoading } = useGetPromotionsQuery({ idMilaDoctor: doctorId });

  return (
    <Layout className={styles.PromoBlockWrapper}>
      {isLoading ? <CustomSpinner /> : <PromoBlock promotions={promos!} />}
    </Layout>
  );
};
