import { Row } from 'antd';
import React, { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

interface WhiteRowProps extends PropsWithChildren {
  className?: string;
}

export const WhiteRow = ({ children, className = '' }: WhiteRowProps) => (
  <Row className={`${className} ${styles.WhiteRow} `}>{children}</Row>
);
