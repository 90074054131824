import { Input } from 'antd';

import { Rule } from 'antd/es/form';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import InputMask from 'react-input-mask';
import React, { ChangeEvent, useMemo } from 'react';
import styles from './styles.module.scss';

interface CustomInputProps {
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  floatLabel?: string;
  inputName?: string;
  isTextArea?: boolean;
  textAreaRows?: number;
  floatLabelClassName?: string;
  rules?: Rule[];
  mask?: string;
}

export const CustomInput = ({
  placeholder,
  value,
  onChange,
  floatLabel,
  floatLabelClassName = '',
  inputName,
  className = '',
  disabled,
  isTextArea = false,
  textAreaRows = 4,
  mask,
}: CustomInputProps) => {
  const input = useMemo(
    () =>
      isTextArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          className={`${styles.textArea} ${className}`}
          rows={textAreaRows}
          name={inputName}
          disabled={disabled}
        />
      ) : mask ? (
        <InputMask
          mask={mask}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          name={inputName}
        >
          <Input className={`${styles.input} ${className}`} name="phone" />
        </InputMask>
      ) : (
        <Input
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
          value={value}
          name={inputName}
          className={`${styles.input} ${className}`}
        />
      ),
    [isTextArea, placeholder, onChange, value, className, textAreaRows, inputName, disabled, mask],
  );

  return (
    <FloatLabelInput label={floatLabel || ''} value={value} className={floatLabelClassName}>
      {input}
    </FloatLabelInput>
  );
};
