import { useCallback } from 'react';

export const useServiceWorker = () => {
  const registerServiceWorker = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`);
        console.log('Service Worker registered with scope:', registration.scope);
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    }
  }, []);

  const askNotificationPermission = useCallback(async () => {
    return Notification.requestPermission();
  }, []);

  return { registerServiceWorker, askNotificationPermission };
};
