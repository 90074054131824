import { createPortal } from 'react-dom';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  content: React.ReactNode;
  iFrameId: string;
};

export const PrintedIFrame = ({ content, iFrameId }: Props) => {
  const [contentRef, setContentRef] = useState<any>(null);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe className={styles.PrintedIFrame} id={iFrameId} name={iFrameId} title={iFrameId} ref={setContentRef}>
      {mountNode && createPortal(<>{content}</>, mountNode)}
    </iframe>
  );
};
