import './App.scss';
import 'dayjs/locale/ru';
import { ConfigProvider, Layout } from 'antd';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { RoutingComponent } from './Routing';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { YMaps } from '@pbe/react-yandex-maps';
import { persistor, store } from './store';
import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import ruRU from 'antd/lib/locale/ru_RU';
import updateLocale from 'dayjs/plugin/updateLocale';
import { YMInitializer } from 'react-yandex-metrika';
import { useGetWhiteLabelInfoQuery } from '../common/redux/api/apiWhiteLabelSlice';

dayjs.locale('ru');

function App() {
  dayjs.extend(updateLocale);
  dayjs.extend(isYesterday);

  dayjs.updateLocale('ru', {
    monthsShort: ['Янв.', 'Февр.', 'Марта', 'Апр.', 'Мая', 'Июня', 'Июля', 'Авг.', 'Сент.', 'Окт.', 'Нояб.', 'Дек.'],
  });

  const { data: whiteLabel, isLoading } = useGetWhiteLabelInfoQuery('');

  const [themeColor, setThemeColor] = useState('#7f49de');
  const [themeButtonColor, setThemeButtonColor] = useState('#7f49de');
  const [textColor, setTextColor] = useState('#1f2c84');
  const [buttonTextColor, setButtonTextColor] = useState('#1f2c84');

  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const whiteLabelManifestJSON = {
    short_name: whiteLabel?.displayName, //most likely should be implemented on mila practice side
    name: whiteLabel?.displayName, //most likely should be implemented on mila practice side
    icons: [
      {
        src: `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`,
        sizes: '128x128 64x64 32x32 24x24 29x29 20x20 16x16',
        type: 'image/png',
        purpose: 'any maskable',
      },
    ],
    start_url: './',
    scope: '.',
    display: 'fullscreen',
    theme_color: '#ffffff',
    background_color: '#ffffff',
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', themeColor);
    document.documentElement.style.setProperty('--primary-button-color', themeButtonColor);
    document.documentElement.style.setProperty('--primary-button-text-color', buttonTextColor);
    document.documentElement.style.setProperty('--primary-dark-color', textColor);
  }, [themeColor, textColor, themeButtonColor, buttonTextColor]);

  useEffect(() => {
    if (whiteLabel && isWhiteLabelEnabled) {
      if (whiteLabel.linksColor) {
        setThemeColor(whiteLabel.linksColor);
      }
      if (whiteLabel.mainButtonColor.backgroundColor) {
        setThemeButtonColor(whiteLabel.mainButtonColor.backgroundColor);
      }
      if (whiteLabel.mainButtonColor.textColor) {
        setButtonTextColor(whiteLabel.mainButtonColor.textColor);
      }
      if (whiteLabel.headersColor) {
        setTextColor(whiteLabel.headersColor);
      }
      if (whiteLabel.domain && whiteLabel.domain.length > 0) {
        window.__RUNTIME_CONFIG__.REACT_APP_HOST = `${whiteLabel.domain}${whiteLabel.milaPatientPortalPath && whiteLabel.milaPatientPortalPath.length > 0 ? whiteLabel.milaPatientPortalPath : ''}`;
      }
    }
  }, [whiteLabel, isWhiteLabelEnabled]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if (whiteLabel && isWhiteLabelEnabled) {
      link.href = `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`;
    }
  }, [whiteLabel, isWhiteLabelEnabled]);

  useEffect(() => {
    if (!isLoading) {
      if (whiteLabel && isWhiteLabelEnabled) {
        const stringManifest = JSON.stringify(whiteLabelManifestJSON);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#manifest-placeholder')?.setAttribute('href', manifestURL);
        setWhiteLabelAppleIcons();
        setWhiteLabelStartUpAppleImage();
      } else {
        document.querySelector('#manifest-placeholder')?.setAttribute('href', `${process.env.PUBLIC_URL}/manifest.json`);
        setDefaultAppleIcons();
        setDefaultStartUpAppleImage();
      }
    }
  }, [whiteLabel, isWhiteLabelEnabled, isLoading]);
  
  const setDefaultAppleIcons = () => {
    updateAppleIcon('apple-icon-57', `${process.env.PUBLIC_URL}/pwa/57.png`);
    updateAppleIcon('apple-icon-60', `${process.env.PUBLIC_URL}/pwa/60.png`);
    updateAppleIcon('apple-icon-72', `${process.env.PUBLIC_URL}/pwa/72.png`);
    updateAppleIcon('apple-icon-76', `${process.env.PUBLIC_URL}/pwa/76.png`);
    updateAppleIcon('apple-icon-114', `${process.env.PUBLIC_URL}/pwa/114.png`);
    updateAppleIcon('apple-icon-120', `${process.env.PUBLIC_URL}/pwa/120.png`);
    updateAppleIcon('apple-icon-144', `${process.env.PUBLIC_URL}/pwa/144.png`);
    updateAppleIcon('apple-icon-152', `${process.env.PUBLIC_URL}/pwa/152.png`);
    updateAppleIcon('apple-icon-192', `${process.env.PUBLIC_URL}/pwa/192.png`);
    updateAppleIcon('apple-icon-512', `${process.env.PUBLIC_URL}/pwa/512.png`);
  };

  const setWhiteLabelAppleIcons = () => {
    updateAppleIcon('apple-icon-57', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-60', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-72', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-76', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-114', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-120', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-144', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-152', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-192', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-icon-512', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
  };
  
  const setDefaultStartUpAppleImage = () => {
    updateAppleIcon('apple-splash-screen-1', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Pro_Max_landscape.png`);
    updateAppleIcon('apple-splash-screen-2', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Pro_landscape.png`);
    updateAppleIcon('apple-splash-screen-3', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png`);
    updateAppleIcon('apple-splash-screen-4', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png`);
    updateAppleIcon('apple-splash-screen-5', `${process.env.PUBLIC_URL}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png`);
    updateAppleIcon('apple-splash-screen-6', `${process.env.PUBLIC_URL}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png`);
    updateAppleIcon('apple-splash-screen-7', `${process.env.PUBLIC_URL}/splash_screens/iPhone_11__iPhone_XR_landscape.png`);
    updateAppleIcon('apple-splash-screen-8', `${process.env.PUBLIC_URL}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png`);
    updateAppleIcon('apple-splash-screen-9', `${process.env.PUBLIC_URL}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.png`);
    updateAppleIcon('apple-splash-screen-10', `${process.env.PUBLIC_URL}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png`);
    updateAppleIcon('apple-splash-screen-11', `${process.env.PUBLIC_URL}/splash_screens/9__iPad_Pro_landscape.png`);
    updateAppleIcon('apple-splash-screen-12', `${process.env.PUBLIC_URL}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png`);
    updateAppleIcon('apple-splash-screen-13', `${process.env.PUBLIC_URL}/splash_screens/10.9__iPad_Air_landscape.png`);
    updateAppleIcon('apple-splash-screen-14', `${process.env.PUBLIC_URL}/splash_screens/10.5__iPad_Air_landscape.png`);
    updateAppleIcon('apple-splash-screen-15', `${process.env.PUBLIC_URL}/splash_screens/10.2__iPad_landscape.png`);
    updateAppleIcon('apple-splash-screen-16', `${process.env.PUBLIC_URL}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png`);
    updateAppleIcon('apple-splash-screen-17', `${process.env.PUBLIC_URL}/splash_screens/8.3__iPad_Mini_landscape.png`);
    updateAppleIcon('apple-splash-screen-18', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Pro_Max_portrait.png`);
    updateAppleIcon('apple-splash-screen-19', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Pro_portrait.png`);
    updateAppleIcon('apple-splash-screen-20', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png`);
    updateAppleIcon('apple-splash-screen-21', `${process.env.PUBLIC_URL}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png`);
    updateAppleIcon('apple-splash-screen-22', `${process.env.PUBLIC_URL}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png`);
    updateAppleIcon('apple-splash-screen-23', `${process.env.PUBLIC_URL}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png`);
    updateAppleIcon('apple-splash-screen-24', `${process.env.PUBLIC_URL}/splash_screens/iPhone_11__iPhone_XR_portrait.png`);
    updateAppleIcon('apple-splash-screen-25', `${process.env.PUBLIC_URL}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png`);
    updateAppleIcon('apple-splash-screen-26', `${process.env.PUBLIC_URL}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png`);
    updateAppleIcon('apple-splash-screen-27', `${process.env.PUBLIC_URL}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png`);
    updateAppleIcon('apple-splash-screen-28', `${process.env.PUBLIC_URL}/splash_screens/12.9__iPad_Pro_portrait.png`);
    updateAppleIcon('apple-splash-screen-29', `${process.env.PUBLIC_URL}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png`);
    updateAppleIcon('apple-splash-screen-30', `${process.env.PUBLIC_URL}/splash_screens/10.9__iPad_Air_portrait.png`);
    updateAppleIcon('apple-splash-screen-31', `${process.env.PUBLIC_URL}/splash_screens/10.5__iPad_Air_portrait.png`);
    updateAppleIcon('apple-splash-screen-32', `${process.env.PUBLIC_URL}/splash_screens/10.2__iPad_portrait.png`);
    updateAppleIcon('apple-splash-screen-33', `${process.env.PUBLIC_URL}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png`);
    updateAppleIcon('apple-splash-screen-34', `${process.env.PUBLIC_URL}/splash_screens/8.3__iPad_Mini_portrait.png`);
  };

  const setWhiteLabelStartUpAppleImage = () => {
    updateAppleIcon('apple-splash-screen-1', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-2', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-3', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-4', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-5', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-6', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-7', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-8', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-9', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-10', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-11', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-12', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-13', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-14', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-15', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-16', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-17', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-18', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-19', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-20', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-21', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-22', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-23', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-24', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-24', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-25', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-26', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-27', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-28', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-29', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-30', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-31', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-32', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-33', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
    updateAppleIcon('apple-splash-screen-34', `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idFaviconFile}`);
  };
  
  const updateAppleIcon = (iconId: any, iconPath: any) => {
    let link = document.getElementById(iconId) as HTMLLinkElement;
    if (link) {
      link.href=iconPath;
    }
  };

  return (
    <PersistGate loading={null} persistor={persistor}>
      <YMInitializer
        accounts={[96307853, 96143494]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
      />
      <ConfigProvider
        locale={ruRU}
        theme={{
          token: {
            colorPrimary: themeColor,
            colorText: whiteLabel && isWhiteLabelEnabled ? textColor : '#15181F',
            colorInfo: '#15181F',
            fontFamily: 'Inter',
          },
          hashed: false,
        }}
      >
        <YMaps
          query={{
            ns: 'nameSpace',
            load: 'Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,templateLayoutFactory',
            apikey: window.__RUNTIME_CONFIG__.REACT_APP_YANDEX_API_KEY,
          }}
        >
          <Suspense
            fallback={
              <Layout>
                <CustomSpinner />
              </Layout>
            }
          >
            <RoutingComponent />
          </Suspense>
        </YMaps>
      </ConfigProvider>
    </PersistGate>
  );
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWrapper;
