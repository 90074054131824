import { CustomModal } from '../CustomModal/CustomModal';
import {
  MedicalAgreement as MilaMedicalAgreement,
  NotificationsAgreement as MilaNotificationsAgreement,
  PersonalDataAgreement as MilaPersonalDataAgreement,
} from './AgreementsTexts';
import { idMedicalDataAccessConsent, idNotificationsAgreementConsent } from '../../contants/constatns';
import { useMemo } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';
import { Typography } from 'antd';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';

export interface AgreementModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  consentId: string;
}

export const AgreementModal = ({ consentId, handleCloseModal, isModalOpen }: AgreementModalProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  // драфт, исправить на актуальные тексты согласий после добавления в запрос /whitelabel
  const agreementTexts = {
    medicalAgreementText: 'Согласие пользователя WL',
    notificationsAgreementText: 'Согласие пользователя на уведомления',
    personalAgreementText: 'Согласие пользователя перс данные',
  };

  const MedicalAgreement = isWhiteLabelEnabled ? (
    <Typography className={styles.AgreementTitleText}>{agreementTexts.medicalAgreementText}</Typography>
  ) : (
    MilaMedicalAgreement
  );

  const NotificationsAgreement = isWhiteLabelEnabled ? (
    <Typography className={styles.AgreementTitleText}>{agreementTexts.notificationsAgreementText}</Typography>
  ) : (
    MilaNotificationsAgreement
  );

  const PersonalDataAgreement = isWhiteLabelEnabled ? (
    <Typography className={`${styles.AgreementTitleText} ${styles.LabQuest}`}>
      {agreementTexts.personalAgreementText}
    </Typography>
  ) : (
    MilaPersonalDataAgreement
  );
  const modalContent = useMemo(() => {
    if (consentId === idMedicalDataAccessConsent) {
      return MedicalAgreement;
    } else if (consentId === idNotificationsAgreementConsent) {
      return NotificationsAgreement;
    } else {
      return PersonalDataAgreement;
    }
  }, [consentId]);

  return (
    <CustomModal isOpen={isModalOpen} onCancelClick={handleCloseModal} className={styles.AgreementModalContainer}>
      {modalContent}
    </CustomModal>
  );
};
