import { Steps } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface StepsProps {
  current: number;
}

// Will rework later
export const CustomSteps = ({ current }: StepsProps) => {
  return (
    <Steps
      className={styles.CustomSteps}
      size="small"
      current={current}
      responsive={false}
      items={[
        {
          title: '1',
        },
        {
          title: '2',
        },
        {
          title: '3',
        },
        {
          title: '4',
        },
      ]}
    />
  );
};
