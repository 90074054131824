import { CaretRight, Location } from 'src/common/icons';
import { Col, Image, Row, Typography } from 'antd';
import { PromoInfo } from 'src/common/types';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface PromoBlockProps {
  promotions: PromoInfo[];
  promoClassName?: string;
}

export const PromoBlock = ({ promotions, promoClassName }: PromoBlockProps) => {
  const navigate = useNavigate();

  const handlePromoClick = (id: string) => {
    navigate(`/records/promo/${id}`);
  };

  return (
    <>
      {promotions &&
        promotions.map((promo) => (
          <Row
            className={`${styles.PromoRow} ${promoClassName}`}
            key={promo.idPromotion}
            onClick={() => handlePromoClick(promo.idPromotion)}
          >
            <Col>
              <Image
                preview={false}
                className={styles.PromoImage}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${promo.backgroundImageStaticFileId}`}
              />
            </Col>
            <Col className={styles.TextCol}>
              <Typography className={styles.Title}>
                {promo.title}
                <CaretRight className={styles.Caret} />
              </Typography>
              <Row className={styles.LocationRow}>
                <Location className={styles.Location} />
                <Typography className={styles.GreyText}>
                  {promo?.branches.map((branch, index) => `${index !== 0 ? ', ' : ''}${branch.shortName}`)}
                </Typography>
              </Row>
              <Typography className={styles.Text}>
                {(promo.isPromotionInfinite && !promo.endTimestamp) || !promo.endTimestamp
                  ? 'Постоянная акция'
                  : `${dayjs(promo.startTimestamp).format('DD MMMM YYYY')} - ${dayjs(promo.endTimestamp).format(
                      'DD MMMM YYYY',
                    )}`}
              </Typography>
              <Typography className={styles.DescText}>{promo.subtitle}</Typography>
            </Col>
          </Row>
        ))}
    </>
  );
};
