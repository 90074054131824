import { Tabs } from 'antd';
import React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';
interface Tab {
  key: string;
  label: string;
}

interface TabsProps {
  items: Tab[];
  className?: string;
  containerClassName?: string;
  onChange?: (tab: string) => void;
  activeKey?: any;
}

export const CustomCardTabs = ({ items, className, containerClassName, onChange, activeKey }: TabsProps) => {
  return (
    <div className={styles.TabContainer + ' ' + containerClassName}>
      <Tabs className={className} type="card" items={items} onChange={onChange} activeKey={activeKey} moreIcon={null} />
    </div>
  );
};
