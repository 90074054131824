import { Col, Image } from 'antd';
import { ServiceRequestType } from 'src/common/types/enums';
import AtHomeIcon from 'src/assets/AtHomeIcon.svg';
import ClinicIcon from 'src/assets/ClinicIcon.svg';
import Dispanserisation from 'src/assets/Dispanserisation.svg';
import Hospital from 'src/assets/Hospital.svg';
import TmkIcon from 'src/assets/TmkIcon.svg';
import styles from './styles.module.scss';

const getServiceTypeColor = (serviceType: ServiceRequestType) => {
  switch (serviceType) {
    case ServiceRequestType.AtHome: {
      return '#BF6B69';
    }

    case ServiceRequestType.OnSite: {
      return '#7F49DE';
    }

    case ServiceRequestType.Online: {
      return '#A6C763';
    }

    case ServiceRequestType.Dispensary: {
      return '#69B9F4';
    }

    case ServiceRequestType.Hospital: {
      return '#E79E44';
    }

    default:
      return '#F0F2F8';
  }
};

const getMultiServiceTypeBackGround = (typeArray: ServiceRequestType[]) => {
  const uniqColors = typeArray.map(getServiceTypeColor);
  const transitionCount = uniqColors.length;
  const size = Math.round(100 / transitionCount);
  const gradientColors = uniqColors.map((color, i, colors) => {
    if (i === 0) {
      return `${color} ${size}%`;
    }
    if (i === colors.length - 1) {
      return `${color} ${i * size}%`;
    }
    return `${colors[i]} ${i * size}%, ${colors[i]} ${(i + 1) * size}%`;
  });
  return `linear-gradient(to left bottom, ${gradientColors.join(',')})`;
};

interface MedCardCalendarIconProps {
  type?: ServiceRequestType;
  typeArray?: ServiceRequestType[];
}

export const MedCardCalendarIcon = ({ type, typeArray }: MedCardCalendarIconProps) =>
  type || type === 0 ? (
    <>
      {type === ServiceRequestType.OnSite && <Image className={styles.Icon} preview={false} src={ClinicIcon} />}
      {type === ServiceRequestType.AtHome && <Image className={styles.Icon} preview={false} src={AtHomeIcon} />}
      {type === ServiceRequestType.Online && <Image className={styles.Icon} preview={false} src={TmkIcon} />}
      {type === ServiceRequestType.Hospital && <Image className={styles.Icon} preview={false} src={Hospital} />}
      {type === ServiceRequestType.Dispensary && (
        <Image className={styles.Icon} preview={false} src={Dispanserisation} />
      )}
    </>
  ) : (
    <Col
      className={styles.ColoredIcon}
      style={{
        backgroundImage: typeArray?.length && typeArray.length > 0 ? getMultiServiceTypeBackGround(typeArray) : '',
      }}
    />
  );
