import { apiDictionarySlice } from '../common/redux/api/apiDictionarySlice';
import { apiEsiaAuthSlice } from '../common/redux/api/apiEsiaAuthSlice';
import { apiPushNotificationSlice } from '../common/redux/api/apiPushNotificationSlice';

import { apiPatientSlice } from 'src/common/redux/api/apiPatientSlice';
import { apiSharedSlice } from 'src/common/redux/api/apiSharedSlice';
import { appointmentReducer } from 'src/common/redux/appointment/appointmentSlice';
import { configureStore } from '@reduxjs/toolkit';
import { filterReducer } from 'src/common/redux/filter/filterSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fileUploadReducer } from 'src/common/redux/fileUploadSlice';
import { apiAppointmentSignalrSlice } from '../common/redux/api/apiAppointmentSignalrSlice';
import { apiWhiteLabelSlice } from '../common/redux/api/apiWhiteLabelSlice';
import { keycloakReducer } from '../common/redux/keycloak/keycloakSlice';

const persistAppointmentConfig = {
  key: 'appointment',
  storage,
};

const persistFilterConfig = {
  key: 'filter',
  storage,
};

const persistedAppointmentReducer = persistReducer(persistAppointmentConfig, appointmentReducer);
const persistedFilterReducer = persistReducer(persistFilterConfig, filterReducer);

export const store = configureStore({
  reducer: {
    [apiPatientSlice.reducerPath]: apiPatientSlice.reducer,
    [apiWhiteLabelSlice.reducerPath]: apiWhiteLabelSlice.reducer,
    [apiDictionarySlice.reducerPath]: apiDictionarySlice.reducer,
    [apiEsiaAuthSlice.reducerPath]: apiEsiaAuthSlice.reducer,
    [apiPushNotificationSlice.reducerPath]: apiPushNotificationSlice.reducer,
    [apiAppointmentSignalrSlice.reducerPath]: apiAppointmentSignalrSlice.reducer,

    [apiSharedSlice.reducerPath]: apiSharedSlice.reducer,
    filter: persistedFilterReducer,
    appointment: persistedAppointmentReducer,
    keycloak: keycloakReducer,
    fileUpload: fileUploadReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(apiDictionarySlice.middleware)
      .concat(apiPatientSlice.middleware)
      .concat(apiWhiteLabelSlice.middleware)
      .concat(apiEsiaAuthSlice.middleware)
      .concat(apiPushNotificationSlice.middleware)
      .concat(apiSharedSlice.middleware)
      .concat(apiAppointmentSignalrSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
