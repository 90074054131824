import { Button, Col, Divider, Layout, Popover, Row, Typography } from 'antd';
import { MedCardCalendarIcon } from 'src/common/components/MedCardCalendarIcon/MedCardCalendarIcon';
import { MedcardCalendarMonthRecord, MedcardCalendarYearRecord } from 'src/common/types';
import { ServiceRequestType } from 'src/common/types/enums';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const lelgendPoint = [
  { text: 'Амбулаторный прием', color: '#7F49DE' },
  { text: 'Онлайн-консультация', color: '#A6C763' },
  { text: 'Вызов на дом', color: '#BF6B69' },
  { text: 'Стационарное лечение', color: '#E79E44' },
  { text: 'Плановая диспансеризация', color: '#69B9F4' },
];

interface CalendarCellData {
  type: ServiceRequestType[];
  records: MedcardCalendarMonthRecord;
}

const numberOfMonths = 12;
const numberOfYears = 5;
const defaultStartYear = dayjs().get('year') - numberOfYears;
const months = Array.from({ length: 12 }, (_, i) => i + 1);
interface MedCardCalendarProps {
  data?: MedcardCalendarYearRecord[];
  onCalendarClick: (year: number, month: number) => void;
}

export const MedCardCalendar = ({ data, onCalendarClick }: MedCardCalendarProps) => {
  const [startYear, setStartYear] = useState<number>(defaultStartYear);

  const years = useMemo(() => {
    let items = [];
    for (let i = dayjs().get('year'); i >= startYear; i--) {
      items.push(i);
    }
    return items;
  }, [startYear]);

  const earliestYear = useMemo(
    () => (data && data.length > 0 ? data.map((item) => item.year).sort()[0] : defaultStartYear),
    [data],
  );

  const handleShowAll = () => setStartYear(earliestYear);

  const cells: CalendarCellData[] = useMemo(() => {
    let items = [];
    for (let year of years) {
      for (let month of months) {
        let item: CalendarCellData = {
          type: [],
          records: { month: 0, onSiteCount: 0, tmkCount: 0, atHomeCount: 0, dispensaryCount: 0, hospitalCount: 0 },
        };
        if (data && data.length > 0) {
          for (let dataItem of data) {
            if (dataItem.year === year) {
              // eslint-disable-next-line no-loop-func
              dataItem.appointments.forEach((appointment) => {
                if (month === appointment.month) {
                  let typeArray = [];
                  if (appointment.onSiteCount > 0) {
                    typeArray.push(ServiceRequestType.OnSite);
                  }
                  if (appointment.atHomeCount > 0) {
                    typeArray.push(ServiceRequestType.AtHome);
                  }
                  if (appointment.tmkCount > 0) {
                    typeArray.push(ServiceRequestType.Online);
                  }

                  if (appointment.dispensaryCount > 0) {
                    typeArray.push(ServiceRequestType.Dispensary);
                  }

                  if (appointment.hospitalCount > 0) {
                    typeArray.push(ServiceRequestType.Hospital);
                  }

                  item = { records: appointment, type: typeArray };
                }
              });
            }
          }
        }

        items.push(item);
      }
    }
    return items;
  }, [years, data]);

  return (
    <Layout className={styles.CalendarWrapper}>
      <Col className={styles.CalendarCol}>
        <Row className={styles.MonthRow}>
          {months.map((month, index) => (
            <Typography className={styles.MonthLabel} key={index}>
              {dayjs()
                .month(month - 1)
                .format('MMMM')
                .slice(0, 3)}
            </Typography>
          ))}
        </Row>
        {years.map((year, index) => (
          <Row className={styles.Row} key={year}>
            <Typography className={styles.YearLabel}>{year}</Typography>
            {cells.slice(index * numberOfMonths, numberOfMonths * (index + 1)).map((cell, index2) =>
              cell.type.length > 0 ? (
                <Popover
                  key={`${index}-${index2}`}
                  content={
                    <Row className={styles.RecordsRow}>
                      <Typography>Амбулаторных приемов: {cell.records.onSiteCount}</Typography>
                      <Typography>Вызовов на дом: {cell.records.atHomeCount}</Typography>
                      <Typography>Онлайн-консультаций: {cell.records.tmkCount}</Typography>
                      <Typography>Диспанзеризация: {cell.records.dispensaryCount}</Typography>
                      <Typography>Стационарное лечение: {cell.records.hospitalCount}</Typography>
                    </Row>
                  }
                >
                  <Button className={styles.CalendarCell} onClick={() => onCalendarClick(year, cell.records.month)}>
                    {cell.type.length > 1 ? (
                      <MedCardCalendarIcon typeArray={cell.type} />
                    ) : (
                      <MedCardCalendarIcon type={cell.type[0]} />
                    )}
                  </Button>
                </Popover>
              ) : (
                <Button key={`${index}-${index2}`} className={styles.CalendarCell} disabled></Button>
              ),
            )}
          </Row>
        ))}
        {earliestYear < startYear && (
          <>
            <Divider className={styles.Divider} />
            <Typography className={styles.ClickableText} onClick={handleShowAll}>
              Раньше
            </Typography>
          </>
        )}
      </Col>

      <Col className={styles.CalendarCol}>
        <Row className={styles.LegendContainer}>
          {lelgendPoint.map((point) => (
            <Row className={styles.LegendRow} key={point.text}>
              <Col className={styles.LegendDot} style={{ backgroundColor: point.color }}></Col>
              <Typography className={styles.LegendText}>{point.text}</Typography>
            </Row>
          ))}
        </Row>
      </Col>
    </Layout>
  );
};
