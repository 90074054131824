import { Briefcase, CaretRight, DoctorIcon, Exit } from '../../icons';
import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { CustomTag } from '../CustomTag/CustomTag';
import { Header } from 'antd/es/layout/layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MILA_ONLINE_LINK } from '../../contants/constatns';
import { NavTabs } from '../NavTabs/NavTabs';
import { NotificationIcon } from '../NotificationIcon/NotificationIcon';
import { PushNotification } from 'src/features/PushNotifications/PushNotification';
import { handleClinicsClick, handlePatientsClick } from '../../utils';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery
} from 'src/common/redux/api/apiWhiteLabelSlice';
import { useMemo } from 'react';
import MilaIcon from 'src/assets/MilaIcon.svg';
import keycloak from '../../../keycloak';
import styles from './styles.module.scss';
import { useAppSelector } from '../../../app/hooks';

interface HeaderProps {
  isShared?: boolean;
}

export const HeaderRow = ({ isShared = false }: HeaderProps) => {
  const location = useLocation();
  const path = useMemo(() => location.pathname.split('/')[1] ?? '', [location]);
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const navigate = useNavigate();

  const handleIconClick = () => {
    path !== 'home' && path !== 'onboarding' && navigate('/home');
  };

  // TODO: fix slider and remove this code

  const { data: profiles } = useGetProfilesQuery(undefined, { skip: isShared });

  const navMenuItems = useMemo(() => {
    const myProfile = profiles?.find((profile) => profile.itSelf);
    return [
      {
        label: 'Главная',
        key: 'home',
      },
      {
        label: 'Медкарты',
        key: `medcards/${myProfile?.idPatient}`,
      },
      {
        label: 'Записи',
        key: 'records',
      },
      {
        label: 'Каталог',
        key: 'catalog',
      },
      {
        label: 'Настройки',
        key: 'settings',
      },
    ];
  }, [profiles]);

  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  const icon = isWhiteLabelEnabled
    ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idLogoFile}`
    : MilaIcon;

  return (
    <>
      <Header className={styles.HeaderRow}>
        <Row className={styles.WorkArea}>
          <Col className={styles.IconCol}>
            <Image className={styles.MilaIcon} src={icon} preview={false} onClick={handleIconClick} />
            <Row className={styles.GreetingRow}>
              <Image className={styles.MilaIconMobile} src={icon} preview={false} />
              <Typography className={styles.GreetingText}>Привет, {keycloak(keycloakObj).getUserName()}!</Typography>
            </Row>
          </Col>
          <Col className={styles.MainCol}>
            {isShared ? (
              <Col className={styles.SharedMenuCol}>
                <Row>
                  <CustomTag className={styles.HeaderLinkTag} onClick={handlePatientsClick}>
                    <DoctorIcon className={styles.HeaderIcon} />
                    <Typography className={styles.HeaderLinkText}>Для пациентов</Typography>
                    <CaretRight className={styles.HeaderIcon} />
                  </CustomTag>
                  <CustomTag className={styles.HeaderLinkTag} onClick={handleClinicsClick}>
                    <Briefcase className={styles.HeaderIcon} />
                    <Typography className={styles.HeaderLinkText}>Для клиник</Typography>
                    <CaretRight className={styles.HeaderIcon} />
                  </CustomTag>
                </Row>
                <Link to={MILA_ONLINE_LINK}>
                  <CustomButton className={styles.CreateMedcardCardButton} isPrimal={true}>
                    <Typography>Создать свою медкарту</Typography>
                  </CustomButton>
                </Link>
              </Col>
            ) : (
              <>
                {path !== 'onboarding' && (
                  <Col className={styles.MenuCol}>
                    <NavTabs activeTabKey={path} items={navMenuItems} basicPath="" />
                  </Col>
                )}
                <Col className={styles.HeaderButtonContainer}>
                  {path !== 'onboarding' && <NotificationIcon />}
                  <Exit className={styles.Button} onClick={() => keycloak(keycloakObj).logOut()} />
                </Col>
              </>
            )}
          </Col>
        </Row>
      </Header>
      <PushNotification />
    </>
  );
};
