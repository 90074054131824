import React, { useState } from 'react';

import styles from './styles.module.scss';

interface IFloatLabelProps {
  children: React.ReactNode;
  label?: string;
  value?: string;
  suffixIcon?: React.ReactNode;
  suffixOffset?: string;
  isFormList?: boolean;
  isDisabled?: boolean;
  className?: string;
}

function FloatLabelInput({
  children,
  label,
  value,
  suffixIcon,
  suffixOffset,
  isDisabled,
  className,
}: IFloatLabelProps) {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) || (typeof value === 'number' && value === 0)
      ? `${styles.Label} ${styles.Labelfloat}`
      : styles.Label;

  return (
    <div className={`${styles.Floatlabel} ${className}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <i className={styles.SuffixIcon} style={{ right: suffixOffset }}>
        {suffixIcon}
      </i>
      {children}
      {label && (
        <label className={isDisabled ? `${labelClass} ${styles.LabelClassDisabled}` : labelClass}>{label}</label>
      )}
    </div>
  );
}

export default FloatLabelInput;
