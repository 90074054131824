import { isMobile } from 'react-device-detect';
import KeyCloakService from 'src/keycloak';
import axios from 'axios';

const paymentSystemUrl = window.__RUNTIME_CONFIG__.REACT_APP_API as string;

type CreateOrder = {
  paymentUrl: string;
  isError: boolean;
};

export enum PaymentStatus {
  Payed = 0,
  UnPaid = 1,
}

type PaymentStatusResponse = {
  status: number;
};

const getPaymentSystemData = async <T>(subPath: string, keycloakObj: any): Promise<T> => {
  var url = `${paymentSystemUrl}/${subPath}`;

  return await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${KeyCloakService(keycloakObj).getAuthToken()}`,
      },
    })
    .then((resp) => {
      return resp.data as T;
    });
};

const PaymentsService = {
  getAppointmentPaymentInfo: async (idProfileAppointment: string, keycloakObj: any): Promise<PaymentStatus> => {
    var subPath = `payments/orders/appointments/${idProfileAppointment}/status`;
    var paymentStatus = await getPaymentSystemData<PaymentStatusResponse>(subPath, keycloakObj);
    if (paymentStatus.status === 1) {
      return PaymentStatus.Payed;
    }
    return PaymentStatus.UnPaid;
  },
  payAppointment: async (idProfileAppointment: string, keycloakObj: any): Promise<string> => {
    var host = window.__RUNTIME_CONFIG__.REACT_APP_HOST;
    if (host.endsWith('/')) {
      host = host.slice(0, -1);
    }

    var subPath = `payments/orders/create_order?IsMobileView=${isMobile}&IdProfileAppointment=${idProfileAppointment}&ReturnUrl=${`${host}/records/appointmentResult/${idProfileAppointment}`}`;
    const createOrderResult = await getPaymentSystemData<CreateOrder>(subPath, keycloakObj);

    if (createOrderResult.isError) {
      throw new Error('Error occurred while creating order');
    }
    window.location.href = createOrderResult.paymentUrl;

    return createOrderResult.paymentUrl;
  },
};

export default PaymentsService;
