import { Layout } from 'antd';
import { Map, ZoomControl, useYMaps } from '@pbe/react-yandex-maps';
import { MapState } from '../../types';
import { ReactNode, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ymaps from 'yandex-maps';

export interface MapWithBranchesProps {
  children: ReactNode;
  mapState?: MapState;
  className?: string;
  placemark?: ymaps.Placemark;
}

export const MapWithPlacemarks = ({ children, mapState, className, placemark }: MapWithBranchesProps) => {
  const ymaps = useYMaps();
  const [map, setMap] = useState<ymaps.Map>();

  useEffect(() => {
    if (placemark && ymaps && map && map.geoObjects.getLength() > 1) {
      const newBounds = map.geoObjects.getBounds();
      if (newBounds) {
        map.setBounds(newBounds, {
          checkZoomRange: false,
          zoomMargin: [10, 10],
        });
      }
    }
  }, [ymaps, map, placemark]);

  return (
    <Layout className={`${styles.MapContainer} ${className}`}>
      <Map className={styles.Map} defaultState={mapState} instanceRef={setMap}>
        {children}
        <ZoomControl options={{ position: { right: 10, top: 130 } }} />
      </Map>
    </Layout>
  );
};
