import { CaretRight } from 'src/common/icons';
import { Clinic } from 'src/common/types';
import { Col, Image, List, Row, Typography } from 'antd';
import { ListComponent } from 'src/common/components/List/List';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface ClinicListProps {
  branches: Clinic[];
}

export const ClinicList = ({ branches }: ClinicListProps) => {
  const navigate = useNavigate();

  const handleMenuClick = (item: Clinic) => {
    navigate(`/catalog/${item.idMedOrganization}`);
  };

  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={branches}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.SearchItem}>
          <Row className={styles.SearchRow}>
            <Col>
              {item.logoStaticFileId && (
                <Image
                  className={styles.ImagePreview}
                  preview={false}
                  src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${item.logoStaticFileId}`}
                />
              )}
            </Col>
            <Col className={styles.TextCol}>
              <Typography className={styles.Text}>{item.shortName}</Typography>
              <Typography className={styles.SmallText}>{item.address}</Typography>
            </Col>
          </Row>
          <CaretRight />
        </List.Item>
      )}
    />
  );
};
