import { ArrowRight, ErrorResult, GreenCheckIcon, SuccessResult } from 'src/common/icons';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { Button, Col, Form, Layout, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomInput } from 'src/common/components/CustomInput/CustomInput';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ServiceRequestType } from 'src/common/types/enums';
import { YouChose } from '../PatientChoice/YouChose/YouChose';
import { useAppSelector } from 'src/app/hooks';
import { useForm } from 'antd/es/form/Form';
import { useMemo } from 'react';
import {
  useSendToAdditionalEmailMutation,
  useSendToAdditionalEmailWaitingListMutation,
} from 'src/common/redux/api/apiPatientSlice';
import styles from './styles.module.scss';

export const AppointmentResult = () => {
  const [params] = useSearchParams();
  const isSuccess = useMemo(() => params.get('isSuccess') && params.get('isSuccess') === 'true', [params]);

  const { appointmentId } = useParams();

  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const waitingListAppointmentId = useAppSelector((state) => state.appointment.waitingListAppointmentId);

  const navigate = useNavigate();
  const [form] = useForm();
  const [send, result] = useSendToAdditionalEmailMutation();
  const [sendWaitingList, waitingListResult] = useSendToAdditionalEmailWaitingListMutation();

  const handleSignUpAgain = () => {
    navigate(`/records/specialtyChoice`);
  };

  const handleBackToHome = () => {
    navigate(`/home`);
  };

  const handleGetReady = () => {
    navigate(`/records/appointment/${appointmentId}`);
  };

  const handleSendEmail = async () => {
    if (appointmentId) {
      try {
        await send({ appointmentId: appointmentId, email: form.getFieldValue('additionalEmail') }).unwrap();
        notification.success({ message: 'Письмо успешно отправлено' });
      } catch (err) {
        notification.error({ message: 'Что-то пошло не так' });
      }
    }
    if (waitingListAppointmentId) {
      try {
        await sendWaitingList({
          appointmentId: waitingListAppointmentId,
          email: form.getFieldValue('additionalEmail'),
        }).unwrap();
        notification.success({ message: 'Письмо успешно отправлено' });
      } catch (err) {
        notification.error({ message: 'Что-то пошло не так' });
      }
    }
  };

  return (
    <>
      <BackHeader title={isSuccess ? 'Вы успешно записались!' : 'Что-то пошло не так'} />

      {isSuccess ? (
        <Row className={styles.MainRow}>
          <Col className={styles.LeftCol}>
            <Layout className={styles.BlockContainer}>
              <SuccessResult />
              <Typography className={styles.SubTitleNarrow}>
                {serviceRequestType === ServiceRequestType.OnSite && appointmentId
                  ? 'Все в порядке! Ждем вас в клинике.'
                  : 'Все в порядке!'}
              </Typography>
              {serviceRequestType === ServiceRequestType.AtHome || waitingListAppointmentId ? (
                <Typography>
                  Ваша заявка передана в клинику. Для согласования приема с вами свяжется администратор.
                </Typography>
              ) : (
                <Typography>
                  Ваша запись доступна в разделе{' '}
                  <Link className={styles.Link} to="/records">
                    Записи
                  </Link>
                  , а также продублирована на почту.
                </Typography>
              )}
              {(appointmentId || waitingListAppointmentId) && (
                <Row className={styles.EmailRow}>
                  <Form className={styles.EmailForm} form={form} onFinish={handleSendEmail}>
                    <Form.Item name="additionalEmail" rules={[{ required: true, message: 'Пожалуйста введите email' }]}>
                      <CustomInput floatLabel="Дополнительный Email" className={styles.EmailInput} />
                    </Form.Item>
                    <Button
                      className={styles.SendButton}
                      onClick={() => form.submit()}
                      disabled={
                        result.isLoading ||
                        result.isSuccess ||
                        waitingListResult.isLoading ||
                        waitingListResult.isSuccess
                      }
                    >
                      {result.isSuccess || waitingListResult.isSuccess ? <GreenCheckIcon /> : <ArrowRight />}
                    </Button>
                  </Form>
                </Row>
              )}
            </Layout>
            {appointmentId && (
              <Layout className={styles.BlockContainer + ' ' + styles.PrepBlock}>
                <Typography className={styles.SubTitle}>Пожалуйста, подготовьтесь к приему</Typography>
                <Typography className={styles.Text}>
                  Поделитесь медкартой с врачом и узнайте о правилах проведения приема
                </Typography>
                <CustomButton className={styles.PrimalButton} isPrimal={true} onClick={handleGetReady}>
                  <Typography>Подготовиться к приему</Typography>
                </CustomButton>
                <Row className={styles.ButtonsRow}>
                  <CustomButton className={styles.SecondaryButton} onClick={handleSignUpAgain}>
                    <Typography>Записаться еще раз</Typography>
                  </CustomButton>
                  <CustomButton className={styles.SecondaryButton} onClick={handleBackToHome}>
                    <Typography>На главную</Typography>
                  </CustomButton>
                </Row>
              </Layout>
            )}
          </Col>

          <Col className={styles.RightCol}>
            <YouChose isSuccess={true} />

            <Typography className={styles.PaleText}>
              Если ваши планы изменятся вы можете перенести или отменить запись в Личном кабинете или через администратора клиники.
            </Typography>
          </Col>
        </Row>
      ) : (
        <Row className={styles.MainRow}>
          <Col className={styles.LeftCol}>
            <Layout className={styles.BlockContainer}>
              <ErrorResult />
              <Typography className={styles.SubTitle}>Пожалуйста, попробуйте еще раз</Typography>
              <Typography>
                По каким-то причинам мы не получили ответ от информационной системы клиники и не можем сейчас
                подтвердить вашу запись.
              </Typography>
              <Typography>
                Вы можете попробовать записаться еще раз. Или мы можем вам перезвонить и согласовать удобное время,
                данные вашего запроса мы сохранили. Как вам удобнее:
              </Typography>
              <CustomButton className={styles.PrimalButton} isPrimal={true} onClick={handleSignUpAgain}>
                <Typography>Записаться еще раз</Typography>
              </CustomButton>
            </Layout>
          </Col>

          <Col className={styles.RightCol}>
            <YouChose isSuccess={false} />
          </Col>
        </Row>
      )}
    </>
  );
};
