import { Col, Image, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { WhiteRow } from '../WhiteRow/WhiteRow';
import GosUslugiPict from 'src/assets/GosUslugi.png';
import React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface GosUslugiBlockProps {
  className?: string;
  title?: string;
  buttonText: string;
  smallText?: any;
  rowType?: boolean;
  buttonHandler?: () => void;
}

export const GosUslugiBlock = ({
  className = '',
  title,
  buttonText,
  smallText,
  rowType,
  buttonHandler,
}: GosUslugiBlockProps) => (
  <WhiteRow className={`${styles.GosRow} ${className} ${rowType ? styles.RowType : ''}`}>
    {rowType ? (
      <>
        <Col>
          <Image preview={false} src={GosUslugiPict} className={styles.GosImage} />
          <Typography className={styles.GreySmallText}>{smallText}</Typography>
        </Col>
        <Col>
          <CustomButton isPrimal className={styles.GosButton} onClick={buttonHandler}>
            <Typography>{buttonText}</Typography>
          </CustomButton>
        </Col>
      </>
    ) : (
      <Col className={styles.GosCol}>
        <Image preview={false} src={GosUslugiPict} className={styles.GosImage} />
        <Typography className={styles.Title}>{title}</Typography>
        <CustomButton className={styles.GosButton} onClick={buttonHandler}>
          <Typography>{buttonText}</Typography>
        </CustomButton>
        <Typography className={styles.GreySmallText}>{smallText}</Typography>
      </Col>
    )}
  </WhiteRow>
);
