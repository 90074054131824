import { MilaIconMobile } from 'src/common/icons';
import { ReactNode, useMemo } from 'react';
import { Image, Row } from 'antd';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';
import { apiWhiteLabelSlice, useGetWhiteLabelInfoQuery } from '../../common/redux/api/apiWhiteLabelSlice';

export interface OnboardingMessageProps {
  className?: string;
  withIcon?: boolean;
  children?: ReactNode;
}

export const OnboardingMessage = ({ children, withIcon = false }: OnboardingMessageProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  return (
    <Row className={styles.MessageBlock}>
      {withIcon &&
        (isWhiteLabelEnabled ? (
          <Image
            className={styles.MilaIcon}
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idIconFile}`}
            preview={false}
          />
        ) : (
          <MilaIconMobile />
        ))}
      {children}
    </Row>
  );
};
