import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import KeyCloakService from '../../../keycloak';

const serverUrl: string = `${window.__RUNTIME_CONFIG__.REACT_APP_API}/patientaccount/profiles`;

export const apiPushNotificationSlice = createApi({
  reducerPath: 'pushNotificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: serverUrl,
    prepareHeaders: async (headers, api) => {
      const keycloakObj = (api.getState() as any)?.keycloak?.keycloakObj;
      if (keycloakObj) {
        headers.set('Authorization', 'Bearer ' + (KeyCloakService(keycloakObj).getAuthToken() || ''));
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPublicKey: builder.query<any, void>({
      query: () => ({
        url: `/get-public-key`,
      }),
    }),

    sendSubscription: builder.mutation<any, string>({
      query: (subscription) => ({
        url: '/push-subscriptions',
        method: 'POST',
        body: subscription,
        headers: {
          'Content-Type': 'application/json',
          'X-Mila-Origin': window.location.origin,
        },
      }),
    }),
  }),
});

export const { useGetPublicKeyQuery, useSendSubscriptionMutation } = apiPushNotificationSlice;
