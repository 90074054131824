import { Image, Row, Typography } from 'antd';

import { BackHeader } from '../../../common/components/BackHeader/BackHeader';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { DataResponse, Payment } from '../../../common/types';
import { PaymentCard } from '../../../common/components/PaymentCard/PaymentCard';
import { Plus } from 'src/common/icons';
import { ServiceRequestType } from 'src/common/types/enums';
import { getPaymentStatus, removePaymentsDuplicatesById } from '../../../common/utils';
import { setServiceRequestType } from 'src/common/redux/appointment/appointmentSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useGetPaymentsQuery } from '../../../common/redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaymentsStub from 'src/assets/PaymentsStub.png';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export const Payments = ({ isMoneyBack = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setNextPage] = useState<number>(1);
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [paymentsListState, setPaymentsList] = useState<Payment[]>([]);
  const pageCount = 10;
  const {
    data: payments,
    isLoading: isPaymentsLoading,
    refetch: refetchPayments,
  } = useGetPaymentsQuery({
    count: pageCount,
    offset: pageOffset,
    sort: '',
    type: isMoneyBack ? 'refunds' : 'payments',
  });

  useEffect(() => {
    currentPage === 1 && setPaymentsList(payments?.data!);
  }, [payments?.data, currentPage]);

  const loadMoreData = () => {
    const pageNumber = currentPage + 1;
    setNextPage(pageNumber);
    setPageOffset((currentPage - 1) * pageCount + 1);
    refetchPayments()
      .unwrap()
      .then((payload: DataResponse<Payment>) => {
        setPaymentsList(paymentsListState.concat(payload.data));
      });
  };

  const uniquePaymentsList = useMemo(() => removePaymentsDuplicatesById(paymentsListState), [paymentsListState]);

  const handleClick = () => {
    dispatch(setServiceRequestType(ServiceRequestType.Online));
    navigate('/records/specialtyChoice');
  };

  return (
    <>
      <BackHeader title={isMoneyBack ? 'Возвраты' : 'Платежи'} />
      <Row className={styles.PaymentCardsWrapper}>
        {isPaymentsLoading ? (
          <CustomSpinner />
        ) : uniquePaymentsList && uniquePaymentsList.length > 0 ? (
          <InfiniteScroll
            dataLength={paymentsListState?.length || 0}
            next={loadMoreData}
            hasMore={payments!?.total > uniquePaymentsList?.length}
            loader={<CustomSpinner />}
            pullDownToRefresh
            refreshFunction={loadMoreData}
            pullDownToRefreshThreshold={300}
            className={styles.ScrollableDiv}
            scrollThreshold={'300px'}
          >
            {uniquePaymentsList?.map((payment) => (
              <PaymentCard
                key={payment.id}
                paymentDate={`${dayjs(payment.date).format('DD.MM.YYYY')} в ${dayjs(payment.date).format('HH:mm')}`}
                medOrganizationName={payment.fromWhom}
                paymentDescription={payment.descriptionHint}
                paymentStatus={getPaymentStatus(payment.status)}
                price={payment.amount}
                serviceDescription={payment.description}
                isMoneyBack={isMoneyBack}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <Row className={styles.PaymentsStubRow}>
            <Image className={styles.PaymentsStubImage} src={PaymentsStub} preview={false} />
            <Typography className={styles.PaymentsStubTitle}>
              Здесь будет информация о платежах, совершенных через Mila
            </Typography>
            <Typography className={styles.PaymentsStubText}>
              Например, за онлайн-консультации с врачами. Кстати, консультироваться онлайн можно из любого города
              России, а также если вы путешествуете за границу: достаточно иметь доступ к интернету и возможность
              оплатить консультацию онлайн
            </Typography>
            <CustomButton isPrimal className={styles.PaymentsStubButton} onClick={handleClick}>
              <Row className={styles.PaymentsStubButtonContent}>
                <Plus />
                <Typography>Подобрать врача для консультации онлайн </Typography>
              </Row>
            </CustomButton>
          </Row>
        )}
      </Row>
    </>
  );
};
