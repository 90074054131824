import {
  AddToCalendar,
  Briefcase,
  Clock,
  GreenCheckIcon,
  LocationTick,
  RoutingThin,
  RubleSign,
} from 'src/common/icons';
import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Button, Col, Divider, Layout, Row, Typography } from 'antd';
import { CustomTag } from 'src/common/components/CustomTag/CustomTag';
import { Link } from 'react-router-dom';
import { ServiceRequestType } from 'src/common/types/enums';
import { useAppSelector } from 'src/app/hooks';
import { useLazyGetCalendarEventQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface YouChoseProps {
  isSuccess?: boolean;
}

export const YouChose = ({ isSuccess = false }: YouChoseProps) => {
  const doctor = useAppSelector((state) => state.appointment.doctor);
  const slot = useAppSelector((state) => state.appointment.slot);
  const medOrganization = useAppSelector((state) => state.appointment.medOrganization);
  const service = useAppSelector((state) => state.appointment.service);
  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const appointmentId = useAppSelector((state) => state.appointment.appointmentId);
  const startDate = useMemo(() => dayjs(slot?.visitStart), [slot]);

  const [getCalendar] = useLazyGetCalendarEventQuery();

  const handleAddToCalendar = async () => {
    if (appointmentId) {
      await getCalendar(appointmentId);
    }
  };

  return (
    <Layout className={styles.YouChoseContainer}>
      <Typography className={styles.Title}>{isSuccess ? 'Вы записаны' : 'Вы выбрали'}</Typography>
      <Row className={styles.Row}>
        <Col>
          {doctor && (
            <Avatar
              className={styles.DocAvatar}
              idPhoto={doctor?.photoId}
              idProfile={doctor?.idMilaDoctor}
              name={doctor?.fio}
              isDoctor
            />
          )}
        </Col>
        <Col className={styles.Col}>
          <Typography className={styles.FullName}>{doctor?.fio}</Typography>
          <Typography className={styles.Description}>{doctor?.experience}</Typography>
        </Col>
      </Row>
      <Divider className={styles.Divider} />
      {service && (
        <Row className={styles.Row}>
          <Briefcase className={styles.Icon} />
          <Col className={styles.Col}>
            <Typography>{service?.serviceName}</Typography>
            <Typography className={styles.Price}>
              {service?.serviceCostFrom} <RubleSign className={styles.Ruble} />
            </Typography>
          </Col>
        </Row>
      )}

      {slot && (
        <>
          <Divider className={styles.Divider} />
          <Row className={styles.Row}>
            <Clock className={styles.Icon} />
            <Col className={styles.Col}>
              <Typography>
                {startDate ? `${startDate.format('D MMM (ddd)')} в ${startDate.format('HH:mm')}` : ''}
              </Typography>
              {serviceRequestType === ServiceRequestType.OnSite && (appointmentId || slot.idAppointment) ? (
                <>
                  <Typography className={styles.Description}>{medOrganization?.address}</Typography>
                  <CustomTag className={styles.Payment}>
                    Оплата в клинике, {service?.serviceCostFrom} <RubleSign className={styles.Ruble} />
                  </CustomTag>
                </>
              ) : (
                <>
                  <Typography className={styles.TextSmall}>
                    Дистанционно, в личном кабинете пациента в сервисе Mila
                  </Typography>
                  {isSuccess ? (
                    <Typography className={styles.TextPaid}>
                      <GreenCheckIcon /> Оплачeно
                    </Typography>
                  ) : (
                    <Typography className={styles.TextSmall}>Требуется предварительная оплата</Typography>
                  )}
                  <Typography className={styles.Description}>
                    Вы сможете отменить консультацию и вернуть предоплату не позже, чем за 2 часа до начала.
                  </Typography>
                  <Typography.Link className={styles.PaleLink} href="https://mila.online/legal" target="_blank">
                    Подробнее об условиях онлайн-консультаций
                  </Typography.Link>
                </>
              )}
            </Col>
          </Row>
        </>
      )}

      {isSuccess && (
        <Row className={styles.ClickableBlockRow}>
          {appointmentId && (
            <Button className={styles.ClickableBlock} onClick={handleAddToCalendar}>
              <AddToCalendar />
              <Typography>Добавить в календарь</Typography>
            </Button>
          )}
          {serviceRequestType === ServiceRequestType.OnSite && appointmentId && (
            <>
              <Link
                className={styles.ClickableBlock}
                to={`https://yandex.ru/maps/?rtext=~${medOrganization?.latitude}%2C${medOrganization?.longitude}`}
                target="_blank"
              >
                <RoutingThin />
                <Typography>Построить маршрут</Typography>
              </Link>

              <Link
                className={styles.ClickableBlock}
                to={`/catalog/${medOrganization?.idMedOrganization}`}
                target="_blank"
              >
                <LocationTick />
                <Typography>Как добраться</Typography>
              </Link>
            </>
          )}
        </Row>
      )}
    </Layout>
  );
};
