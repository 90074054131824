import { Avatar } from '../../../common/components/Avatar/Avatar';
import { Col, Image, Row, Typography } from 'antd';
import { useMemo } from 'react';
import Bell from 'src/assets/Bell.png';
import HandPill from 'src/assets/HandPill.png';
import HandStethocsope from 'src/assets/HandStethoscope.png';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface UpdateCardProps {
  entityId?: string;
  cardHeader: string;
  cardTitle: string;
  cardDescription: string;
  iconSrc?: string;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  cardIndex?: number;
  medorganizationName?: string;
  idPhoto?: string;
  patientId?: string;
  patientName?: string;
  doctorName?: string;
  classNameCardIcon?: string;
  classNameTitle?: string;
  classNameDoctorName?: string;

  onClick?: () => void;
}

export const UpdateCard = ({
  cardHeader,
  cardTitle,
  cardDescription,
  patientName,
  doctorName,
  cardIndex = 0,
  medorganizationName,
  idPhoto = '',
  patientId = '',
  onClick,
  backgroundColor,
  textColor = '',
  iconSrc,
  className,
  classNameCardIcon,
  classNameTitle,
  classNameDoctorName,
}: UpdateCardProps) => {
  const bgColorAppointment = useMemo(() => ((cardIndex + 2) % 2 === 0 ? '#40C9D5' : '#BF6B69'), [cardIndex]);

  const bgColorMedcard = useMemo(() => ((cardIndex + 2) % 2 === 0 ? '#4F489B' : '#7F49DE'), [cardIndex]);

  const cardIcon = useMemo(() => ((cardIndex + 2) % 2 === 0 ? HandStethocsope : HandPill), [cardIndex]);

  const formattedPatientName = useMemo(() => patientName?.split(' ')[1], [patientName]);

  return (
    <Col
      className={`${styles.ImportantCol} ${className}`}
      style={{
        background: backgroundColor || (cardHeader === 'МЕДКАРТА' ? bgColorMedcard : bgColorAppointment),
      }}
      onClick={onClick}
    >
      <Typography className={styles.Subtitle} style={{ color: textColor ?? '' }}>
        {cardHeader}
      </Typography>
      <Typography className={`${styles.Title2} ${classNameTitle}`} style={{ color: textColor ?? '' }}>
        {cardTitle}
      </Typography>
      <Row className={styles.TextBlock}>
        <Row>
          <Typography className={styles.Text} style={{ color: textColor ?? '' }}>
            {cardDescription}
          </Typography>
        </Row>
        {doctorName && (
          <Row>
            <Typography className={`${styles.Text} ${classNameDoctorName}`} style={{ color: textColor ?? '' }}>
              {doctorName}
            </Typography>
          </Row>
        )}
        {medorganizationName && (
          <Row>
            <Typography className={styles.Text} style={{ color: textColor ?? '' }}>
              {medorganizationName}
            </Typography>
          </Row>
        )}

        <Row className={classNameCardIcon}>
          <Image preview={false} src={iconSrc || (cardHeader === 'МЕДКАРТА' ? Bell : cardIcon)} />
        </Row>
      </Row>
      {patientName && (
        <Row className={styles.AvatarRow}>
          <Avatar
            idPhoto={idPhoto}
            idProfile={patientId}
            name={patientName}
            className={styles.Avatar}
            classNameText={styles.AvatarText}
          />
          <Typography className={styles.Text} style={{ color: textColor ?? '' }}>
            {formattedPatientName}
          </Typography>
        </Row>
      )}
    </Col>
  );
};
