import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadedFiles: [],
};

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    setUploadedFiles(state, action) {
      state.uploadedFiles = action.payload;
    },
  },
});

export const { setUploadedFiles } = fileUploadSlice.actions;
export const fileUploadReducer = fileUploadSlice.reducer;

