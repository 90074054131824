import { Clinic } from 'src/common/types';
import { ClinicPlaceholder } from 'src/common/icons';
import { Col, Image, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

interface ClinicIconProps {
  clinic: Clinic;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ClinicIcon = ({ clinic, onClick }: ClinicIconProps) => {
  return (
    <Row className={styles.ClinicIconRow}>
      {clinic.isConnectedToMila ? (
        <Link to={`/catalog/${clinic.idMedOrganization}`}>
          <Image
            className={styles.Image}
            preview={false}
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${clinic?.logoStaticFileId}`}
          />
        </Link>
      ) : (
        <Col className={styles.Placeholder} onClick={(e) => !clinic.isConnectedToMila && onClick(e)}>
          <ClinicPlaceholder />
        </Col>
      )}

      <Typography className={styles.ClinicName}>{clinic.shortName}</Typography>
    </Row>
  );
};
