import { Image, Row, Typography } from 'antd';

import { CustomModal } from '../../common/components/CustomModal/CustomModal';
import { MilaMessage } from '../../common/components/MilaMessage/MilaMessage';
import React from 'react';
import Wink from '../../assets/Wink.png';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface RemoveProfileFormProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const SubmitModal = ({ isModalOpen, handleCloseModal }: RemoveProfileFormProps) => {
  return (
    <CustomModal isOpen={isModalOpen} className={styles.Modal} onCancelClick={handleCloseModal}>
      <Row className={styles.ModalWrapper}>
        <Row>
          <Typography.Title level={3} className={styles.ModalContentTitle}>
            Удаление профиля
          </Typography.Title>
        </Row>
        <Row className={styles.Center}>
          <Image src={Wink} preview={false} alt="" />
        </Row>
        <Row>
          <MilaMessage title={'Спасибо, что выразили свое мнение! '} text={'Я очень рада, что вы с нами'} />
        </Row>
      </Row>
    </CustomModal>
  );
};
