import { Col, Image, Typography } from 'antd';
import { CustomButton } from '../../CustomButton/CustomButton';
import { handleAddEsiaAccount } from '../../../utils';
import { useAppSelector } from '../../../../app/hooks';
import GosUslugiPict from 'src/assets/GosUslugi.png';
import styles from './styles.module.scss';

export const AddProfileBlock = () => {
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  return (
    <Col className={styles.frame}>
      <Image src={GosUslugiPict} alt="" preview={false} />
      <Typography className={styles.title}>Получайте больше данных</Typography>
      <CustomButton className={styles.button}>
        <Typography onClick={() => handleAddEsiaAccount(keycloakObj)}>Подключить Госуслуги</Typography>
      </CustomButton>
      <Typography.Text className={styles.text}>
        Привяжите свой профиль с Госуслуг, чтобы получать больше данных. Вы также сможете входить через Госуслуги
      </Typography.Text>
    </Col>
  );
};
