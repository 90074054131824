import { Tag } from 'antd';
import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

interface TagProps {
  children: ReactNode;
  selected?: boolean;
  grey?: boolean;
  warn?: boolean;
  className?: string;
  onClick?: () => void;
}

export const CustomTag = ({ children, selected = false, grey = false, warn = false, className, onClick }: TagProps) => {
  return (
    <Tag
      className={`${styles.CustomTag} ${selected ? styles.Active : ''} ${grey ? styles.Grey : ''} ${
        warn ? styles.Warn : ''
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};
