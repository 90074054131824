import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Image, Layout, Row, Typography } from 'antd';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import NotFound from 'src/assets/NotFound.png';
import React, { useState } from 'react';
import YandexShare from '../../../common/components/YandexShare/YandexShare';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export const InvitationModal = () => {
  const [isYandexShareOpen, setIsYandexShareOpen] = useState(false);

  const handleYandexShareOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsYandexShareOpen(true);
  };
  const handleYandexShareClose = () => setIsYandexShareOpen(false);

  return (
    <Layout className={styles.ModalRowWrapper} onClick={handleYandexShareClose}>
      <Row className={styles.ModalRow}>
        <Typography className={styles.ModalTitle}>Пригласить клинику</Typography>
        <Row className={styles.ImageRow}>
          <Image src={NotFound} preview={false} className={styles.ImageIcon} />
        </Row>
        <Typography.Title className={styles.ModalSubTitle} level={3}>
          Вашей клиники еще нет в Mila?
        </Typography.Title>
        <Typography.Text type="secondary" className={styles.ModalAboutMila}>
          Пригласите клинику, поделившись с ней кодом на подключение с 50% скидкой
        </Typography.Text>
        <Typography.Text className={styles.ModalCoupon}>MILAPLUS</Typography.Text>
        <MilaMessage
          title={'Что делать, если ваша клиника еще не подключена'}
          text={'А вы хотите получать из нее свои медицинские данные в Mila'}
          hiddenText={
            <Typography className={styles.ModalMessageInfo}>
              <Typography.Text className={styles.ModalMessageText}>
                А вы хотите получать из нее свои медицинские данные в Mila
              </Typography.Text>
              <Typography.Text className={styles.ModalMessageText}>
                Согласитесь, когда заключения врачей, анализы, снимки, вся история по стоматологическим и
                косметологическим манипуляциям из разных клиник доступны в одном месте – это очень удобно. Можно,
                наконец, перестать приносить на каждый прием пачку бумаг и просто поделиться своей медкартой с нужным
                врачом, отправив ссылку.
              </Typography.Text>
              <Typography.Text className={styles.ModalMessageText}>
                На самом деле все клиники в России, имеющие медицинскую лицензию, в том числе стоматологические и
                косметологические, должны передавать медицинские документы в электронном виде, а авторизованные через
                Госуслуги пациенты иметь к ним доступ. Этого требует Постановление Правительства РФ № 140 от 09.02.2022
                г. (ранее № 555 от 5 мая 2018 г.) «О единой государственной информационной системе в сфере
                здравоохранения» и Постановление Правительства РФ № 852 от 01.06.2021 г. «О лицензировании медицинской
                деятельности».
              </Typography.Text>
              <Typography.Text className={styles.ModalMessageText}>
                Однако организовать безопасную передачу медицинских документов каждой отдельной клинике очень непросто:
                требуется обеспечить защиту каналов связи и хранилища данных. Чтобы помочь вашей клинике решить эту
                задачу Вы можете порекомендовать ей использовать Mila – сервис, разработанный на базе крупнейшей
                облачной платформы для обмена данными о здоровье N3.Health.
              </Typography.Text>
              <Typography.Text className={`${styles.ModalMessageText} ${styles.ModalMessageBoldText}`}>
                Пригласите клинику в Милу, направив информацию о сервисе на e-mail, в мессенджеры, соцсети или просто
                расскажите врачу и администратору.
              </Typography.Text>
              <Typography.Text className={`${styles.ModalMessageText} ${styles.ModalMessageBoldText}`}>
                В приглашении вы можете передать в клинику код <strong>MILAPLUS</strong> и тогда подключение будет для
                нее бесплатным
              </Typography.Text>
            </Typography>
          }
          className={styles.ModalMessage}
        />
        <CustomButton isPrimal className={styles.AddNewButton} onClick={handleYandexShareOpen}>
          <Row>
            <Typography.Text>Поделиться приглашением с клиникой</Typography.Text>
          </Row>
        </CustomButton>
        {isYandexShareOpen && (
          <YandexShare
            className={styles.Share}
            text="Здравствуйте! Прошу обеспечить доступ к моей медицинской карте в электронном виде в цифровом ассистенте пациента MILA. Для передачи данных рекомендую использовать платформу www.n3health.ru. По коду MILAPLUS сейчас действует скидка 50% на подключение."
          />
        )}
      </Row>
    </Layout>
  );
};
