import { CustomButton } from '../CustomButton/CustomButton';
import { CustomInput } from '../CustomInput/CustomInput';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { Image, Layout, Rate, Row, Typography, notification } from 'antd';
import { MilaMessage } from '../MilaMessage/MilaMessage';
import { useRateAppointmentMutation } from '../../redux/api/apiPatientSlice';
import BadEstimatePict from './images/bad.png';
import EstimatePict from './images/estimate.png';
import GoodEstimatePict from './images/good.png';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import styles from './styles.module.scss';

interface EstimateCardProps {
  appointmentId: string;
  rates: {
    rateTitle: string;
    rateType: 'rate' | 'rateconnectionquality';
    ratePlaceholder?: string;
  }[];
  title: ReactNode | string;
  text: string;
}

export const EstimateCard = ({ appointmentId, rates, title, text }: EstimateCardProps) => {
  const [comment, setComment] = useState<string>('');
  const [scoreArray, setScoreArray] = useState<number[]>([]);
  const [isRateDone, setRateDone] = useState<boolean>(false);
  const [currentRate, setCurrentRate] = useState<number>(0);

  const [rate, mutation] = useRateAppointmentMutation();

  const handleRateChange = (value: number) => {
    const newScoreArray = [...scoreArray];
    newScoreArray[currentRate] = value;
    setScoreArray(newScoreArray);
  };

  const handleCommentChange = async (input: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setComment(input.target.value);
  };

  const handleRateSend = () => {
    rate({ appointmentId, score: scoreArray[currentRate], comment, rateType: rates[currentRate].rateType })
      .unwrap()
      .then(() => {
        setComment('');
        currentRate !== rates.length - 1 ? setCurrentRate((prev) => prev + 1) : setRateDone(true);
      })
      .catch(() => {
        notification.error({ message: 'Ошибка отправки оценки' });
      });
  };

  const allRatesIsGood = scoreArray.every((score) => score >= 4);

  return (
    <>
      <Layout className={styles.card}>
        <Row>
          <Typography.Title level={2} className={styles.title}>
            {isRateDone ? 'Спасибо за оценку' : 'Оцените услугу'}
          </Typography.Title>
        </Row>
        {!isRateDone ? (
          <>
            <Row>
              <Image src={EstimatePict} preview={false} className={styles.pict} alt="" />
            </Row>
            <MilaMessage className={styles.messageBox} title={title} text={text} />
          </>
        ) : allRatesIsGood ? (
          <>
            <Row>
              <Image src={GoodEstimatePict} preview={false} className={styles.pict} alt="" />
            </Row>
            <MilaMessage
              className={styles.messageBox}
              title="Спасибо, что выразили свое мнение!"
              text="Я очень рада, что вы с нами"
            />
          </>
        ) : (
          <>
            <Row>
              <Image src={BadEstimatePict} preview={false} className={styles.pict} alt="" />
            </Row>
            <MilaMessage
              className={styles.messageBox}
              title="Мне жаль, что мы не смогли полностью оправдать ваши ожидания"
              text="Мы будем очень стараться стать лучше! Спасибо, что выразили свое мнение."
            />
          </>
        )}

        {mutation.isLoading ? (
          <CustomSpinner />
        ) : (
          !isRateDone && (
            <Row className={styles.estimate}>
              <Typography.Title level={3} className={styles.subTitle}>
                {rates[currentRate].rateTitle}
              </Typography.Title>
              <Rate className={styles.rate} value={scoreArray[currentRate]} onChange={handleRateChange} />
              <CustomInput
                className={styles.feedback}
                value={comment}
                onChange={handleCommentChange}
                isTextArea
                placeholder={rates[currentRate].ratePlaceholder || 'Ваша оценка'}
              />
              <CustomButton
                isPrimal
                className={styles.send}
                disabled={!scoreArray[currentRate]}
                onClick={handleRateSend}
              >
                <Typography.Text>Отправить</Typography.Text>
              </CustomButton>
            </Row>
          )
        )}
      </Layout>
    </>
  );
};
