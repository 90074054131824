import { Button, Col, Form, Layout, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { ShareModal } from '../../MedCard/ShareModal/ShareModal';
import { UploadChangeParam } from 'antd/es/upload';
import {
  useAttachAppointmentAnalysesMutation,
  useAttachAppointmentPhotoMutation,
  useDeleteSharedLinkMutation,
  useDetachAppointmentAnalysesMutation,
  useDetachAppointmentPhotoMutation,
  useGetSharedMedcardsQuery,
  useLazyAnalysesByQueryQuery,
  useSetAppointmentMutation,
} from '../../../common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery
} from '../../../common/redux/api/apiWhiteLabelSlice';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { useForm } from 'antd/lib/form/Form';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import { CheckOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import DeleteSharedMedcardModal from './DeleteSharedMedcardModal/DeleteSharedMedcardModal';
import styles from './styles.module.scss';
import type { RcFile as OriRcFile } from 'rc-upload/lib/interface';

interface DoctorInfoBlockProps {
  idProfile: string;
  appointmentStatusUpcoming?: boolean;
  appointmentId?: string;
  refetchData?: () => void;
  disabled?: boolean;
  sharedLinkId?: string;
}

interface RcFile extends OriRcFile {
  readonly lastModifiedDate: Date;
}

export const DoctorInfoBlock = ({
  idProfile,
  refetchData,
  appointmentId = '',
  appointmentStatusUpcoming = false,
  disabled = false,
  sharedLinkId,
}: DoctorInfoBlockProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  const navigate = useNavigate();
  const [form] = useForm();
  const [isDocumentsFormModalVisible, setDocumentsFormModalVisibility] = useState(false);
  const [isShareMedcardModalOpen, setIsShareMedcardModalOpen] = useState(false);
  const [medcardLink, setMedcardLink] = useState<string>('');
  const [isOpenDeleteSharedMedcard, setisOpenDeleteSharedMedcard] = useState<boolean>(false);
  const [idMilaUserProfile, setIdMilaUserProfile] = useState<string>('');

  const [setAppointment] = useSetAppointmentMutation();

  const [attachAppointmentAnalyses] = useAttachAppointmentAnalysesMutation();

  const [attachAppointmentPhoto] = useAttachAppointmentPhotoMutation();
  const [detachAppointmentPhoto] = useDetachAppointmentPhotoMutation();
  const [detachAppointmentAnalyses] = useDetachAppointmentAnalysesMutation();

  const { data: links } = useGetSharedMedcardsQuery();
  const [deleteLink] = useDeleteSharedLinkMutation();

  const handleDocumentsModalCancel = () => {
    setDocumentsFormModalVisibility(false);
  };

  const handleAttachNewDocs = () => {
    setDocumentsFormModalVisibility(true);
  };

  const handleOpenShareMedcardModal = () => {
    setIsShareMedcardModalOpen(true);
  };

  const handleCloseShareMedcardModal = () => {
    setIsShareMedcardModalOpen(false);
  };

  const [searchAnalysesAttached, analysesAttached] = useLazyAnalysesByQueryQuery();

  const [analysisNameQuery, setAnalysisNameQuery] = useState<string>('');

  const debouncedSearchAnalyses = useDebounce(analysisNameQuery, 300);

  const medcardAccessText = (
    <>
      <Typography className={styles.LoadText}>{`Вы можете предоставить врачу доступ к медкарте${
        !isWhiteLabelEnabled ? ' в Mila.' : '.'
      }`}</Typography>
      <br />
      <Typography className={styles.LoadText}>
        Ссылка на просмотр данных для врача имеет органиченное время действия. При предоставлении доступа к медкарте по
        ссылке будут доступны только те полученные из клиник либо загруженные вручную медицинские документы, просмотр
        которых был разрешен вами в настройках документа.
      </Typography>
      <br />
      <Typography className={styles.LoadText}>
        Ссылка для врача будет передана в медицинскую информационную систему клиники вместе с вашей записью на прием.
        <Typography className={styles.BoldMessageText}>
          Просмотр данных по такой ссылке будет открыт в течение 24 часов только в день назначенного приема.
        </Typography>
      </Typography>
      <br />
      <Typography className={styles.LoadText}>
        По истечении срока действия ссылка удалится автоматически. По клику на кнопку «Поделиться медкартой» на главной
        странице или в разделе «Медкарты» вы можете посмотреть список действующих ссылок на текущий момент и при
        необходимости закрыть доступ и удалить ссылку досрочно.
      </Typography>
      <br />
      <Typography className={styles.LoadText}>
        Важно помнить, что вы самостоятельно несете ответственность за раскрытие персональных данных (как своих, так и
        связанных профилей).
      </Typography>
    </>
  );

  useEffect(() => {
    if (debouncedSearchAnalyses && debouncedSearchAnalyses.length > 2)
      searchAnalysesAttached({ appointmentId, analysisNameQuery });
  }, [analysisNameQuery, appointmentId, debouncedSearchAnalyses, searchAnalysesAttached]);

  useEffect(() => {
    if (sharedLinkId && links) {
      const medcard = links.find((item) => item.idMilaSharedLink === sharedLinkId);
      if (medcard) {
        setMedcardLink(medcard.link);
        setIdMilaUserProfile(medcard.idMilaUserProfile);
      }
    }
  }, [sharedLinkId, links]);

  const analysysSearchDocs = useMemo(
    () =>
      analysesAttached?.currentData?.map((analysis) => ({
        key: analysis.analysisUserDocumentId,
        value: analysis.analysisName,
        label: analysis.analysisName,
      })),
    [analysesAttached],
  );

  const handlePhotoChange = (event: UploadChangeParam) => {
    if (event.fileList[0]?.originFileObj) {
      attachAppointmentPhoto({
        file: event.fileList[0].originFileObj,
        appointmentId,
      })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Фото успешно добавлено' });
          refetchData && refetchData();
        })
        .catch(() => {
          notification.error({ message: 'Ошибка добавления фото' });
        });
    }
  };

  const checkPhotoType = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;

    const isProperFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg';

    if (!isLt2M) {
      notification.error({ message: 'Размер файла не должен превышать 500КБ!' });
    }
    if (!isProperFormat) {
      notification.error({ message: 'Формат файла не поддерживается!' });
    }
    return false;
  };

  const handlePhotoDetach = (photoId: string) => () => {
    detachAppointmentPhoto({ appointmentId, photoId }).then(() => {
      refetchData && refetchData();
    });
  };

  const handleSubmitForm = () => {
    setAppointment({
      appointmentId,
      ...form.getFieldsValue(),
    })
      .then(() => {
        refetchData && refetchData();
        notification.success({ message: 'Данные успешно сохранены' });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка сохранения данных' });
      });
  };

  const attachFile = (_id: string, o: { value: string; label: string; key: string }) => {
    attachAppointmentAnalyses({ appointmentId: appointmentId, analysisDocumentId: o.key })
      .unwrap()
      .then(() => {
        refetchData && refetchData();
      })
      .catch(() => {
        notification.error({ message: 'Ошибка добавления файла' });
      });
  };

  const handleRemoveDocument = (docId: string) => {
    detachAppointmentAnalyses({ analysisDocumentId: docId, appointmentId: appointmentId })
      .unwrap()
      .then(() => {
        refetchData && refetchData();
      });
  };

  const handleSearchAnalyses = (input: ChangeEvent<HTMLInputElement>) => {
    setAnalysisNameQuery(input.target.value);
  };

  const openDeleteSharedMedcardModal = () => {
    setisOpenDeleteSharedMedcard(true);
  };

  const closeDeleteSharedMedcardModal = () => {
    setisOpenDeleteSharedMedcard(false);
  };

  const handleDeleteMedcard = () => {
    if (sharedLinkId) {
      deleteLink(sharedLinkId).then((res: any) => {
        if (!res.error) {
          notification.success({ message: 'Ссылка удалена' });
          refetchData && refetchData();
          setMedcardLink('');
          closeDeleteSharedMedcardModal();
        }
      });
    }
  };

  const handleGoTOMedcard = () => {
    if (idMilaUserProfile) {
      navigate(`/medcards/${idMilaUserProfile}?activeTab=documents`);
    }
  };

  return (
    <Layout className={styles.DoctorInfoWrapper}>
      <Typography className={styles.Title}>
        {disabled ? 'Информация для врача, приложенная пациентом к записи на прием' : 'Информация для врача'}
      </Typography>
      {!disabled && (
        <MilaMessage
          title={'Как делиться медицинскими документами с врачом'}
          text={'и делать это безопасно '}
          hiddenText={medcardAccessText}
          className={styles.ShareMessageBlock}
        />
      )}
      <Form form={form} className={styles.Form} layout="vertical" onFinish={handleSubmitForm}>
        {/*  temporary commented before demo*/}
        {/*<Typography className={styles.BoldText}>{disabled ? 'Жалобы' : 'Расскажите, какие у вас жалобы'}</Typography>
        <Form.Item name="anamnesisComplaints" initialValue={complaints}>
          <CustomInput isTextArea floatLabel="Опишите свое состояние" disabled={!appointmentStatusUpcoming} />
        </Form.Item>
        {appointmentStatusUpcoming && (
          <Row className={styles.LoadRow}>
            <Typography className={styles.LoadText}>Загрузите фотографии если это необходимо</Typography>
            <Upload
              className={styles.FileUploader}
              accept={'.jpg, .svg, .png'}
              maxCount={1}
              onChange={(event) => handlePhotoChange(event)}
              beforeUpload={(file) => checkPhotoType(file)}
              showUploadList={{ showRemoveIcon: false }}
            >
              <CustomButton>
                <Row className={styles.LoadButtonContent}>
                  <Plus />
                  <Typography>Загрузить</Typography>
                </Row>
              </CustomButton>
            </Upload>
          </Row>
        )}

        {attachedPhotosMilaUserDocumentIds && (
          <Row className={styles.ImagesRow}>
            {attachedPhotosMilaUserDocumentIds.map((photo) => (
              <Col className={styles.ImageBlock} key={photo}>
                <ServerImage appointmentId={appointmentId} photoId={photo} />
                {appointmentStatusUpcoming && (
                  <Popconfirm
                    title={`Вы уверены, что хотите удалить прикрепленное фото?`}
                    onConfirm={handlePhotoDetach(photo)}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <div className={styles.RemoveImage} />
                  </Popconfirm>
                )}
              </Col>
            ))}
          </Row>
        )}
        <Typography className={styles.BoldText}>
          {disabled
            ? 'Сведения об установленных диагнозах от пациента'
            : 'Вы можете предоставить врачу разовый доступ к медкарте или приложить отдельные документы к записи на прием'}
          </Typography>*/}

        <Row className={styles.MedcardRow}>
          {!medcardLink && (
            <CustomButton
              className={styles.SaveButton}
              onClick={handleOpenShareMedcardModal}
              disabled={!appointmentStatusUpcoming}
            >
              <Typography>Предоставить врачу доступ к медкарте</Typography>
            </CustomButton>
          )}
          {medcardLink && sharedLinkId && (
            <>
              <Col>
                <Button disabled className={styles.DisabledMedcardBtn} icon={<CheckOutlined />}>
                  Доступ врачу предоставлен
                </Button>
              </Col>
              <Col>
                <Link className={styles.MedcardActionsLinks} to={`/shared/medcard/${medcardLink}`} target="_blank">
                  Посмотреть, что увидит врач
                </Link>
              </Col>
              <Col>
                <Button className={styles.MedcardActionsLinks} type="link" onClick={openDeleteSharedMedcardModal}>
                  Закрыть доступ для врача
                </Button>
              </Col>
            </>
          )}
        </Row>
        {/*!disabled && (
          <Row className={styles.AnalysesBlock}>
            {appointmentStatusUpcoming && (
              <Form.Item name="analysisNameQuery">
                <CustomAutocompleteSearch
                  className={styles.Input}
                  floatLabel="Выбрать отдельный документ из медкарты"
                  options={analysysSearchDocs || []}
                  onChange={handleSearchAnalyses}
                  handleChangeValue={attachFile}
                />
              </Form.Item>
            )}
            {appointmentStatusUpcoming && (
              <Row className={styles.LoadRow}>
                <Typography className={styles.LoadText}>
                  Если документ отсутствует в медкарте - загрузите его
                </Typography>
                <CustomButton onClick={handleAttachNewDocs}>
                  <Row className={styles.LoadButtonContent}>
                    <Plus />
                    <Typography>Загрузить</Typography>
                  </Row>
                </CustomButton>
              </Row>
            )}
            <Row>
              <DocumentsList
                docsList={analysysDocs}
                appointmentId={appointmentId}
                className={styles.DocumentsListConatinet}
                isAnalysisDocument
                removeDocument={handleRemoveDocument}
              />
            </Row>
          </Row>
            )*/}
        {/*!disabled && appointmentStatusUpcoming && (
          <CustomButton isPrimal className={styles.SaveButton} htmlType="submit">
            <Typography>Сохранить информацию для врача</Typography>
          </CustomButton>
        )*/}
      </Form>

      {/* <CustomModal isOpen={isDocumentsFormModalVisible} onCancelClick={handleDocumentsModalCancel}>
        <MedcardDocumentLoadForm
          isAppointmentForm
          idMilaAppointment={appointmentId}
          isUpcomingAppointment={appointmentStatusUpcoming}
        />
      </CustomModal> */}

      <ShareModal
        idProfile={idProfile}
        appointmenId={appointmentId}
        isModalOpen={isShareMedcardModalOpen}
        onCancelClick={handleCloseShareMedcardModal}
        hideSelect
      />

      <DeleteSharedMedcardModal
        isModalOpen={isOpenDeleteSharedMedcard}
        onCancelClick={closeDeleteSharedMedcardModal}
        handleDeleteMedcard={handleDeleteMedcard}
        handleGoTOMedcard={handleGoTOMedcard}
      />
    </Layout>
  );
};
