import { Button, Col } from 'antd';
import { Chevron } from 'src/common/icons';
import React from 'react';
import styles from './styles.module.scss';

interface ServerImageProps {
  gotoPrev: () => void;
  gotoNext: () => void;
  leftArrowDisable?: boolean;
  rightArrowDisable?: boolean;
  className?: string;
}

export const SliderArrows = ({
  gotoPrev,
  gotoNext,
  leftArrowDisable,
  rightArrowDisable,
  className,
}: ServerImageProps) => {
  return (
    <Col className={`${styles.Arrows} ${className}`}>
      <Button
        shape="circle"
        className={`${styles.Arrow} ${styles.ArrowPrev}`}
        onClick={gotoPrev}
        htmlType="button"
        disabled={leftArrowDisable}
        icon={<Chevron />}
      />
      <Button
        shape="circle"
        className={`${styles.Arrow} ${styles.ArrowNext}`}
        onClick={gotoNext}
        htmlType="button"
        disabled={rightArrowDisable}
        icon={<Chevron />}
      />
    </Col>
  );
};
