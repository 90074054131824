import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { PromoBlock } from '../../../common/components/PromoBlock/PromoBlock';
import { WhiteRow } from '../../../common/components/WhiteRow/WhiteRow';
import { useGetPromotionsQuery } from '../../../common/redux/api/apiPatientSlice';
import { useParams } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

export const ClinicPromotions = () => {
  const { clinicId } = useParams();
  const { data: promotionsData, isLoading } = useGetPromotionsQuery({ idMedOrganization: clinicId });

  return isLoading ? (
    <CustomSpinner />
  ) : promotionsData ? (
    <WhiteRow className={styles.PromoBlockWrapper}>
      <PromoBlock promotions={promotionsData!} />
    </WhiteRow>
  ) : (
    <></>
  );
};
