import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { ChangeEvent, useEffect, useState } from 'react';
import { Clinic } from 'src/common/types';
import { ClinicPlacemark } from 'src/features/MapModule/ClinicPlacemark/ClinicPlacemark';
import { Col, Layout, Row, Typography } from 'antd';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { CustomSearch } from 'src/common/components/CustomSearch/CustomSearch';
import { GlobalSearch } from 'src/features/GlobalSearch/GlobalSearch';
import { Location } from 'src/common/icons';
import { MapWithPlacemarks } from '../../../common/components/MapWithPlacemarks/MapWithPlacemarks';
import { ServiceTypeChoice } from './ServiceTypeChoice/ServiceTypeChoice';
import { useAppSelector } from 'src/app/hooks';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { useLazyGetSearchQuery } from 'src/common/redux/api/apiPatientSlice';
import { adultAge } from '../../../common/contants/constatns';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface CreateAppointmentModalContentProps {
  onClose: () => void;
  isOpen: boolean;
  clinic?: Clinic;
}

export const CreateAppointmentModalContent = ({ isOpen, onClose, clinic }: CreateAppointmentModalContentProps) => {
  const [searchInput, setSearchInput] = useState<string>();
  const debouncedSearch = useDebounce(searchInput || '', 300);
  const region = useAppSelector((state) => state.filter.region);
  const isChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);

  const [search, searchQuery] = useLazyGetSearchQuery();

  useEffect(() => {
    if (debouncedSearch) {
      search({
        query: debouncedSearch,
        region: region ?? 'Все регионы',
        idClinic: clinic?.idMedOrganization,
        serveAge: isChildren ? childrenAge : adultAge
      });
    }
  }, [clinic?.idMedOrganization, debouncedSearch, region, search, isChildren, childrenAge]);

  const handleClose = () => {
    onClose();
  };

  const handleSearch = async (input: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(input.target.value);
  };

  return (
    <CustomModal isOpen={isOpen} onCancelClick={handleClose} className={styles.ModalContainer}>
      <Layout className={styles.ModalContentWrapper}>
        {searchInput ? (
          <>
            <Typography className={styles.ModalContentTitle}>Поиск</Typography>
            <AgeToggle containerClassName={styles.TabsContainer} />
          </>
        ) : (
          <>
            <Typography className={styles.ModalContentTitle}>
              {clinic ? 'Записаться в клинику' : 'Добавить запись'}
            </Typography>

            {clinic && (
              <Row className={styles.MapRow}>
                <Typography className={styles.MapTitle}>{clinic.shortName}</Typography>
                <Row className={styles.ContactRow}>
                  <Location className={styles.Icon} />
                  <Col>
                    <Typography>{clinic?.address}</Typography>
                    <Typography className={styles.GreyText}>{clinic?.nearestSubwayDescription}</Typography>
                  </Col>
                </Row>
                <MapWithPlacemarks
                  mapState={{ zoom: 15, center: [Number(clinic.latitude), Number(clinic.longitude)] }}
                  className={styles.MapWrapper}
                >
                  <ClinicPlacemark
                    key={clinic.idMedOrganization}
                    latitude={clinic.latitude}
                    longitude={clinic.longitude}
                  />
                </MapWithPlacemarks>
              </Row>
            )}
          </>
        )}
        <CustomSearch
          floatLabel={clinic ? 'Специализация или врач в клинике' : 'Специализация, врач, клиника'}
          inputName={'search'}
          className={styles.Search}
          handleSearch={handleSearch}
          value={searchInput}
        />
        {searchInput ? (
          <GlobalSearch
            data={searchQuery.currentData}
            isLoading={searchQuery.isLoading || searchQuery.isFetching}
            searchInput={debouncedSearch}
          />
        ) : (
          <>
            <Row className={styles.ServicesRow}>
              <Typography className={styles.SubTitle}>
                {clinic ? 'Или выберите тип приема для записи:' : 'Или выберите, куда вы хотите записаться:'}
              </Typography>
              <ServiceTypeChoice idMedOrganization={clinic?.idMedOrganization} />
            </Row>
          </>
        )}
      </Layout>
    </CustomModal>
  );
};
