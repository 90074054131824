import { Action, RecordsState } from 'src/features/Clinic/RecordsClinic/action.types';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { ItemsTabs } from 'src/common/types';
import { Layout, Row } from 'antd';
import { RecordsWrapper } from '../../../common/components/RecordsWrapper/RecordsWrapper';
import { itemsPerPage } from 'src/common/contants/constatns';
import { useEffect, useReducer } from 'react';
import { useGetClinicsRecordsQuery } from 'src/common/redux/api/apiPatientSlice';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

const tabsItems: ItemsTabs[] = [
  {
    label: 'Предстоящие записи',
    key: 'upcoming',
  },
  {
    label: 'Прошедшие записи',
    key: 'past',
  },
];

interface RecordsClinicProps {
  handleAddNewRecordClick?: () => void;
}

export const RecordsClinic = ({ handleAddNewRecordClick }: RecordsClinicProps) => {
  const [state, dispatch] = useReducer(
    (state: RecordsState, action: Action) => {
      switch (action.type) {
        case 'AddRecords':
          return { ...state, records: [...state.records, ...action.payload] };
        case 'NextPage':
          return { ...state, pageCount: state.pageCount + 1 };
        case 'RecordType':
          return { ...state, pageCount: 0, records: [], recordType: action.payload };
        case 'SelectedPerson':
          return { ...state, pageCount: 0, records: [], selectedPerson: action.payload };
        case 'Refetch':
          return { ...state, pageCount: 0, records: [] };
      }
    },
    { records: [], pageCount: 0, recordType: 'upcoming', selectedPerson: 'all' },
  );
  const { clinicId } = useParams();

  const {
    data: clinicRecordsData,
    isLoading,
    refetch: refetchClinicsRecordsData,
  } = useGetClinicsRecordsQuery(
    {
      type: state.recordType,
      medOrganizationId: clinicId,
      offset: itemsPerPage * state.pageCount,
      count: itemsPerPage,
      sort: 'created desc',
    },
    { skip: !clinicId },
  );

  useEffect(() => {
    if (clinicRecordsData?.data) {
      dispatch({ type: 'AddRecords', payload: clinicRecordsData.data });
    }
  }, [clinicRecordsData]);

  const handleTabClick = (key: string) => {
    dispatch({ type: 'RecordType', payload: key });
  };

  const handleNextLoad = () => {
    dispatch({ type: 'NextPage' });
  };

  return (
    <Layout className={styles.CalendarRecordsListWrapper}>
      <Row>
        <CustomCardTabs items={tabsItems} onChange={handleTabClick} />
      </Row>

      <RecordsWrapper
        records={state.records}
        handleAddNewRecordClick={handleAddNewRecordClick}
        selectedRecordType={state.recordType}
        isLoading={isLoading}
        refetchData={refetchClinicsRecordsData}
        handleNext={handleNextLoad}
        maxCount={clinicRecordsData?.total ?? 0}
      />
    </Layout>
  );
};
