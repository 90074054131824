import { AboutClinic } from './AboutClinic/AboutClinic';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { ClinicCard } from './ClinicCard/ClinicCard';
import { ClinicPromotions } from './ClinicPromotions/ClinicPromotions';
import { ClinicReviews } from './ClinicReviews/ClinicReviews';
import { CustomTabs } from 'src/common/components/CustomTabs/CustomTabs';
import { DoctorsBlock } from './DoctorsBlock/DoctorsBlock';
import { ItemsTabs } from 'src/common/types';
import { Layout, Row } from 'antd';
import { RecordsClinic } from './RecordsClinic/RecordsClinic';
import { useGetAboutClinicInfoQuery, useGetClinicInfoQuery } from 'src/common/redux/api/apiPatientSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

const items: ItemsTabs[] = [
  {
    label: 'Обзор',
    key: 'about',
  },
  {
    label: 'Записи',
    key: 'schedule',
  },
  {
    label: 'Врачи',
    key: 'doctors',
  },
  {
    label: 'Отзывы',
    key: 'reviews',
  },
  {
    label: 'Акции',
    key: 'promo',
  },
];

const itemsWithoutPromo: ItemsTabs[] = items.slice(0, 4);

export const Clinic = () => {
  const { clinicId } = useParams();
  const [params, setParams] = useSearchParams();
  const activeTab: string = useMemo(() => (params.get('activeTab') ? params.get('activeTab')! : 'about'), [params]);

  const { data: clinicInfo } = useGetClinicInfoQuery(clinicId!, { skip: !clinicId });
  const { data: clinicAboutInfo } = useGetAboutClinicInfoQuery(clinicId!, { skip: !clinicId });

  const visibleItems = useMemo(
    () => (clinicInfo?.hasPromotions ? items : itemsWithoutPromo),
    [clinicInfo?.hasPromotions],
  );

  const handleTabChange = (key: string) => {
    setParams({ activeTab: key });
  };

  return (
    <Layout className={styles.ClinicContainer}>
      <BackHeader title={clinicInfo?.shortName} className={styles.TitleRow} />

      {clinicInfo && <ClinicCard clinicInfo={clinicInfo} single />}

      <Row className={styles.InfoRow}>
        <CustomTabs items={visibleItems} activeKey={activeTab} onChange={handleTabChange} tabBarGutter={23} />
      </Row>

      <Row className={styles.ScrollWrapper}>
        {activeTab === 'about' && clinicAboutInfo && clinicInfo && (
          <AboutClinic clinicAbout={clinicAboutInfo} clinicInfo={clinicInfo} />
        )}
        {activeTab === 'doctors' && clinicId && <DoctorsBlock idMedOrganization={clinicId} />}
        {activeTab === 'schedule' && clinicInfo && <RecordsClinic />}
        {activeTab === 'promo' && clinicInfo?.hasPromotions && <ClinicPromotions />}
        {activeTab === 'reviews' && <Row className={styles.BlockRow}>{<ClinicReviews />}</Row>}
      </Row>
    </Layout>
  );
};
