import { CSSProperties } from 'react';
import { Col, Typography } from 'antd';
import { getGuidBasedColor } from '../../utils';
import styles from './styles.module.scss';

interface AvatarReplaceProps {
  classNameAvatar?: string;
  classNameText?: string;
  initials?: string;
  id: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const ReplaceAvatar = ({ classNameAvatar, classNameText, initials, id, onClick, style }: AvatarReplaceProps) => (
  <Col
    onClick={onClick}
    className={`${styles.ImageReplace} ${classNameAvatar}`}
    style={{ background: getGuidBasedColor(id), ...style }}
  >
    <Typography className={`${styles.ImageReplaceText} ${classNameText}`}>{initials}</Typography>
  </Col>
);
