import React from 'react';
import { CustomTabs } from 'src/common/components/CustomTabs/CustomTabs';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import UploadDocumentBtn from '../UploadDocumentBtn/UploadDocumentBtn';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import styles from './styles.module.scss';

type DocumentUploadTabsProps = {
  activeKey: 'oneDoc' | 'separateDocs';
  isShowUploadFilesBtn: boolean;
  isRequiredDocumentForm: boolean;
  saveBtnClass: string;
  onTabChange: (tab: string) => void;
  normalizeFile: (e: UploadChangeParam<UploadFile>) => (UploadChangeParam<UploadFile<any>> & any[]) | UploadFile<any>[];
  setIsActiveSaveBtn: (value: React.SetStateAction<boolean>) => void;
  setIsFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
};

const DocumentUploadTabs = ({
  activeKey,
  isShowUploadFilesBtn,
  isRequiredDocumentForm,
  saveBtnClass,
  onTabChange,
  normalizeFile,
  setIsActiveSaveBtn,
  setIsFileUploaded,
}: DocumentUploadTabsProps) => {
  const tabsItems = [
    {
      key: 'oneDoc',
      label: 'В один документ',
    },
    {
      key: 'separateDocs',
      label: 'В отдельные документы',
    },
  ];

  return (
    <>
      <CustomTabs
        items={tabsItems}
        activeKey={activeKey}
        containerClassName={styles.TabsContainer}
        className={styles.TabBtn}
        onChange={onTabChange}
        type="card"
      />
      {activeKey === 'oneDoc' && (
        <MilaMessage
          title="Вы можете загрузить несколько файлов в один документ"
          text="Например, если фотографируете многостраничный документ."
          className={styles.MessageBlock}
        />
      )}
      {activeKey === 'separateDocs' && (
        <MilaMessage
          title="Каждый файл будет загружен как отдельный документ"
          text={`Созданные документы будут добавлены в папку “Другое” с 
                  текущей датой. После загрузки вы сможете изменить дату, 
                  название и другие атрибуты таких документов, чтобы 
                  структурировать их хранение в медкарте`}
          className={styles.MessageBlock}
        />
      )}
      {isShowUploadFilesBtn && (
        <UploadDocumentBtn
          normalizeFile={normalizeFile}
          setIsActiveSaveBtn={setIsActiveSaveBtn}
          isRequiredDocumentForm={isRequiredDocumentForm}
          saveBtnClass={saveBtnClass}
          setIsFileUploaded={setIsFileUploaded}
        />
      )}
    </>
  );
};

export default DocumentUploadTabs;
