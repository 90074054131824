import { Col, List } from 'antd';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

export interface ListProps<T> {
  data: T[];
  renderFunc: (val: T) => ReactNode;
  containerClass?: string;
}

export const ListComponent = <T extends Object>({
  data,
  renderFunc,
  containerClass = styles.ListContainer,
}: ListProps<T>) => {
  return (
    <Col className={containerClass}>
      <List className={styles.List} itemLayout="vertical" dataSource={data} renderItem={renderFunc} />
    </Col>
  );
};
