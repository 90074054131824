import { Link } from 'react-router-dom';
import { Notifications, NotificationsActive } from 'src/common/icons';
import { useGetProfileNotificationsQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import styles from './styles.module.scss';

interface NotificationIconProps {
  iconClass?: string;
}

export const NotificationIcon = ({ iconClass }: NotificationIconProps) => {
  const { data: notifications } = useGetProfileNotificationsQuery({
    count: 10,
    offset: 0,
    sort: 'created',
  });

  const hasActiveNotifications = useMemo(() => notifications?.unreadCount !== 0, [notifications]);
  return (
    <Link className={styles.ButtonRow} to="/settings/userProfile/notifications">
      {hasActiveNotifications ? (
        <NotificationsActive className={`${styles.Button} ${iconClass}`} />
      ) : (
        <Notifications className={`${styles.Button} ${iconClass}`} />
      )}
    </Link>
  );
};
