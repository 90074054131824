import { CaretRight, Info, Location, RecommendationDoc } from 'src/common/icons';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import { MedcardRecommedation } from 'src/common/types';
import { Row } from 'antd';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import Typography from 'antd/es/typography/Typography';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export const Recommendation = ({ created, dosage, idClinic, name, typeName }: MedcardRecommedation) => (
  <WhiteRow className={styles.RecomendationRow}>
    <Row className={styles.Row}>
      <RecommendationDoc className={styles.GreySmallText} />
      <Typography>{dayjs(created).format('D MMMM YYYY, dddd')}</Typography>
    </Row>
    <Typography className={styles.MedicamentsRow}>{typeName}</Typography>
    <Typography>{name}</Typography>
    {dosage && (
      <Row>
        <Info className={styles.Icon} />
        <Typography>{dosage}</Typography>
      </Row>
    )}
    <Row className={styles.Row}>
      <Location className={styles.Icon} />
      <Typography>VH Павелецкая</Typography>
      <CaretRight />
    </Row>
    {idClinic && (
      <Link to={`/catalog/${idClinic}`} className={styles.Link}>
        <CustomButton className={styles.Button}>
          <Typography className={styles.SmallText}>Записаться в клинику</Typography>
        </CustomButton>
      </Link>
    )}
  </WhiteRow>
);
