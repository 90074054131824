import React, { useEffect, useMemo, useState } from 'react';

import { ActiveProfileState, MedCarLastUpdate } from '../../../common/types';
import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Col, Row, Typography } from 'antd';
import { CustomButton } from '../../../common/components/CustomButton/CustomButton';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { DocumentDownload, Export } from '../../../common/icons';
import { MedcardDocumentLoadForm } from '../../MedCard/MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { ShareModal } from '../../MedCard/ShareModal/ShareModal';
import { WhiteRow } from '../../../common/components/WhiteRow/WhiteRow';
import { getProfileNameLabel } from '../../../common/utils';
import { useGetMedcardUpdateDateQuery, useGetProfilesQuery } from '../../../common/redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { minimumDate } from '../../../common/contants/constatns';
import { streamMedCardDateApi } from 'src/common/redux/api/apiStreamSlice';

export const MedcardBlock = ({ className = '' }) => {
  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const [isShareMedcardModalOpen, setIsShareMedcardModalOpen] = useState(false);
  const [isDocumentOpen, setDocumentOpen] = useState(false);

  const [activeProfile, setActiveProfile] = useState<ActiveProfileState>();

  //{ latestAppointmentTimestamp: string }
  const { data: crId, isLoading: isLoadingMedcardDate } = useGetMedcardUpdateDateQuery(activeProfile?.idProfile!, {
    refetchOnMountOrArgChange: true,
  });

  const [medacardDate, setMedacardDate] = useState<MedCarLastUpdate>();
  const [newMedacardDate, setNewMedacardDate] = useState<MedCarLastUpdate>();

  const [processMessage] = streamMedCardDateApi((item) => {
    // join data for the same CrId!!
    setNewMedacardDate(item);
  });

  useEffect(() => {
    if (newMedacardDate && (!medacardDate || dayjs(newMedacardDate.latestAppointmentTimestamp).isAfter(dayjs(medacardDate.latestAppointmentTimestamp)))) {
        setMedacardDate(newMedacardDate);
    }
  }, [newMedacardDate]);

  useEffect(() => {
    setMedacardDate(undefined);
    processMessage(crId);
  }, [crId]);

  const navigate = useNavigate();

  const handleProfileClick = (profile?: ActiveProfileState) => {
    setActiveProfile(profile);
  };

  const handleShowMedcard = () => {
    navigate(`/medcards/${activeProfile?.idProfile}`);
  };

  const handleOpenShareMedcardModal = () => {
    setIsShareMedcardModalOpen(true);
  };

  const handleCloseShareMedcardModal = () => {
    setIsShareMedcardModalOpen(false);
  };

  const handleDocumentOpen = () => setDocumentOpen(true);
  const handleDocumentClose = () => setDocumentOpen(false);

  const cardProfiles = useMemo(
    () =>
      profiles
        ?.slice(0, 5)
        .map((profile) => ({
          profileName: getProfileNameLabel(profile),
          ...profile,
        }))
        .sort((a, b) => Number(b.itSelf) - Number(a.itSelf)),
    [profiles],
  );

  const lastCardUpdateDate = useMemo(
    () =>
      `Карта обновлена ${dayjs(medacardDate?.latestAppointmentTimestamp).format('DD.MM.YYYY')} в ${dayjs(
        medacardDate?.latestAppointmentTimestamp,
      ).format('HH:mm')}`,
    [medacardDate],
  );

  useEffect(() => {
    cardProfiles &&
      setActiveProfile({
        ...cardProfiles[0],
      });
  }, [cardProfiles]);

  return isLoadingProfiles && isLoadingMedcardDate ? (
    <CustomSpinner />
  ) : (
    <WhiteRow className={`${styles.MedcardBlock} ${className}`}>
      <Row>
        <Typography.Text className={styles.MedcardTitle}>Медкарта</Typography.Text>
      </Row>
      <Row className={styles.MedcardProfile}>
        <Col className={styles.ProfileNameWrapper}>
          <Typography.Text>{activeProfile?.profileName}</Typography.Text>
          {dayjs(medacardDate?.latestAppointmentTimestamp).isAfter(minimumDate) && (
            <Typography.Text className={styles.CardUpdateInfo}>{lastCardUpdateDate}</Typography.Text>
          )}
        </Col>
        <Col className={styles.AvatarsWrapper}>
          {cardProfiles?.map((profile, index) => (
            <Avatar
              idPhoto={profile?.idPhoto}
              idProfile={profile.idProfile}
              className={`${styles.Avatar} ${activeProfile?.idProfile === profile.idProfile && styles.SelectedAvatar}`}
              onClick={() => handleProfileClick(profile)}
              style={{ zIndex: activeProfile?.idProfile === profile.idProfile ? 5 : cardProfiles.length - index }}
              key={profile.idProfile}
              name={`${profile.surname} ${profile.name}`}
              classNameText={styles.ImageReplaceText}
            />
          ))}
        </Col>
      </Row>
      <Row className={styles.ButtonsRow}>
        <CustomButton isPrimal className={styles.ShowButton} onClick={handleShowMedcard}>
          <Typography.Text>Смотреть</Typography.Text>
        </CustomButton>
        <CustomButton className={styles.SmallButton} onClick={handleOpenShareMedcardModal}>
          <Export />
        </CustomButton>
        <CustomButton className={styles.SmallButton} onClick={handleDocumentOpen}>
          <DocumentDownload />
        </CustomButton>
      </Row>

      <ShareModal
        idProfile={activeProfile?.idProfile}
        isModalOpen={isShareMedcardModalOpen}
        onCancelClick={handleCloseShareMedcardModal}
      />

      <CustomModal isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
        <MedcardDocumentLoadForm onFinish={handleDocumentClose} idMedcardProfile={activeProfile?.idProfile} />
      </CustomModal>
    </WhiteRow>
  );
};
