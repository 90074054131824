import { useEffect, useState } from 'react';

const widgetUrl: string = window.__RUNTIME_CONFIG__.REACT_APP_TM_COMMUNICATION_URL;

interface TMKChatWidgetProps {
  userId: string;
  roomId: string;
  sessionId: string;
  name?: string;
}

export const TMKChatWidget = ({ userId, roomId, sessionId, name = 'TMCommunication' }: TMKChatWidgetProps) => {
  const scriptId = `micro-frontend-script-${name}`;
  const styleId = `micro-frontend-style-${name}`;
  const link = `${widgetUrl}`;

  const [isListenerAdd, setListenerAdd] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);

  const renderMicroFrontend = (name: string) => {
    // @ts-ignore
    window[`render${name}`](`${name}-container`);
  };

  const getUrl = (val: string) => {
    let url = val;
    try {
      url = new URL(val)?.href;
    } catch {
      try {
        url = new URL(`${window.location.origin}${val}`)?.href;
      } catch {
        /* empty */
      }
    }

    return url;
  };


  const listener = () => {
    try {
      window?.postMessage(
        {
          isActualCommunicationData: true,
          path: link, // url где развёрнут вкс
          data: {
            route: 'messenger',
            userId, //идентификатор пользователя
            showChats: true,
            externalRoomId: roomId, // идентификатор комнаты
            sessionId, //идентификатор сессии если используется tm.auth                   }
          },
        },
        '*',
      );
    } catch (e) {
      console.error(e);
    }
    setListenerAdd(true);
  };

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      renderMicroFrontend(name);
      return;
    }
    

    const configScriptId = `micro-frontend-config-script-${name}`;

    const url = getUrl(link);

    if (isFirstLoad) {
      fetch(`${url}/asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          const scriptConfig = document.createElement("script");
          scriptConfig.src = `${url}/config.js`;
          scriptConfig.id = configScriptId;
          scriptConfig.crossOrigin = "";
          document.head.appendChild(scriptConfig);
          const script = document.createElement('script');
          script.id = scriptId;
          script.crossOrigin = '';
          const hostElements = url.split('/');
          const srcElements: string[] = [];
          manifest.files['main.js']?.split('/')?.forEach((f: string) => {
            if (!hostElements.includes(f)) {
              srcElements.push(f);
            }
          });
          script.src = `${url}/${srcElements.join('/')}`;
          scriptConfig.onload = () => {
            script.onload = () => {
              renderMicroFrontend(name);
            };
          };
          document.head.appendChild(script);

          if (manifest.files['main.css']) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            const hrefElements: string[] = [];
            manifest.files['main.css']?.split('/')?.forEach((f: string) => {
              if (!hostElements.includes(f)) {
                hrefElements.push(f);
              }
            });
            link.href = `${url}/${hrefElements.join('/')}`;
            link.id = styleId;
            document.head.appendChild(link);
          }
        })
        .catch((error) => console.warn(error));
      setFirstLoad(false);
    }

    return () => {
      // @ts-ignore
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
      document.getElementById(styleId)?.remove();
      document.getElementById(scriptId)?.remove();
      document.getElementById(configScriptId)?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListenerAdd]);

  useEffect(() => {
    listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, roomId, sessionId]);

  const loadIframe = ({ data }: { data: string }) => {
    if (data === 'communicationLoad') {
      listener();
    }
  };

  useEffect(() => {
    if (!isFirstLoad) listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', loadIframe, false);
    }
    return () => window.removeEventListener('message', loadIframe, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <main id={`${name}-container`} style={{ width: '100%', height: '100%' }} />;
};
