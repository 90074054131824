import { CloseModalMobileButton } from '../../icons';
import { Modal } from 'antd';
import React, { ReactElement } from 'react';
import styles from './styles.module.scss';

interface CustomModalProps {
  className?: string;
  children?: ReactElement;
  isOpen: boolean;
  onOkClick?: () => void;
  onCancelClick?: () => void;
}

export const CustomModal = ({ children, className = '', onOkClick, onCancelClick, isOpen }: CustomModalProps) => (
  <Modal
    open={isOpen}
    onOk={onOkClick}
    onCancel={onCancelClick}
    className={`${styles.DefaultModalContainer} ${className}`}
    closeIcon={<CloseModalMobileButton />}
    footer={null}
  >
    {children}
  </Modal>
);
