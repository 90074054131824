import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomSearch } from 'src/common/components/CustomSearch/CustomSearch';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { DoctorCard } from 'src/features/DoctorChoice/DoctorCard/DoctorCard';
import { Layout, Row, Typography } from 'antd';
import { filterDoctorsByAge } from 'src/common/utils';
import { useAppSelector } from 'src/app/hooks';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { useGetDoctorsQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

interface DoctorsBlockProps {
  idMedOrganization: string;
}

export const DoctorsBlock = ({ idMedOrganization }: DoctorsBlockProps) => {
  const [searchVal, setSearchVal] = useState<string>('');
  const debouncedSearchVal = useDebounce(searchVal, 300);

  const [params] = useSearchParams();
  const preselectedSpecialty: string = useMemo(
    () => (params.get('specialty') ? params.get('specialty')! : ''),
    [params],
  );

  const { data: doctors, isLoading } = useGetDoctorsQuery({ practiceId: idMedOrganization });

  const isChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);

  const filteredDoctors = useMemo(() => {
    if (doctors) {
      let filteredDoctors = filterDoctorsByAge([...doctors], isChildren, childrenAge, undefined, undefined);

      if (debouncedSearchVal) {
        filteredDoctors = filteredDoctors.filter(
          (doctor) =>
            doctor.fio?.toLowerCase().includes(debouncedSearchVal.toLowerCase()) ||
            doctor.specialitiesTags.some((tag) =>
              tag.specialityName?.toLowerCase().includes(debouncedSearchVal?.toLowerCase()),
            ),
        );
      }

      return filteredDoctors;
    }
    return doctors;
  }, [doctors, isChildren, debouncedSearchVal, childrenAge]);

  useEffect(() => {
    if (preselectedSpecialty) {
      setSearchVal(preselectedSpecialty);
    }
  }, [preselectedSpecialty]);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value);
  }, []);

  return (
    <Layout className={styles.DoctorsBlockWrapper}>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          <Row className={styles.DoctorsAgeToggleRow}>
            <Typography className={styles.Title}>Все врачи и специалисты в клинике</Typography>
            <AgeToggle containerClassName={styles.AgeContainer} mini />
          </Row>

          <CustomSearch floatLabel="Специализация, врач" inputName="" handleSearch={handleSearch} value={searchVal} allowClear={true} />

          {isLoading ? (
            <CustomSpinner />
          ) : (
            filteredDoctors?.map((doctor) => (
              <Row key={doctor.idMilaDoctor}>
                <DoctorCard doctor={doctor} withSchedule preselectedBranchId={idMedOrganization} />
              </Row>
            ))
          )}
        </>
      )}
    </Layout>
  );
};
