import { Button, Row, Typography } from 'antd';
import { DocumentsItem, RemoveDocumentIcon } from 'src/common/icons';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export interface FileItemProps {
  fileId?: string;
  fileName: string;
  remove?: () => void;
}

export const FileItem = ({ fileName, fileId, remove }: FileItemProps) => (
  <Row className={styles.DocumentItemWrapper}>
    <DocumentsItem className={styles.Icon} />
    {fileId ? (
      <Link target="_blank" to={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${fileId}`}>
        <Typography className={styles.DocumentNameLabel}>{fileName}</Typography>
      </Link>
    ) : (
      <Typography className={styles.DocumentNameLabel}>{fileName}</Typography>
    )}
    {remove && <Button icon={<RemoveDocumentIcon />} onClick={remove} />}
  </Row>
);
