import { AddProfileBlock } from './AddProfileBlock/AddProfileBlock';
import { Avatar } from '../Avatar/Avatar';
import { Col, Image, Row, Typography } from 'antd';
import { ConfirmedCircle } from '../ConfirmedCircle/ConfirmedCircle';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { Link, useLocation } from 'react-router-dom';
import { getProfileNameLabel, sortProfilesByItself } from '../../utils';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { Plus } from 'src/common/icons';
import React, { useMemo } from 'react';
import Sider from 'antd/es/layout/Sider';
import styles from './styles.module.scss';

type ProfileSidebarProps = {
  className?: string;
};

export const ProfileSidebar = ({ className = '' }: ProfileSidebarProps) => {
  const { data: profiles, isLoading } = useGetProfilesQuery();
  const location = useLocation();

  const shouldHide = useMemo(() => location.pathname.includes('onboarding'), [location]);

  const sortedProfiles = useMemo(() => (profiles ? sortProfilesByItself(profiles) : []), [profiles]);

  const isEsiaConnectedProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.isConfirmed, [profiles]);

  return !shouldHide ? (
    <Sider className={`${className} ${styles.profileSidebar}`} width={260}>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <Col className={styles.profiles}>
          {sortedProfiles?.map((profile) => (
            <Link to={`/settings/userProfile/${profile.idProfile}`} key={profile.idProfile}>
              <Row className={styles.profile}>
                <Col className={styles.info}>
                  <Avatar
                    idPhoto={profile.idPhoto}
                    className={styles.Avatar}
                    idProfile={profile?.idProfile!}
                    name={`${profile?.surname} ${profile.name}`}
                  />

                  <Col className={styles.texts}>
                    <Typography.Text className={styles.name}>{getProfileNameLabel(profile)}</Typography.Text>
                    <Typography className={styles.text}>
                      {profile.isConfirmed ? 'Подтвержденная учетная запись' : 'Неподтвержденная учетная запись'}
                    </Typography>
                  </Col>
                </Col>
                <Col>
                  <ConfirmedCircle
                    isConfirmed={profile.isConfirmed && profile.isDataReceptionAllowed}
                    idProfile={profile.idProfile}
                  />
                </Col>
              </Row>
            </Link>
          ))}
        </Col>
      )}
      <Link to="/settings/userProfile">
        <Row className={styles.addProfile}>
          <div className="ant-image">
            <Plus />
          </div>
          <Col>Добавить профиль</Col>
        </Row>
      </Link>
      {!isEsiaConnectedProfile && <AddProfileBlock />}
    </Sider>
  ) : (
    <></>
  );
};
