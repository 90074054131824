import { AppointmentButtons } from 'src/features/Appointment/AppointmentButtons/AppointmentButtons';
import { AppointmentPaymentStatus, AppointmentStatus, ServiceRequestType } from 'src/common/types/enums';
import { Avatar } from '../Avatar/Avatar';
import { CalendarRecord } from '../../types';
import { CancelAppointmentTag } from '../CancelAppointmentTag/CancelAppointmentTag';
import { Col, Divider, Row, Typography } from 'antd';
import { DoctorLabel } from '../DoctorLabel/DoctorLabel';
import { HomeClinicIcon, Location, OnlineClinicIcon } from '../../../common/icons';
import { Link } from 'react-router-dom';
import { PriceTag } from '../PriceTag/PriceTag';
import { RoutingButton } from '../RoutingButton/RoutingButton';
import {
  checkIsPaid,
  checkPaymentStatus,
  getAppointmentStatusByCreated,
  getMinutesToNow,
  getTmkMinutesLeftLabel,
  removeCents,
} from '../../utils';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const getClinicIcon = (clinicType: string) => {
  if (clinicType === 'Амбулаторный приём') {
    return <Location className={styles.ClinicIcon} />;
  } else if (clinicType === 'Онлайн-консультация') {
    return <OnlineClinicIcon className={styles.ClinicIcon} />;
  } else {
    return <HomeClinicIcon className={styles.ClinicIcon} />;
  }
};

interface CalendarRecordCardProps {
  appointment: CalendarRecord;
  refetchData?: () => void;
}

export const CalendarRecordCard = ({ appointment, refetchData }: CalendarRecordCardProps) => {
  const id = useMemo(
    () => (appointment.idAppointment ? appointment.idAppointment : appointment.idExternalAppointment),
    [appointment],
  );

  const cardTag = useMemo(() => {
    if (appointment.isCancelled) {
      return (
        <CancelAppointmentTag
          price={removeCents(appointment.paymentCost!)}
          isMoneyBack={checkPaymentStatus(appointment.paymentStatus).refundedMoney}
          isTmk={appointment.serviceDeliveryTypeName === 'Онлайн-консультация'}
          canceledPayment={checkPaymentStatus(appointment.paymentStatus).canceledPayment}
        />
      );
    } else {
      return (
        <PriceTag
          price={removeCents(appointment.paymentCost)}
          isPaid={checkIsPaid(appointment.paymentStatus)}
          appointmentPaymentStatusName={appointment.paymentStatusName}
          paymentType={appointment.paymentTypeName}
        />
      );
    }
  }, [appointment]);

  const handleRoutingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(
      `https://yandex.ru/maps/?rtext=~${appointment.medOrganization.latitude}%2C${appointment.medOrganization.longitude}`,
      '_blank',
    );
  };

  const tmkMinutesLeft = useMemo(
    () => (getMinutesToNow(appointment.created) <= 120 ? getTmkMinutesLeftLabel(appointment.created) : ''),
    [appointment],
  );

  const isTmkLeftMinutesLabelVisible = useMemo(
    () =>
      appointment.serviceDeliveryType === ServiceRequestType.Online &&
      tmkMinutesLeft &&
      getMinutesToNow(appointment.created) > 0 &&
      !appointment.isCancelled &&
      appointment.paymentStatus === AppointmentPaymentStatus.MoneyReserved &&
      getAppointmentStatusByCreated(appointment.created) !== AppointmentStatus.Past,
    [appointment, tmkMinutesLeft],
  );

  return (
    <>
      <Link to={`/records/appointment/${id}`} className={styles.CalendarCardWrapper} key={id}>
        <Row className={styles.DateRow}>
          <Col className={styles.PatientAvatarContainer}>
            <Avatar
              idPhoto={appointment.patient.idPhoto}
              className={`${styles.Avatar}`}
              name={appointment.patient.fullName}
              idProfile={appointment.patient.idPatient}
            />
          </Col>
          <Col className={styles.ServiceNameAndTime}>
            <Typography className={styles.ServiceName}>{appointment.doctor.serviceName}</Typography>
            <Typography className={styles.Time}>{dayjs(appointment.created).format('HH:mm')}</Typography>
          </Col>
        </Row>
        <Divider />

        <Col className={styles.CardContentContainer}>
          <Col className={styles.ProfileCol}>
            <DoctorLabel
              id={appointment.doctor.idMilaDoctor}
              name={appointment.doctor.fullName}
              specialty={appointment.doctor.specializationName}
            />
            <Row className={styles.ClinicBlockRow}>
              {getClinicIcon(appointment.serviceDeliveryTypeName)}
              <Col className={styles.ClinicNameBlock}>
                <Link to={`/catalog/${appointment.medOrganization.idMedOrganization}`}>
                  {appointment.serviceDeliveryTypeName === 'Онлайн-консультация'
                    ? appointment.serviceDeliveryTypeName
                    : appointment.medOrganization.shortName}
                </Link>
                <Typography className={styles.AddressText}>{appointment.medOrganization.address}</Typography>
              </Col>

              {!(appointment.serviceDeliveryTypeName === 'Онлайн-консультация') && (
                <Col onClick={handleRoutingClick}>
                  <RoutingButton
                    latitude={appointment.medOrganization.latitude}
                    longitude={appointment.medOrganization.longitude}
                  />
                </Col>
              )}
            </Row>

            {cardTag}
          </Col>

          <Col>
            <AppointmentButtons appointment={appointment} isList refetchData={refetchData} />
            {isTmkLeftMinutesLabelVisible && (
              <Row className={styles.TimeLeftRow}>
                <Typography className={styles.TimeLeftText}>{`Осталось ${tmkMinutesLeft} минут`}</Typography>
              </Row>
            )}
          </Col>
        </Col>
      </Link>
    </>
  );
};
