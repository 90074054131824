export const isInFrame = (): boolean => {
  try {
    return window.top !== window.self;
  } catch {
    return true;
  }
};

type ExternalWndProps = {
  proportion?: number;
  target?: string;
};

export const openExternalProportionalWindow = (location: string = '', props: ExternalWndProps = {}) => {
  const { proportion = 0.75 } = props;
  const { target = '_blank' } = props;
  const maxWidth = window.screen?.width || window.outerWidth;
  const maxHeight = window.screen?.height || window.outerHeight;
  const width = Math.round(maxWidth * proportion);
  const height = Math.round(maxHeight * proportion);
  const top = Math.round(((1 - proportion) / 3) * maxHeight);
  const left = Math.round(((1 - proportion) / 3) * maxWidth);

  const options = `location=no,resizeable=yes,menubar=no,width=${width},height=${height},top=${top},left=${left}"`;

  return window.open(location, target, options)!;
};

export const externalWindowResult = async <T>(
  externalWindow: Window | undefined,
  awaitedType: string,
  cancelRef?: { current?: () => void },
) => {
  return new Promise<T>((resolve, reject) => {
    if (!externalWindow) {
      return reject({ window_closed: '' });
    }

    const listener = (evt: MessageEvent) => {
      if (evt.data?.type !== awaitedType) {
        return;
      }

      if (evt.data.status === 'error') {
        finish();
        reject({ '*': evt.data.error });
        return;
      }
      finish();
      resolve(evt.data?.payload);
    };

    window.addEventListener('message', listener);

    const finish = () => {
      window.removeEventListener('message', listener);
      clearInterval(ticker);
    };

    const ticker = setInterval(() => {
      if (externalWindow.closed) {
        reject();
        finish();
      }
    }, 300);

    if (cancelRef !== undefined) {
      cancelRef.current = finish;
    }
  });
};
