import React, { useEffect } from 'react';

type YandexShareProps = {
  state?:
    | {
        theme: {
          services: string;
          lang: string;
          colorScheme: string;
          direction: string;
          size: string;
          shape: string;
          bare: boolean;
          copy: string;
          useLinks: boolean;
        };
        content: string;
      }
    | {};
  className?: string;
  text?: string;
};

const defaultState = {
  theme: {
    services: 'vkontakte,odnoklassniki,telegram,whatsapp',
    lang: 'ru',
    colorScheme: 'whiteblack',
    direction: 'vertical',
    size: 'm',
    shape: 'round',
    bare: false,
    copy: 'extraItem',
    useLinks: true,
  },
  content: {
    url: '',
  },
};

export const YandexShare = ({ state = {}, className = '', text = '' }: YandexShareProps) => {
  useEffect(() => {
    // @ts-ignore
    window.Ya.share2('ya', { ...defaultState, ...state, ...{ content: { url: text } } });
  }, [state, text]);
  return <div id="ya" className={className ?? ''} />;
};

export default YandexShare;
