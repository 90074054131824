import { AgreementModal } from '../../common/components/AgreementModal/AgreementModal';
import { BackHeader } from '../../common/components/BackHeader/BackHeader';
import { ButtonsBlock } from './ButtonsBlock';
import { Checkbox, Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CustomSpinner } from '../../common/components/CustomSpinner/CustomSpinner';
import { OnboardingMessage } from './OnboardingMessage';
import { getChildrenBirthDate } from '../../common/utils';
import {
  idMedicalDataAccessConsent,
  idNotificationsAgreementConsent,
  idPersonalDataConsent,
} from '../../common/contants/constatns';
import {
  useAddChildrenToMedcardsMutation,
  useAddMedOrganizationDataToMedcardsMutation,
  useGetIsOnboardingRequiredQuery,
  useGetOnboardingClinicsQuery,
  useGetProfileAuthTypeQuery,
  useGetProfilesQuery,
  useSetOnboardingPassedMutation,
  useUpdateConsentMutation,
} from '../../common/redux/api/apiPatientSlice';
import {useGetWhiteLabelInfoQuery
} from '../../common/redux/api/apiWhiteLabelSlice';
import { useEffect, useMemo, useState } from 'react';
import { useGetChildrensQuery } from '../../common/redux/api/apiEsiaAuthSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import keycloak from '../../keycloak';
import { useAppSelector } from '../../app/hooks';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  const { data: clinics, isLoading: isLoadingClinics } = useGetOnboardingClinicsQuery();
  const { data: onboardingStatus, isLoading: isLoadingStatus } = useGetIsOnboardingRequiredQuery();
  const { data: authType } = useGetProfileAuthTypeQuery();
  const { data: childrens } = useGetChildrensQuery(keycloakObj);
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const [updateConsent] = useUpdateConsentMutation();
  const [addChildrenToMedcards] = useAddChildrenToMedcardsMutation();
  const [addMedOrganizationDataToMedcards] = useAddMedOrganizationDataToMedcardsMutation();
  const [setOnboardingPassed] = useSetOnboardingPassedMutation();
  const [isMedDataViewConsentSigned, setMedDataViewConsentSignedStatus] = useState<boolean>(false);
  const [isMedDataViewConsentTouched, setMedDataViewConsentSignedTouched] = useState<boolean>(false);

  const [isNotificationsConsentSigned, setNotificationsConsentSigned] = useState<boolean>(false);
  const [isNotificationsConsentTouched, setNotificationsConsentSignedTouched] = useState<boolean>(false);
  const [isNotificationBlockShown, setNotificationBlockShown] = useState<boolean>(false);

  const [isChildrensBlockShown, setChildrensBlockShown] = useState<boolean>(false);
  const [isChildrensConsentSigned, setChildrensConsentSigned] = useState<boolean>(false);
  const [isChildrensConsentTouched, setChildrensConsentSignedTouched] = useState<boolean>(false);
  const [isFinishOnboardingBlockShown, setisFinishOnboardingBlockShown] = useState<boolean>(false);

  const [messageIndex, setMessageIndex] = useState<number>(0);

  const isHasChildren = useMemo(() => childrens && childrens.length > 0, [childrens]);

  const childrenIds = useMemo(() => childrens?.map((child) => child.id), [childrens]);

  const [checkedChildren, setIsCheckedChildren] = useState<string[]>([]);

  const mainProfileId = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile, [profiles]);

  const mainProfileName = useMemo(() => profiles?.find((profile) => profile.itSelf)?.name, [profiles]);

  const handleCheckChildren = (event: CheckboxChangeEvent) => {
    let updatedList = [...checkedChildren];
    if (!checkedChildren?.includes(event.target.id!)) {
      updatedList = [...checkedChildren!, event.target.id!];
    } else {
      updatedList.splice(checkedChildren.indexOf(event.target.id!), 1);
    }
    setIsCheckedChildren(updatedList);
  };

  useEffect(() => {
    if (onboardingStatus && mainProfileId) {
      if (onboardingStatus?.isOnboardingRequired)
        updateConsent({ idMilaConsent: idPersonalDataConsent, idProfile: mainProfileId!, isActive: true });
      else {
        if (location?.state?.prevLocationParams && location.state?.prevLocationPath) {
          navigate(`${location.state.prevLocationPath}${location.state.prevLocationParams}`);
        } else if (location?.state?.prevLocationPath) {
          navigate(location.state.prevLocationPath);
        } else {
          navigate('/home');
        }
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [onboardingStatus, onboardingStatus?.isOnboardingRequired, mainProfileId, navigate, updateConsent]);

  useEffect(() => {
    setIsCheckedChildren(childrenIds!);
  }, [childrenIds]);

  const foundClinicIds = useMemo(() => clinics?.map((clinic) => clinic.idMedOrganization), [clinics]);

  const handleSignMedDataAgreement = () => {
    setMedDataViewConsentSignedTouched(true);

    updateConsent({ idMilaConsent: idMedicalDataAccessConsent, idProfile: mainProfileId!, isActive: true })
      .unwrap()
      .then(() => {
        setMedDataViewConsentSignedStatus(true);
        setNotificationBlockShown(true);
        setMessageIndex(0);
      });

    addMedOrganizationDataToMedcards({ externalClinicIds: foundClinicIds! });
  };

  const handleDeclineMedDataAgreement = () => {
    setMedDataViewConsentSignedTouched(true);
    updateConsent({ idMilaConsent: idMedicalDataAccessConsent, idProfile: mainProfileId!, isActive: false })
      .unwrap()
      .then(() => {
        setNotificationBlockShown(true);
        setMessageIndex(0);
      });
  };

  const handleAcceptNotifications = () => {
    setNotificationsConsentSignedTouched(true);
    updateConsent({ idMilaConsent: idNotificationsAgreementConsent, idProfile: mainProfileId!, isActive: true })
      .unwrap()
      .then(() => {
        setNotificationsConsentSigned(true);

        isHasChildren ? setChildrensBlockShown(true) : setisFinishOnboardingBlockShown(true);
        setMessageIndex(0);
      });
  };

  const handleDeclineNotifications = () => {
    setNotificationsConsentSignedTouched(true);
    updateConsent({ idMilaConsent: idNotificationsAgreementConsent, idProfile: mainProfileId!, isActive: false })
      .unwrap()
      .then(() => {
        isHasChildren ? setChildrensBlockShown(true) : setisFinishOnboardingBlockShown(true);
        setMessageIndex(0);
      });
  };

  const handleAcceptChildrenInfo = () => {
    setChildrensConsentSignedTouched(true);
    addChildrenToMedcards({body: { children: checkedChildren?.map((child) => ({ id: child, isConsentSigned: true })) }, keycloakObj})
      .unwrap()
      .then(() => {
        setChildrensConsentSigned(true);
        setisFinishOnboardingBlockShown(true);
        setMessageIndex(0);
      });
  };

  const handleDeclineChildrenInfo = () => {
    setChildrensConsentSignedTouched(true);
    setisFinishOnboardingBlockShown(true);
    setMessageIndex(0);
  };

  const handleFinishOnboarding = () => {
    setOnboardingPassed();

    if (location.state?.prevLocationParams && location.state?.prevLocationPath) {
      navigate(`${location.state.prevLocationPath}${location.state.prevLocationParams}`);
    } else if (location.state?.prevLocationPath) {
      navigate(location.state.prevLocationPath);
    } else {
      navigate('/home');
    }
  };

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [openedConsentId, setOpenedConsentId] = useState('');

  const handleAgreementDownload = (fileId?: string) => {
    if (fileId) {
      window.open(
          `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${fileId}?inline=false`,
          '_blank',
          'noopener,noreferrer',
      );
    }
  };

  const handleOpenAgreementMedDocsModal = () => {
    if (whiteLabel?.idMedCardAgreementFile) {
      return handleAgreementDownload(whiteLabel?.idMedCardAgreementFile);
    }
    setAgreementModalOpen(true);
    setOpenedConsentId(idMedicalDataAccessConsent);
  };

  const handleOpenAgreementNotificationsModal = () => {
    if (whiteLabel?.idNotificationAgreementFile) {
      return handleAgreementDownload(whiteLabel?.idNotificationAgreementFile);
    }
    setAgreementModalOpen(true);
    setOpenedConsentId(idNotificationsAgreementConsent);
  };

  const handleCloseModal = () => {
    setAgreementModalOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (messageIndex > 5) {
        return;
      } else {
        setMessageIndex(messageIndex + 1);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [messageIndex]);

  return (isLoadingStatus && isLoadingProfiles) || !onboardingStatus ? (
    <CustomSpinner />
  ) : (
    <>
      <BackHeader title="Настройки профиля" />

      <Row className={styles.MessageContainer}>
        <OnboardingMessage withIcon>
          <Row className={styles.MessageRow}>
            <Typography className={styles.MessageText}>
              Привет, {mainProfileName || keycloak(keycloakObj).getUserName()} 🙋‍♀️ {isWhiteLabelEnabled ? '' : 'Я Mila'}. Я помогу настроить ваш профиль, это очень
              быстро.
            </Typography>
          </Row>
        </OnboardingMessage>
      </Row>

      {/* Gosuslugi or Sber way */}

      <Row
        className={`${
          messageIndex > 2 || isNotificationBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
        }`}
      >
        {authType === 'esia' && (
          <Row className={styles.MessageContainer}>
            <OnboardingMessage>
              <Row className={styles.MessageRow}>
                <Typography className={styles.MessageText}>
                  Вы вошли через Госуслуги, у вас подтвержденная учетная запись и максимальные права доступа к
                  медицинским данным и сервисам{isWhiteLabelEnabled ? '' : ' Mila'}.
                </Typography>
              </Row>
            </OnboardingMessage>
          </Row>
        )}

        {authType === 'sber' && (
          <Row className={styles.MessageContainer}>
            <OnboardingMessage>
              <Row className={styles.MessageRow}>
                <Typography className={styles.MessageText}>
                  Вы вошли через Сбер ID, у вас подтвержденная учетная запись. Вам доступны все сервисы{isWhiteLabelEnabled ? '' : ' Mila'} и данные из
                  подключенных частных клиник. Сведения из государственных информационных систем могут быть доступны
                  частично.
                </Typography>
              </Row>
            </OnboardingMessage>
          </Row>
        )}
      </Row>

      {/* Found clinics list */}

      <>
        {clinics && clinics?.length > 0 ? (
          <>
            <Row
              className={`${styles.MessageContainer} ${
                messageIndex > 3 || isNotificationBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
              }`}
            >
              <OnboardingMessage>
                <Row className={styles.MessageRow}>
                  <Typography className={styles.MessageText}>
                    Я уже проверила подключенные ко мне клиники и лаборатории и нашла информацию о вас в этих
                    организациях:
                  </Typography>
                  {isLoadingClinics ? (
                    <CustomSpinner />
                  ) : (
                    clinics?.map((clinic) => (
                      <Row className={styles.ClinicOptionBlock} key={clinic.idMedOrganization}>
                        <Typography className={styles.MedOrganizationText}>{clinic.shortName}</Typography>
                      </Row>
                    ))
                  )}
                </Row>
              </OnboardingMessage>
            </Row>
            <Row
              className={`${styles.MessageContainer} ${
                messageIndex > 4 || isNotificationBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
              }`}
            >
              <OnboardingMessage withIcon>
                <Row className={styles.MessageRow}>
                  <Typography className={styles.MessageText}>
                    Хотите ли вы загрузить данные из этих организаций в свою медкарту? Для этого мне потребуется ваше
                    согласие
                    <Typography.Link className={styles.TextLink} onClick={handleOpenAgreementMedDocsModal}>
                      {' на получение и просмотр медицинских сведений'}
                    </Typography.Link>
                    . Вы в любой момент сможете отключить доступ в настройках профиля.
                  </Typography>
                </Row>
              </OnboardingMessage>
            </Row>
          </>
        ) : (
          <Row
            className={`${styles.MessageContainer} ${
              messageIndex > 4 || isNotificationBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
            }`}
          >
            <OnboardingMessage withIcon>
              <Row className={styles.MessageRow}>
                <Typography className={styles.MessageText}>
                  Хотите ли вы, чтобы я собирала ваши медицинские данные из подключенных ко мне клиник и лабораторий?
                  Для этого мне потребуется ваше согласие
                  <Typography.Link className={styles.TextLink} onClick={handleOpenAgreementMedDocsModal}>
                    {' на получение и просмотр медицинских сведений'}
                  </Typography.Link>
                  . Вы в любой момент сможете отключить доступ в настройках профиля.
                </Typography>
              </Row>
            </OnboardingMessage>
          </Row>
        )}
      </>

      <Row
        className={`${styles.SingleButtonsContainer} ${
          messageIndex > 5 || isNotificationBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
        }`}
      >
        <ButtonsBlock
          acceptButtonText="Подписать согласие"
          declineButtonText="Отказаться от просмотра медицинских сведений"
          acceptOnClick={handleSignMedDataAgreement}
          declineOnClick={handleDeclineMedDataAgreement}
          isAcceptButtonDisabled={isMedDataViewConsentSigned && isMedDataViewConsentTouched}
          isDeclineButtonDisabled={isMedDataViewConsentTouched && !isMedDataViewConsentSigned}
          isFirstBlock
        />
      </Row>

      {/* Notification block */}
      <Row
        className={`${
          messageIndex > 1 || isChildrensBlockShown || isFinishOnboardingBlockShown
            ? styles.AnimationShowContainer
            : styles.HiddenContainer
        }`}
      >
        {isNotificationBlockShown && (
          <>
            {isMedDataViewConsentSigned ? (
              <Row className={styles.MessageContainer}>
                <OnboardingMessage withIcon>
                  <Row className={styles.MessageRow}>
                    <Typography className={styles.MessageText}>Все в порядке! Я уже ищу ваши документы. 👍</Typography>
                  </Row>
                </OnboardingMessage>
              </Row>
            ) : (
              <Row className={styles.MessageContainer}>
                <OnboardingMessage withIcon>
                  <Row className={styles.MessageRow}>
                    <Typography className={styles.MessageText}>
                      Поняла. Вы сможете записываться на консультации, но медкарту придется вести вручную. Если
                      передумаете - можете в любой момент включить получение данных в настройках профиля.
                    </Typography>
                  </Row>
                </OnboardingMessage>
              </Row>
            )}
          </>
        )}
      </Row>

      {isNotificationBlockShown && (
        <Row
          className={`${styles.MessageContainer} ${
            messageIndex > 2 || isChildrensBlockShown || isFinishOnboardingBlockShown
              ? styles.AnimationShowContainer
              : styles.HiddenContainer
          }`}
        >
          <Row className={styles.MessageContainer}>
            <OnboardingMessage>
              <Row className={styles.MessageRow}>
                <Typography className={styles.MessageText}>
                  Кстати, очень рекомендую загрузить архив медицинских документов, которые хранятся у вас дома, чтобы
                  всегда под рукой была полная история. Вы сможете открывать временный доступ к медкарте для врачей или
                  родственников.
                </Typography>
              </Row>
            </OnboardingMessage>
          </Row>
        </Row>
      )}

      {isNotificationBlockShown && (
        <Row
          className={`${styles.MessageContainer} ${
            messageIndex > 2 || isChildrensBlockShown || isFinishOnboardingBlockShown
              ? styles.AnimationShowContainer
              : styles.HiddenContainer
          }`}
        >
          {(messageIndex > 2 || isChildrensBlockShown || isFinishOnboardingBlockShown) && (
            <>
              <OnboardingMessage>
                <Row className={styles.MessageRow}>
                  <Typography className={styles.MessageText}>
                    Ещё я могу отслеживать статусы по вашим записям и появление новых медицинских данных и сообщать вам
                    на e-mail. Для этого, пожалуйста, разрешите мне присылать уведомления согласно{' '}
                    <Typography.Link className={styles.TextLink} onClick={handleOpenAgreementNotificationsModal}>
                      {' условиям'}
                    </Typography.Link>
                    .
                  </Typography>
                </Row>
              </OnboardingMessage>
            </>
          )}
          <Row
            className={`${styles.SingleButtonsContainer} ${
              messageIndex > 3 || isChildrensBlockShown || isFinishOnboardingBlockShown
                ? styles.AnimationShowContainer
                : styles.HiddenContainer
            }`}
          >
            <ButtonsBlock
              acceptButtonText="Разрешить уведомления"
              declineButtonText="Запретить уведомления"
              acceptOnClick={handleAcceptNotifications}
              declineOnClick={handleDeclineNotifications}
              isAcceptButtonDisabled={isNotificationsConsentSigned && isNotificationsConsentTouched}
              isDeclineButtonDisabled={!isNotificationsConsentSigned && isNotificationsConsentTouched}
            />
          </Row>
        </Row>
      )}

      {/* Childrens block */}
      {isHasChildren && isChildrensBlockShown && (
        <Row
          className={`${
            messageIndex > 1 || isFinishOnboardingBlockShown ? styles.AnimationShowContainer : styles.HiddenContainer
          }`}
        >
          <Row className={styles.MessageContainer}>
            <OnboardingMessage>
              <Row className={styles.MessageRow}>
                <Typography className={styles.MessageText}>
                  Хорошо😊 Еще я могу сразу добавить медкарты для ваших детей, т.к. получила их данные из вашего
                  аккаунта на Госуслугах. Потребуется ваше{' '}
                  <Typography.Link className={styles.TextLink} onClick={handleOpenAgreementMedDocsModal}>
                    согласие
                  </Typography.Link>
                  .
                </Typography>
                {childrens?.map((children) => (
                  <Row className={styles.ChildrenBlock} key={children.id}>
                    <Row>
                      <Checkbox
                        className={styles.ChildrenNameCheckbox}
                        onChange={handleCheckChildren}
                        checked={checkedChildren?.includes(children.id)}
                        id={children.id}
                      ></Checkbox>
                    </Row>
                    <Row className={styles.ChildrenNamesBlock}>
                      <Typography
                        className={styles.ChildrenNameText}
                      >{`${children.lastName} ${children.firstName}`}</Typography>
                      <Typography className={styles.ChildrenBirthdayText}>
                        {getChildrenBirthDate(children.birthDate)}
                      </Typography>
                    </Row>
                  </Row>
                ))}
              </Row>
            </OnboardingMessage>

            <Row
              className={`${styles.SingleButtonsContainer} ${
                messageIndex > 2 || isFinishOnboardingBlockShown
                  ? styles.AnimationShowContainer
                  : styles.HiddenContainer
              }`}
            >
              <ButtonsBlock
                acceptButtonText="Подписать согласие и добавить медкарты для детей"
                declineButtonText="Отказаться от просмотра медицинских сведений о детях"
                acceptOnClick={handleAcceptChildrenInfo}
                declineOnClick={handleDeclineChildrenInfo}
                isAcceptButtonDisabled={isChildrensConsentTouched && isChildrensConsentSigned}
                isDeclineButtonDisabled={isChildrensConsentTouched && !isChildrensConsentSigned}
              />
            </Row>
          </Row>
        </Row>
      )}

      {/* Onboarding finish block */}

      {isFinishOnboardingBlockShown && (
        <>
          <Row className={`${messageIndex > 1 ? styles.AnimationShowContainer : styles.HiddenContainer}`}>
            <Row className={styles.MessageContainer}>
              <OnboardingMessage withIcon>
                <Row className={styles.MessageRow}>
                  <Typography className={styles.MessageText}>
                    Принято😊 Если вы захотите добавить медкарты на других членов семьи - сделайте это в разделе
                    “Профили”.
                  </Typography>
                </Row>
              </OnboardingMessage>
            </Row>
          </Row>

          <Row className={`${messageIndex > 2 ? styles.AnimationShowContainer : styles.HiddenContainer}`}>
            <Row className={styles.MessageContainer}>
              <OnboardingMessage>
                <Row className={styles.MessageRow}>
                  <Typography className={styles.MessageText}>
                    На этом настройка вашего профиля завершена. 👏 Мне кажется, мы отлично справились!
                  </Typography>
                </Row>
              </OnboardingMessage>
              <Row
                className={`${styles.SingleButtonsContainer} ${
                  messageIndex > 3 ? styles.AnimationShowContainer : styles.HiddenContainer
                }`}
              >
                <ButtonsBlock acceptButtonText="Ок, давай начнём" acceptOnClick={handleFinishOnboarding} />
              </Row>
            </Row>
          </Row>
        </>
      )}
      <AgreementModal
        consentId={openedConsentId}
        isModalOpen={isAgreementModalOpen}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
