import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import styles from './styles.module.scss';

export const ConfirmationAccountText = (
  <Typography className={styles.ModalMessageInfo}>
    <Typography.Text className={styles.ModalMessageText}>
        Подтвержденными считаются учетные записи пользователей, авторизованных через Госуслуги, потому что 
        данные их профилей мы получаем из достоверных источников.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      Для подтвержденных через Госуслуги учетных записей может быть найдено больше медицинских документов, т.к.
      для поиска данных в государственных информационных системах требуется авторизация именно через Госуслуги.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      Если ранее вы прошли простую регистрацию, вы можете привязать к аккаунту свой профиль
      с Госуслуг. После этого вы сможете входить в Личный кабинет как через Госуслуги, так и под старым авторизационным профилем.
      Аккаунт будет единым, учетная запись - подтвержденной, доступ к информационным системам для поиска ваших
      документов - максимальными.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      P.S.: если у вас подтвержденная учетная запись, но система отображает маркер «Получение документов не настроено» -
      проверьте, подписано ли у вас согласие на доступ на просмотр медицинских сведений в разделе
      <Link className={styles.ModalMessageLinkText} to="/consent">
        {' '}
        «Согласия на доступ к данным»
      </Link>
      .
    </Typography.Text>
  </Typography>
);

export const AddClinicText = (
  <Typography className={styles.ModalMessageInfo}>
    <Typography.Text className={styles.ModalMessageText}>
      Чтобы я могла найти и показать ваши медицинские документы из клиники в Mila, клиника должна передавать данные в
      Единую государственную информационную систему в сфере здравоохранения Минздава РФ (ЕГИСЗ). Этого от медицинских
      организаций требует действующее законодательство*.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      Однако провести эту интеграцию не так просто: требуется обеспечить защиту каналов связи и хранилища данных. Чтобы
      помочь вашей клинике решить эту задачу Вы можете порекомендовать ей использовать Mila – сервис, разработанный на
      базе облачной платформы{' '}
      <Typography.Link className={styles.ModalMessageLinkText} href="https://n3health.ru/" target="_blank">
        N3.Health
      </Typography.Link>{' '}
      от компании ЭлНетМед - крупнейшего аккредитованного Минздравом РФ оператора по объему передачи данных в ЕГИСЗ от
      частных клиник в России**.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText} strong>
      Пригласите клинику в Mila, направив информацию о сервисе на e-mail, в мессенджеры, соцсети или просто расскажите
      врачу и администратору.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      В приглашении вы можете передать в клинику код MILAPLUS, и тогда подключение станет для нее дешевле на 50%.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageSmallText}>
      * Постановление Правительства РФ № 140 от 09.02.2022 г. «О единой государственной информационной системе в сфере
      здравоохранения» и Постановление Правительства РФ № 852 от 01.06.2021 г. «О лицензировании медицинской
      деятельности».
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageSmallText}>
      ** Идентификатор в Реестре информационных систем, подключенных к ЕГИСЗ Министерства здравоохранения РФ – 3, дата
      включения в Реестр - 11.10.2019, аттестованный оператор персональных данных, регистрационный номер в Реестре
      операторов Роскомнадзора 78-21-008437, класс защищенности К2.
    </Typography.Text>
  </Typography>
);

export const ChildrenMedcardsText = (
  <Typography className={styles.ModalMessageInfo}>
    <Typography.Text className={styles.ModalMessageText}>
      Я знаю, что часто вопросы здоровья в семье ложатся на одного из родителей. И еще знаю, как это неудобно - иметь
      разные аккаунты на каждого члена семьи, между которыми нужно переключаться и помнить все пароли :)
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      В Mila вы можете вести несколько профилей в одном аккаунте, видеть общий список записей на прием и легко
      переключаться с одной медкарты на другую.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      Добавить новый профиль можно в разделе{' '}
      <Link className={styles.ModalMessageLinkText} to="/settings/userProfile">
        «Настройки»
      </Link>{' '}
      двумя способами:
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      1.{' '}
      <Typography.Text className={styles.ModalMessageText} strong>
        Через Госуслуги:
      </Typography.Text>{' '}
      если вы хотите получать медицинские данные из клиник для нового профиля - на странице «Добавить профиль» нажмите
      «Добавить через Госуслуги» и войдите в его аккаунт на Госуслугах (т.е. если вы добавляете сына - нужно войти в
      Госуслуги под профилем сына. Да,{' '}
      <Typography.Link
        href="https://www.gosuslugi.ru/help/faq/login_child/102380"
        target="_blank"
        className={styles.ModalMessageLinkText}
      >
        на Госуслугах можно создать учетную запись ребенку до 13 лет
      </Typography.Link>
      ). Так мы будем уверены, что вы имеете право на доступ к медкарте этого пользователя. Данные с Госуслуг подтянутся
      в новый профиль автоматически и учетной записи сразу будет присвоен статус «подтвержденная».
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      2.{' '}
      <Typography.Text className={styles.ModalMessageText} strong>
        Вручную:{' '}
      </Typography.Text>
      на странице «Добавить профиль» вы также можете заполнить форму вручную. Статус добавленной таким образом учетной
      записи - «неподтвержденная». В этом случае у вас будет возможность записывать пользователя на прием и вести его
      медкарту вручную, но вы не сможете автоматически получать медицинские документы из клиник. Впрочем, созданные
      вручную профили можно позже подтвердить через Госуслуги. Обратите внимание, что при подтверждении добавленные
      ранее учетные данные профиля будут заменены данными с Госуслуг.
    </Typography.Text>
  </Typography>
);

export const ChildrenMedcardsTextWhiteLabel = (
  <Typography className={styles.ModalMessageInfo}>
    <Typography.Text className={styles.ModalMessageText}>
      Вы можете вести несколько профилей в одном аккаунте, видеть общий список записей на прием и легко переключаться с
      одной медкарты на другую.
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      Добавить новый профиль можно в разделе{' '}
      <Link className={styles.ModalMessageLinkText} to="/settings/userProfile">
        «Настройки»
      </Link>{' '}
      двумя способами:
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      1.{' '}
      <Typography.Text className={styles.ModalMessageText} strong>
        Через Госуслуги:
      </Typography.Text>{' '}
      если вы хотите получать медицинские данные из клиник для нового профиля - на странице «Добавить профиль» нажмите
      «Добавить через Госуслуги» и войдите в его аккаунт на Госуслугах (т.е. если вы добавляете сына - нужно войти в
      Госуслуги под профилем сына. Да,{' '}
      <Typography.Link
        href="https://www.gosuslugi.ru/help/faq/login_child/102380"
        target="_blank"
        className={styles.ModalMessageLinkText}
      >
        на Госуслугах можно создать учетную запись ребенку до 13 лет
      </Typography.Link>
      ). Так мы будем уверены, что вы имеете право на доступ к медкарте этого пользователя. Данные с Госуслуг подтянутся
      в новый профиль автоматически и учетной записи сразу будет присвоен статус «подтвержденная».
    </Typography.Text>
    <br />
    <Typography.Text className={styles.ModalMessageText}>
      2.{' '}
      <Typography.Text className={styles.ModalMessageText} strong>
        Вручную:{' '}
      </Typography.Text>
      на странице «Добавить профиль» вы также можете заполнить форму вручную. Статус добавленной таким образом учетной
      записи - «неподтвержденная». В этом случае у вас будет возможность записывать пользователя на прием и вести его
      медкарту вручную, но вы не сможете автоматически получать медицинские документы из клиник. Впрочем, созданные
      вручную профили можно позже подтвердить через Госуслуги. Обратите внимание, что при подтверждении добавленные
      ранее учетные данные профиля будут заменены данными с Госуслуг.
    </Typography.Text>
  </Typography>
);
