import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { ClinicCard } from 'src/features/Clinic/ClinicCard/ClinicCard';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { InvitationModal } from 'src/features/Catalog/InvitationModal/InvitationModal';
import { Plus } from 'src/common/icons';
import { ProfileTabs } from 'src/common/components/ProfileTabs/ProfileTabs';
import { Row, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useGetClinicsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

export const MyClinics = () => {
  const [params] = useSearchParams();
  const [selectedProfile, setSelectedPerson] = useState<string>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: profiles } = useGetProfilesQuery();

  const allProfiles = useMemo(() => profiles?.map((profile) => profile.idProfile) ?? [], [profiles]);

  useEffect(() => {
    const profileId = params.get('profileId');
    if (profileId) {
      setSelectedPerson(profileId);
    }
  }, [params]);

  const { data: myClinics, isLoading } = useGetClinicsQuery(
    {
      profileIds: selectedProfile === 'all' ? allProfiles : [selectedProfile!],
    },
    { skip: !(profiles?.length && profiles.length > 0 && selectedProfile) },
  );

  const handlePersonTabClick = (key: string) => {
    setSelectedPerson(key);
  };

  const handleOpenInvitationModal = () => setIsModalOpen(true);
  const handleCloseInvitationModal = () => setIsModalOpen(false);

  return (
    <>
      <BackHeader title="Мои клиники" />
      <Row>
        <ProfileTabs activeProfile={selectedProfile} onChange={handlePersonTabClick} />
      </Row>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        myClinics?.map((clinic) => <ClinicCard clinicInfo={clinic} key={clinic.idMedOrganization} />)
      )}
      <CustomButton className={styles.InvitationButton} onClick={handleOpenInvitationModal} isPrimal>
        <Row className={styles.InvitationButtonRow}>
          <Plus />
          <Typography>Пригласить клинику</Typography>
        </Row>
      </CustomButton>
      <CustomModal isOpen={isModalOpen} onCancelClick={handleCloseInvitationModal}>
        <InvitationModal />
      </CustomModal>
    </>
  );
};
