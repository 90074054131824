import { Col, Image, Row } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import GosUslugiPict from 'src/assets/GosUslugi.png';
import Typography from 'antd/es/typography/Typography';
import styles from './styles.module.scss';

interface GosUslugiDesktopBlockProps {
  buttonHandler?: () => void;
}

export const GosUslugiDesktopBlock = ({ buttonHandler }: GosUslugiDesktopBlockProps) => (
  <WhiteRow className={styles.BlockWrapper}>
    <Col>
      <Image preview={false} src={GosUslugiPict} className={styles.GosImage} />
    </Col>
    <Col>
      <Row className={styles.BlockTextContainer}>
        <Typography className={styles.BlockTitle}>Подтвердите учетную запись</Typography>
        <Typography className={styles.BlockText}>
          Чтобы автоматически получать данные из клиник для этого профиля войдите в его аккаунт на Госуслугах. Так мы
          будем уверены, что вы имеете право на доступ к медкарте этого пользователя
        </Typography>
      </Row>
    </Col>
    <Col>
      <CustomButton className={styles.GosButton} onClick={buttonHandler}>
        <Typography>Подтвердить через Госуслуги</Typography>
      </CustomButton>
    </Col>
  </WhiteRow>
);
