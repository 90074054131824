import { CustomTabs } from '../CustomTabs/CustomTabs';
import { getProfileNameLabel, sortProfilesByItself } from '../../utils';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import styles from './styles.module.scss';

interface ProfileTabsProps {
  activeProfile?: string;
  onChange: (key: string) => void;
  withoutAllTab?: boolean;
}

export const ProfileTabs = ({ activeProfile, onChange, withoutAllTab }: ProfileTabsProps) => {
  const { data: profiles } = useGetProfilesQuery();
  const sortedProfiles = useMemo(() => sortProfilesByItself(profiles!), [profiles]);

  const tabsItmes = useMemo(() => {
    let items = withoutAllTab ? [] : [{ key: 'all', label: 'Все' }];
    if (profiles?.length && profiles.length > 0) {
      items = items.concat(
        sortedProfiles?.map((profile) => ({
          label: getProfileNameLabel(profile),
          key: profile.idProfile,
        })),
      );
    }
    return items;
  }, [profiles, sortedProfiles, withoutAllTab]);

  return (
    <CustomTabs
      items={tabsItmes}
      onChange={onChange}
      activeKey={activeProfile}
      containerClassName={styles.TabsContainer}
    />
  );
};
