import { notification } from 'antd';
import { urlB64ToUint8Array } from './ServiceWorker/urlB64ToUint8Array';
import { useGetPublicKeyQuery, useSendSubscriptionMutation } from 'src/common/redux/api/apiPushNotificationSlice';
import { Link, useLocation } from 'react-router-dom';
import { useServiceWorker } from './ServiceWorker/useServiceWorker';
import React, { useEffect, useState } from 'react';
import Typography from 'antd/es/typography/Typography';
import styles from './styles.module.scss';
import { useGetProfileAuthTypeQuery } from '../../common/redux/api/apiPatientSlice';
import { AuthType } from '../../common/types/enums';

export const PushNotification: React.FC = () => {
  const location = useLocation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showError, setShowError] = useState<boolean>(false);

  const { data: authType } = useGetProfileAuthTypeQuery();

  const [sendSubscription, { isError: isSendSubscriptionError, error: sendSubscriptionError }] =
    useSendSubscriptionMutation();
  const {registerServiceWorker} = useServiceWorker();
  const {
    data,
    isError: isPublicKeyError,
    error: publicKeyError,
  } = useGetPublicKeyQuery(undefined, { skip: location.pathname.includes('shared/medcard') });

  const getPermission = (): void => {
    if ('Notification' in window === false || Notification === undefined) {
      return;
    }

    Notification.requestPermission(async (status: NotificationPermission) => {
      console.log('Статус разрешения на уведомления:', status);
      if (status === 'granted') {
        try {
          await registerServiceWorker();
          await getSubscription(data.key);
        } catch (error) {
          console.error('Ошибка при запросе разрешения на уведомления:', error);
        }
      }
    });
  };

  const getSubscription = async (publicKey?: string): Promise<void> => {
    try {
      if (!publicKey) {
        console.error('publicKey is undefined');
        return;
      }
      const registration = await navigator.serviceWorker.ready;

      if (!registration) {
        throw new Error('Регистрация службы уведомлений не найдена');
      }

      registration.pushManager.getSubscription().then(async (currentSubscriptions) => {
        if (!currentSubscriptions) {
          await subscribe(publicKey, registration);
          await sendToServer();
        } else {
          (window as any).mySubscription = currentSubscriptions;
          console.log('Подписка уже существует:', currentSubscriptions);
        }
      });
    } catch (error) {
      console.error('Ошибка получения подписки:', error);
    }
  };

  const subscribe = async (publicKey: string, registration: ServiceWorkerRegistration) => {
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(publicKey),
    });

    console.log('Создана подписка:', subscription);
    (window as any).mySubscription = subscription;
  };

  const sendToServer = async (): Promise<void> => {
    if (!(window as any).mySubscription) {
      console.log('Подписки еще нет');
      return;
    }
    const subscriptionData = JSON.stringify((window as any).mySubscription.toJSON(), null, 2);

    // Вызов мутации для отправки подписки
    try {
      await sendSubscription(subscriptionData);
      console.log('Подписка успешно отправлена на сервер');
    } catch (error) {
      console.error('Ошибка при отправке на сервер:', error);
    }
  };

  useEffect(() => {
    data && getPermission();
  }, [data]);

  useEffect(() => {
    setShowError(isPublicKeyError || isSendSubscriptionError);
  }, [isPublicKeyError, isSendSubscriptionError]);

  useEffect(() => {
    if (showError && authType === AuthType.Email) {
      notificationApi.error({
        message: (
          <Typography>
            Пожалуйста,{' '}
            <Link
              to="/consent"
              onClick={() => {
                setShowError(false);
              }}
              className={styles.ErrorLink}
            >
              подпишите согласия
            </Link>{' '}
            для работы с сервисом
          </Typography>
        ),
      });
    }
  }, [showError, authType]);

  return <>{contextHolder}</>;
};
