import { CSSProperties } from 'react';
import { Image, Row } from 'antd';
import { ReplaceAvatar } from '../ReplaceAvatar/ReplaceAvatar';
import { getInitials } from 'src/common/utils';
import DoctorStubSmall from 'src/assets/DoctorStubSmall.png';
import styles from './styles.module.scss';

interface AvatarProps {
  idPhoto: string;
  idProfile: string;
  name: string;
  className?: string;
  classNameText?: string;
  onClick?: () => void;
  style?: CSSProperties;
  isDoctor?: boolean;
}

export const Avatar = ({
  idPhoto,
  idProfile,
  className,
  classNameText,
  name,
  onClick,
  style,
  isDoctor = false,
}: AvatarProps) =>
  idPhoto ? (
    <Row style={{ ...style }}>
      <Image
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${idPhoto}`}
        className={`${styles.Avatar} ${className}`}
        preview={false}
        onClick={onClick}
      />
    </Row>
  ) : isDoctor ? (
    <Row style={{ ...style }}>
      <Image src={DoctorStubSmall} className={`${styles.Avatar} ${className}`} preview={false} onClick={onClick} />
    </Row>
  ) : (
    <ReplaceAvatar
      id={idProfile}
      classNameAvatar={className}
      initials={getInitials(name)}
      classNameText={classNameText}
      onClick={onClick}
      style={style}
    />
  );
