import { Briefcase } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import styles from './styles.module.scss';

interface ServiceBlockProps {
  name?: string;
}

export const ServiceBlock = ({ name = '' }: ServiceBlockProps) => {
  return (
    <Row className={styles.ServiceRow}>
      <Briefcase className={styles.Icon} />
      <Col>
        <Typography>Услуги</Typography>
        <Typography className={styles.ServiceTag}>{name}</Typography>
      </Col>
    </Row>
  );
};
