import { Avatar } from 'src/common/components/Avatar/Avatar';
import { CaretRight } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Profile } from 'src/common/types';
import { getProfileNameLabel } from '../../../common/utils';
import styles from './styles.module.scss';

interface ProfileRowProps {
  profile: Profile;
}

export const ProfileRow = ({ profile }: ProfileRowProps) => (
  <Link className={styles.Link} to={`/settings/userProfile/${profile.idProfile}`}>
    <Row className={styles.OptionRow}>
      <Col className={styles.NameCol}>
        <Avatar
          className={styles.ProfileAvatar}
          idPhoto={profile.idPhoto}
          idProfile={profile.idProfile}
          name={`${profile.surname} ${profile.name}`}
        />
        <Typography className={styles.Name}>{getProfileNameLabel(profile)}</Typography>
      </Col>
      <CaretRight />
    </Row>
  </Link>
);
