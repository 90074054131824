import { Col, Divider, Row, Skeleton, Typography } from 'antd';
import { ExclamationMarkStatBlock, StatusUpBlockIcon } from 'src/common/icons';
import { Link, useNavigate } from 'react-router-dom';
import { WhiteRow } from '../../../common/components/WhiteRow/WhiteRow';
import {
  useGetProfileRecommedationsQuery,
  useGetProfileStatQuery,
  useGetProfilesQuery,
} from '../../../common/redux/api/apiPatientSlice';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { StatProfile } from 'src/common/types';
import { streamStatProfileApi } from 'src/common/redux/api/apiStreamSlice';

interface InformationBlockProps {
  hasUpcomingRecords: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const InformationBlock = ({ hasUpcomingRecords, onClick }: InformationBlockProps) => (
  <Row className={styles.ImportantInfoBlock}>
    <Col>{hasUpcomingRecords ? <ExclamationMarkStatBlock /> : <StatusUpBlockIcon />}</Col>
    <Col onClick={onClick}>
      <Typography.Text className={styles.ImportantInfoText}>
        {hasUpcomingRecords
          ? 'Важно! В вашей медкарте есть назначения врачей за последний месяц. Не забудьте выполнить их вовремя'
          : 'Все по плану, новых рекомендаций врачей не найдено. Как только получу свежие назначения - сразу расскажу вам'}
      </Typography.Text>
    </Col>
  </Row>
);

export const StatisticsBlock = () => {
  const [statistics, setStatistics] = useState<StatProfile>();
  const [newStatistics, setNewStatistics] = useState<StatProfile>();
  const [statisticsLoading, setStatisticsLoading] = useState(true);

  const { data: crId, isLoading } = useGetProfileStatQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  });

  const [processMessage] = streamStatProfileApi((item) => {
    // join data for the same CrId!!
    setNewStatistics(item);
    setStatisticsLoading(false);
  });

  useEffect(() => {
    if (newStatistics) {
      if (statistics) {
        setStatistics({
          analysesAndExaminationsCount: statistics.analysesAndExaminationsCount + newStatistics.analysesAndExaminationsCount,
          appointmentsCount: statistics.appointmentsCount + newStatistics.appointmentsCount,
          diagnosesCount: statistics.diagnosesCount + newStatistics.diagnosesCount,
          documentsTotalCount: statistics.documentsTotalCount + newStatistics.documentsTotalCount,
          hospitalizationsCount: statistics.hospitalizationsCount + newStatistics.hospitalizationsCount,
          firstDocumentTimestamp: statistics.firstDocumentTimestamp === null ? newStatistics.firstDocumentTimestamp :
              newStatistics.firstDocumentTimestamp === null || statistics.firstDocumentTimestamp < newStatistics.firstDocumentTimestamp ? 
              statistics.firstDocumentTimestamp : newStatistics.firstDocumentTimestamp
        });
      } else {
        setStatistics(newStatistics);
      }
    }
  }, [newStatistics]);

  useEffect(() => {
    setStatistics(undefined);
    processMessage(crId);
  }, [crId]);

  //statistics

  const { data: profiles } = useGetProfilesQuery();
  const navigate = useNavigate();

  const profileId = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile || '', [profiles]);

  const { data: recommedations } = useGetProfileRecommedationsQuery(profileId, {
    skip: !profileId,
  });

  const hasUpcomingRecords = useMemo(
    () => !!recommedations?.find(({ created }) => dayjs().diff(created, 'day') < 30),
    [recommedations],
  );

  const handleDocumentsCountClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/medcards/${profileId}?activeTab=documents`);
  };

  const handleRecommendationsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/medcards/${profileId}?activeTab=calendar`);
  };

  return (
    <Link to={`/medcards/${profileId}`} className={styles.StatisticsWrapper}>
      <WhiteRow className={styles.BlockRow}>
        <Col className={styles.DocumentsBlock} onClick={handleDocumentsCountClick}>
          <Row className={styles.DocumentsBlockWrapper}>
            <Typography.Text className={styles.DocumentsText}>Всего найдено документов</Typography.Text>

            {statisticsLoading ? (
              <Skeleton.Button active shape="square" className={styles.DocumentsCountText} />
            ) : (
              <Typography.Text className={styles.DocumentsCountText}>
                {statistics?.documentsTotalCount || 0}
              </Typography.Text>
            )}
            {statistics?.firstDocumentTimestamp && (
              <Typography.Text className={styles.DocumentsLastDateText}>
                {`C ${dayjs(statistics?.firstDocumentTimestamp).format('DD.MM.YYYY')}`}
              </Typography.Text>
            )}
          </Row>
        </Col>

        <Col className={styles.StatisticsBlock}>
          <Row>
            <Typography.Text className={styles.StatisticsBlockTitle}>Ваша статистика</Typography.Text>
          </Row>
          <Row className={styles.StatisticsBlocksRow}>
            <Col className={styles.StatisticsColumn}>
              <Typography.Text className={styles.StatisticsText}>Обращений в клиники</Typography.Text>
              {statisticsLoading ? (
                <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.StatisticsCountText}>
                  {statistics?.appointmentsCount || 0}
                </Typography.Text>
              )}
            </Col>
            <Divider type="vertical" className={styles.VerticalDivider} />
            <Col className={styles.StatisticsColumn}>
              <Typography.Text className={styles.StatisticsText}>Исследований и анализов</Typography.Text>
              {statisticsLoading ? (
                <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.StatisticsCountText}>
                  {statistics?.analysesAndExaminationsCount || 0}
                </Typography.Text>
              )}
            </Col>
            <Divider type="vertical" className={styles.VerticalDivider} />
            <Col className={styles.StatisticsColumn}>
              <Typography.Text className={styles.StatisticsText}>Диагнозов установлено</Typography.Text>
              {statisticsLoading ? (
                <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.StatisticsCountText}>
                  {statistics?.diagnosesCount || 0}
                </Typography.Text>
              )}
            </Col>
            <Divider type="vertical" className={styles.VerticalDivider} />
            <Col className={styles.StatisticsColumn}>
              <Typography.Text className={styles.StatisticsText}>Случаев госпитализации</Typography.Text>
              {statisticsLoading ? (
                <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.StatisticsCountText}>
                  {statistics?.hospitalizationsCount || 0}
                </Typography.Text>
              )}
            </Col>
          </Row>

          <InformationBlock hasUpcomingRecords={hasUpcomingRecords} onClick={handleRecommendationsClick} />
        </Col>
      </WhiteRow>
      <WhiteRow className={styles.BlockRowMobile}>
        <>
          <Row className={styles.DocumentsBlockMobile} onClick={handleDocumentsCountClick}>
            <Row className={styles.DocumentsBlockWrapper}>
              {statisticsLoading ? (
                <Skeleton active className={styles.DocumentsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.DocumentsCountText}>
                  {statistics?.documentsTotalCount || 0}
                </Typography.Text>
              )}
              <Typography.Text className={styles.DocumentsText}>Документов найдено</Typography.Text>
              {statisticsLoading ? (
                <Skeleton active className={styles.DocumentsCountText} paragraph={false} />
              ) : (
                <Typography.Text className={styles.DocumentsLastDateText}>
                  {`C ${dayjs(statistics?.firstDocumentTimestamp).format('DD.MM.YYYY')}`}
                </Typography.Text>
              )}
            </Row>
          </Row>
          <Row className={styles.StatisticsColumnsWrapper}>
            <Row className={styles.StatisticsRow}>
              <Col className={styles.StatisticsMobileColumn}>
                <Typography.Text className={styles.StatisticsText}>Обращений в клиники</Typography.Text>
                {statisticsLoading ? (
                  <Skeleton active className={styles.DocumentsCountText} paragraph={false} />
                ) : (
                  <Typography.Text className={styles.StatisticsCountText}>
                    {statistics?.appointmentsCount || 0}
                  </Typography.Text>
                )}
              </Col>
              <Divider type="vertical" className={styles.VerticalDivider} />
              <Col className={styles.StatisticsMobileColumn}>
                <Typography.Text className={styles.StatisticsText}>Исследований и анализов</Typography.Text>
                {statisticsLoading ? (
                  <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
                ) : (
                  <Typography.Text className={styles.StatisticsCountText}>
                    {statistics?.analysesAndExaminationsCount || 0}
                  </Typography.Text>
                )}
              </Col>
            </Row>
            <Divider className={styles.HorizontalDivider} />
            <Row className={styles.StatisticsRow}>
              <Col className={styles.StatisticsMobileColumn}>
                <Typography.Text className={styles.StatisticsText}>Диагнозов установлено</Typography.Text>
                {statisticsLoading ? (
                  <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
                ) : (
                  <Typography.Text className={styles.StatisticsCountText}>
                    {statistics?.diagnosesCount || 0}
                  </Typography.Text>
                )}
              </Col>
              <Divider type="vertical" className={styles.VerticalDivider} />
              <Col className={styles.StatisticsMobileColumn}>
                <Typography.Text className={styles.StatisticsText}>Случаев госпитализации</Typography.Text>
                {statisticsLoading ? (
                  <Skeleton active className={styles.StatisticsCountText} paragraph={false} />
                ) : (
                  <Typography.Text className={styles.StatisticsCountText}>
                    {statistics?.hospitalizationsCount || 0}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          </Row>

          <InformationBlock hasUpcomingRecords={hasUpcomingRecords} onClick={handleRecommendationsClick} />
        </>
      </WhiteRow>
    </Link>
  );
};
