import { ExclamationMarkTagIcon, GreenCheckIcon } from '../../icons';
import { Tag } from 'antd';
import { useMemo } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface PaymentTagProps {
  price?: string | number;
  status: string;
}

export const PaymentTag = ({ price = '', status }: PaymentTagProps) => {
  const isPaid = useMemo(() => status === 'Оплачено', [status]);
  const isError = useMemo(() => status === 'Ошибка', [status]);
  const tagIcon = useMemo(() => {
    if (isPaid) {
      return <GreenCheckIcon className={styles.TagIcon} />;
    } else if (isError) {
      return <ExclamationMarkTagIcon className={styles.TagIcon} />;
    } else {
      return null;
    }
  }, [isPaid, isError]);

  return (
    <Tag
      className={`${styles.TagWrapper} ${isPaid ? styles.TagGreenBorder : styles.TagRedBorder} ${
        isError && styles.RedText
      }`}
      icon={tagIcon}
    >{`${status}, ${price} Р`}</Tag>
  );
};
