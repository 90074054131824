import { Input, InputRef } from 'antd';
import { RemoveCross, Search } from 'src/common/icons';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import React, { ChangeEvent, memo, useEffect, useRef } from 'react';
import styles from './styles.module.scss';

interface SearchProps {
  handleSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  value?: string;
  floatLabel: string;
  inputName?: string;
  disabled?: boolean;
  defaultValue?: string;
  focus?: boolean;
  allowClear?: boolean;
}

export const CustomSearch = memo(
  ({
    handleSearch,
    placeholder,
    className,
    value,
    floatLabel,
    disabled,
    inputName,
    defaultValue,
    focus,
    allowClear = false,
  }: SearchProps) => {
    const searchRef = useRef<InputRef>(null);

    useEffect(() => {
      focus && searchRef.current?.focus();
    }, [focus]);

    return (
      <FloatLabelInput
        label={floatLabel}
        value={value?.toString()}
        className={`${styles.FormItemWrapper} ${className}`}
      >
        <Input
          suffix={<Search />}
          placeholder={placeholder}
          onChange={handleSearch}
          defaultValue={defaultValue}
          disabled={disabled}
          name={inputName}
          value={value}
          ref={searchRef}
          allowClear={allowClear && { clearIcon: <RemoveCross /> }}
        />
      </FloatLabelInput>
    );
  },
);
