import { CaretRight, Location } from 'src/common/icons';
import { Link } from 'react-router-dom';
import { Row, Typography } from 'antd';
import styles from './styles.module.scss';

interface ClinicLabelProps {
  name?: string;
  id?: string;
}

export const ClinicLabel = ({ name, id }: ClinicLabelProps) => (
  <Link to={id ? `/catalog/${id}` : ''} className={!id ? styles.DisabledLink : ''}>
    <Row className={styles.Row}>
      <Location className={styles.Icon} />
      <Typography>{name}</Typography>
      {id && <CaretRight />}
    </Row>
  </Link>
);
