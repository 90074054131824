import { Checkbox, Form, Image, Input, Row, Typography, notification } from 'antd';
import { CustomButton } from '../../../common/components/CustomButton/CustomButton';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { WhiteRow } from '../../../common/components/WhiteRow/WhiteRow';
import {
  useDeleteProfileMutation,
  useGetProfileRemoveReasonsQuery,
} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery,
} from 'src/common/redux/api/apiWhiteLabelSlice';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';
import KeyCloakService from 'src/keycloak';
import React, { useMemo, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import SadPict from '../../../assets/Sad.png';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface RemoveProfileFormProps {
  userId?: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  itSelf?: boolean;
}

export const RemoveProfileForm = ({
  isModalOpen,
  handleCloseModal,
  userId = '',
  itSelf = false,
}: RemoveProfileFormProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const { data: removeProfileReasons, isFetching } = useGetProfileRemoveReasonsQuery();
  const [deleteProfile] = useDeleteProfileMutation();
  const navigate = useNavigate();
  const [form] = useForm();
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const title = isWhiteLabelEnabled ? 'Сожалеем, что вы уходите' : 'Мне жаль расставаться ((';
  const text = isWhiteLabelEnabled
    ? 'Пожалуйста, укажите причины, по которым вы удаляете профиль - мы будем знать, что нужно исправить'
    : 'Пожалуйста, укажите причины, по которым вы удаляете профиль - буду знать, что нужно исправить';

  const handleValuesChanges = () =>
    Object.entries(form.getFieldsValue()).filter((value) => value[1]).length
      ? setSubmitDisabled(false)
      : setSubmitDisabled(true);

  const handleSubmitForm = () => {
    const fields = Object.entries(form.getFieldsValue());
    const deleteReasonCodes = fields
      .slice(0, fields.length - 1)
      .filter((value) => value[1])
      .map((value) => value[0]);
    const otherReason = fields[fields.length - 1][1] ? String(fields[fields.length - 1][1]) : null;

    deleteProfile({ data: { deleteReasonCodes, otherReason }, userId })
      .then(() => {
        if (itSelf) {
          KeyCloakService(keycloakObj).logOut();
        } else {
          navigate(`/settings`);
        }

        notification.success({ message: 'Профиль успешно удален' });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка удаления профиля' });
      });
  };

  return (
    <CustomModal isOpen={isModalOpen} className={styles.Modal} onCancelClick={handleCloseModal}>
      <Form form={form} onFinish={handleSubmitForm} onValuesChange={handleValuesChanges}>
        <Row className={`${styles.ModalWrapper} ${styles.BottomMargin}`}>
          <Row>
            <Typography.Title level={3} className={styles.ModalContentTitle}>
              Удаление профиля
            </Typography.Title>
          </Row>
          <Row>
            <Image src={SadPict} preview={false} className={styles.Pict} alt="" />
          </Row>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage title={title} text={text} />
          </Row>
          <WhiteRow className={styles.WhiteCol}>
            <Typography.Title level={4} className={styles.ModalContentSubTitle}>
              Удалить профиль, потому что
            </Typography.Title>
            {isFetching ? (
              <CustomSpinner />
            ) : (
              removeProfileReasons?.map(({ code, text }) => (
                <Form.Item key={code} name={code} valuePropName="checked">
                  <Checkbox>{text}</Checkbox>
                </Form.Item>
              ))
            )}
          </WhiteRow>
          <WhiteRow className={styles.WhiteCol}>
            <Typography.Title level={4} className={styles.ModalContentSubTitle}>
              Другая причина или комментарий
            </Typography.Title>
            <Row>
              <Form.Item name="customReason">
                <Input.TextArea
                  maxLength={90}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  placeholder="Максимум 90 символов"
                  className={styles.CustomReason}
                />
              </Form.Item>
            </Row>
            <Row>
              <CustomButton
                disabled={isSubmitDisabled}
                className={styles.RemoveButton}
                onClick={handleCloseModal}
                isPrimal
                htmlType="submit"
              >
                <Typography.Text>Удалить профиль</Typography.Text>
              </CustomButton>
            </Row>
          </WhiteRow>
        </Row>
      </Form>
    </CustomModal>
  );
};
