import { Button, Row, Typography } from 'antd';
import { DocumentsItem, RemoveDocumentIcon } from 'src/common/icons';

import { CalendarRecord, MedcardDocument } from 'src/common/types';
import { CustomModal } from '../CustomModal/CustomModal';
import { MedcardDocumentLoadForm } from 'src/features/MedCard/MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { useState } from 'react';
import styles from './styles.module.scss';
export interface DocumentsListProps {
  docsList: MedcardDocument[];
  appointment?: Partial<CalendarRecord>;
  className?: string;
  removeDocument: (docId: string) => void;
  refetch: () => void;
}

export const DocumentsList = ({ docsList, appointment, className, removeDocument, refetch }: DocumentsListProps) => {
  const [document, setDocumentToShow] = useState<MedcardDocument>();
  const [isDocumentsFormModalVisible, setDocumentsFormModalVisibility] = useState(false);

  const handleAttachNewDocs = (doc: MedcardDocument) => {
    setDocumentToShow(doc);

    setDocumentsFormModalVisibility(true);
  };

  const closeModal = () => {
    setDocumentsFormModalVisibility(false);
  };

  const finishLoading = () => {
    setDocumentsFormModalVisibility(false);
    refetch();
  };

  return (
    <Row className={styles.DocumentsList} gutter={[0, 22]}>
      {docsList?.map((doc) => (
        <Row className={styles.DocumentItemWrapper} key={doc.idMilaUserDocument ?? doc.idExternalDocument}>
          <DocumentsItem />
          <Typography className={`${styles.DocumentNameLabel} ${className}`} onClick={() => handleAttachNewDocs(doc)}>
            {doc.documentName}
          </Typography>
          <Button
            icon={<RemoveDocumentIcon />}
            onClick={() => removeDocument(doc.idMilaUserDocument ?? doc.idExternalDocument)}
          />
        </Row>
      ))}
      <CustomModal  isOpen={isDocumentsFormModalVisible} onCancelClick={closeModal}>
        <MedcardDocumentLoadForm
          onFinish={finishLoading}
          isAppointmentForm
          appointment={appointment}
          document={document}
        />
      </CustomModal>
    </Row>
  );
};
