import { CustomButton } from '../CustomButton/CustomButton';
import { CustomModal } from '../CustomModal/CustomModal';
import { Image, Row, Typography } from 'antd';
import { InvitationModal } from 'src/features/Catalog/InvitationModal/InvitationModal';
import { Plus } from 'src/common/icons';
import { useState } from 'react';
import MedCase from 'src/assets/MedCase.png';
import styles from './styles.module.scss';
import {MilaService} from '../../types';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';

export const NoDataStub = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Row className={styles.StubContainer}>
      <Image className={styles.Image} src={MedCase} preview={false} />
      <Typography className={styles.BoldText}>В вашем регионе пока нет врачей</Typography>
      <Typography className={styles.Text}>
        Попробуйте изменить регион или включите поиск по всем регионам. А еще вы можете пригласить свою клинику и врачей
        в Милу
      </Typography>
      {(!whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.AddClinic)) && <CustomButton isPrimal className={styles.Button} onClick={handleOpenModal}>
        <Row className={styles.ButtonRow}>
          <Plus className={styles.Plus} />
          <Typography>Пригласить клинику</Typography>
        </Row>
      </CustomButton>}
      <CustomModal isOpen={isModalOpen} className={styles.AddClinicModal} onCancelClick={handleCloseModal}>
        <InvitationModal />
      </CustomModal>
    </Row>
  );
};
