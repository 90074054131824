import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { DoctorCard } from 'src/features/DoctorChoice/DoctorCard/DoctorCard';
import { ProfileTabs } from 'src/common/components/ProfileTabs/ProfileTabs';
import { Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useGetDoctorsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';

export const MyDoctors = () => {
  const [params] = useSearchParams();
  const [selectedProfile, setSelectedPerson] = useState<string>('all');

  const { data: profiles } = useGetProfilesQuery();

  const allProfiles = useMemo(() => profiles?.map((profile) => profile.idProfile) ?? [], [profiles]);

  useEffect(() => {
    const profileId = params.get('profileId');
    if (profileId) {
      setSelectedPerson(profileId);
    }
  }, [params]);

  const { data: myDoctors, isLoading } = useGetDoctorsQuery(
    {
      patientIds: selectedProfile === 'all' ? allProfiles : [selectedProfile!],
    },
    { skip: !(profiles?.length && profiles.length > 0 && selectedProfile) },
  );

  const handlePersonTabClick = (key: string) => {
    setSelectedPerson(key);
  };

  return (
    <>
      <BackHeader title="Мои врачи" />
      <Row>
        <ProfileTabs activeProfile={selectedProfile} onChange={handlePersonTabClick} />
      </Row>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        myDoctors?.map((doctor) => <DoctorCard doctor={doctor} withSchedule key={doctor.idMilaDoctor} />)
      )}
    </>
  );
};
