import { AutoComplete, Input } from 'antd';
import { AutocompleteOption } from 'src/common/types';
import { Search } from 'src/common/icons';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import React, { ChangeEvent } from 'react';
import styles from './styles.module.scss';

interface AutocompleteSearchProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  value?: string;
  floatLabel: string;
  inputName?: string;
  disabled?: boolean;
  options: { value: string; label: string; key: string }[];
  handleChangeValue: (id: string, option: AutocompleteOption) => void;
  filterOption?: boolean | ((inputValue: string, option?: AutocompleteOption) => boolean);
  allowClear?: boolean;
}

export const CustomAutocompleteSearch = ({
  onChange,
  placeholder,
  className = '',
  value,
  floatLabel,
  disabled,
  options,
  handleChangeValue,
  filterOption,
  allowClear = false,
}: AutocompleteSearchProps) => (
  <FloatLabelInput label={floatLabel} value={value?.toString()} className={`${styles.FormItemWrapper} ${className}`}>
    <AutoComplete
      options={options}
      onSelect={(value, option) => handleChangeValue(value, { ...option, key: option.key })}
      value={value}
      filterOption={
        filterOption
          ? filterOption
          : (value, option) => option?.value?.toLowerCase().indexOf(value?.toLowerCase() || '') !== -1
      }
      allowClear={allowClear}
      onClear={() => handleChangeValue('', { value: '', label: '', key: '' })}
      disabled={disabled}
    >
      <Input suffix={<Search />} placeholder={placeholder} onChange={onChange} disabled={disabled} />
    </AutoComplete>
  </FloatLabelInput>
);
