import { RoutingIcon } from 'src/common/icons';
import { Typography } from 'antd';
import styles from './styles.module.scss';

interface RoutingButtonProps {
  latitude?: string;
  longitude?: string;
}

export const RoutingButton = ({ latitude, longitude }: RoutingButtonProps) => {
  return (
    <Typography.Link
      className={styles.RoutingButton}
      href={`https://yandex.ru/maps/?rtext=~${latitude}%2C${longitude}`}
      target="_blank"
    >
      <RoutingIcon />
    </Typography.Link>
  );
};
