import { Row } from 'antd';
import { SliderArrows } from '../SliderArrows/SliderArrows';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface CustomSliderProps {
  children: ReactElement[];
  sliderSettings?: Settings;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  titleClass?: string;
  title?: string;
  onNextHandle?: (id: string) => void;
  defaultSlideNumber?: number;
}

export const CustomSlider = ({ children, sliderSettings, onNextHandle, defaultSlideNumber }: CustomSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (defaultSlideNumber !== null && defaultSlideNumber !== undefined && sliderRef.current) {
      setCurrentSlide(defaultSlideNumber);
      sliderRef.current.slickGoTo(defaultSlideNumber);
    }
  }, [defaultSlideNumber]);

  const settings = useMemo(
    () => ({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            infinite: false,
            variableWidth: true,
          },
        },
      ],
      beforeChange: (current: number, next: number) => {
        onNextHandle && onNextHandle(children[next]?.key as string);
      },
      ...sliderSettings,
    }),
    [children, onNextHandle, sliderSettings],
  );

  const gotoNext = () => {
    sliderRef.current?.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <Row className={styles.Slider}>
      <Slider ref={sliderRef} {...settings} afterChange={(index: number) => setCurrentSlide(index)}>
        {children}
      </Slider>
      <SliderArrows
        gotoNext={gotoNext}
        gotoPrev={gotoPrev}
        leftArrowDisable={currentSlide === 0}
        rightArrowDisable={currentSlide === children.length - settings.slidesToShow}
      />
    </Row>
  );
};
