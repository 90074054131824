import { Typography } from 'antd';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export const MedicalAgreement = (
  <>
    <Typography className={styles.AgreementTitleText}>
      Согласие пользователя на просмотр медицинских сведений
    </Typography>
    <Typography.Text>
      В соответствии со статьями 23, 24 Конституции Российской Федерации, статьей 22 Федерального закона от 21. 11.2011
      N 323-ФЗ "Об основах охраны здоровья граждан в Российской Федерации", Федеральным законом от 27.07.2006 № 152-ФЗ
      &laquo;О персональных данных&raquo;, Постановлением Правительства РФ от 12 апреля 2018 г. N 447 об утверждении
      <Typography.Text strong> &laquo;</Typography.Text>Правил взаимодействия иных информационных систем,
      предназначенных для сбора, хранения, обработки и предоставления информации, касающейся деятельности медицинских
      организаций и предоставляемых ими услуг, с информационными системами в сфере здравоохранения и медицинскими
      организациями&raquo;,<Typography.Text strong> </Typography.Text>
      Политикой конфиденциальности, размещенной на сайте Mila (Мила) по адресу www.mila.online,
    </Typography.Text>
    <Typography.Text>
      Я, субъект персональных данных, дееспособное физическое лицо, достигшее 18-летнего возраста, успешно прошедшее
      процедуру регистрации на сайте Mila (Мила) путем заполнения формы регистрации или авторизации посредством Госуслуг
      (через Единую Систему Идентификации и Аутентификации, ЕСИА), или авторизации посредством Сбер ID (сервис
      авторизации от ПАО &laquo;Сбербанк&raquo;) в целях использования сайта Mila (Мила) и его сервисов, в том числе
      виджета записи на прием в медицинскую организацию, отображаемого на сайте медицинской организации (далее - Сайт,
      Сервисы), именуемый далее <Typography.Text strong>Пользователь</Typography.Text>, действуя своей волей и в своем
      интересе, <Typography.Text strong>даю согласие на просмотр мной </Typography.Text>
      <Typography.Text strong>
        имеющейся в медицинских организациях информации о состоянии моего здоровья, а также здоровья лиц, законным
        представителям которых я являюсь
      </Typography.Text>
      , в том числе сведений о результатах медицинского обследования, наличии заболеваний, об установленных диагнозах и
      о прогнозе развития заболевания, методах оказания медицинской помощи, связанном с ними риске, возможных видах
      медицинского вмешательства, его последствиях и результатах оказания медицинской помощи в виде медицинских
      документов (их копий), а также выписок из них в форме электронных документов, файлов и статистических данных
      (далее &ndash; Медицинские документы).
    </Typography.Text>
    <ol>
      <li>
        <Typography.Text strong>
          {' '}
          Согласие выдано Обществу с ограниченной ответственностью &laquo;ЭлНетМед&raquo;{' '}
        </Typography.Text>
        (ООО&nbsp;&laquo;ЭлНетМед&raquo;, ИНН 7842529670, ОГРН 114 784 736 4323) (далее &ndash; Оператор). Я уведомлен,
        что ООО &laquo;ЭлНетМед&raquo; является официальным оператором программного комплекса &laquo;N3.Health&raquo; -
        &laquo;иной информационной системы&raquo;, уполномоченным Министерством здравоохранения Российской Федерации для
        размещения сведений об оказанной медицинской помощи в ЕГИСЗ Минздрава РФ в соответствии с действующим
        законодательством (идентификатор в Реестре Минздрава РФ &ndash; 3 , включения в Реестр - 11.10.2019). Я
        уведомлен, что ООО &laquo;ЭлНетМед&raquo; также является оператором персональных данных, включенным в Реестр
        операторов Роскомнадзора (регистрационный номер 78‑21-008437). Я уведомлен, что программное обеспечение
        N3.Health ООО &laquo;ЭлНетМед&raquo; включено в Реестр российского ПО Министерства цифрового развития, связи и
        массовых коммуникаций Российской Федерации (запись в Реестре № 9016 от 05.02.2021).
        <Typography.Text strong> </Typography.Text>Я уведомлен, что Оператор ПДн имеет право во исполнение своих
        обязательств согласно регламенту электронного взаимодействия медицинских организаций на обмен (прием и передачу)
        персональными данными с медицинскими организациями, имеющими лицензии на осуществление медицинской деятельности
        в Российской Федерации (далее &ndash; Медицинские Организации), с иными прописанными в регламенте участниками
        электронного взаимодействия с использованием машинных носителей информации или по каналам связи, с соблюдением
        мер, обеспечивающих их защиту от несанкционированного доступа, при условии, что их прием и обработка будут
        осуществляться лицом, обязанным сохранять врачебную тайну.
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>
        Мои персональные данные, а также данные моих детей, полученные сервисом Mila (Мила) из ЕСИА или Сбер ID с целью
        идентификации меня как пациента, мною проверены и являются верными.
      </Typography.Text>
    </Typography.Text>
    <ol start={2}>
      <li>
        Я уведомлен, что в соответствии с требованиям п. 5. Правил взаимодействия иных информационных систем,
        предназначенных для сбора, хранения, обработки и предоставления информации, касающейся деятельности медицинских
        организаций и предоставляемых ими услуг, с информационными системами в сфере здравоохранения и медицинскими
        организациями (далее &ndash; Правила взаимодействия иных информационных систем), утвержденных Постановлением
        Правительства РФ от 12 апреля 2018 г. N 447,{' '}
        <Typography.Text strong>
          доступ физических лиц к данным Иной информационной системы &laquo;N3.Health&raquo; обеспечивается с
          использованием подтвержденной учетной записи физического лица в &laquo;Единой системе идентификации и
          аутентификации&raquo; (ЕСИА)
        </Typography.Text>{' '}
        - посредством нажатия на кнопку &laquo;Войти через Госуслуги&raquo; на Сайте и его Сервисах) и получением из нее
        следующих сведений о физическом лице:
      </li>
    </ol>
    <Typography.Text>а)&nbsp;фамилия, имя и отчество (при наличии);</Typography.Text> <br />
    <Typography.Text>б)&nbsp;пол;</Typography.Text> <br />
    <Typography.Text>в)&nbsp;дата рождения;</Typography.Text> <br />
    <Typography.Text>
      г)&nbsp;страховой номер индивидуального лицевого счета застрахованного лица в системе персонифицированного учета
      Пенсионного фонда Российской Федерации;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>д)&nbsp;реквизиты документа, удостоверяющего личность;</Typography.Text> <br />
    <Typography.Text>е)&nbsp;адрес электронной почты (при наличии);</Typography.Text> <br />
    <Typography.Text>ж)&nbsp;номер мобильного телефона (при наличии).</Typography.Text> <br />
    <ol start={3}>
      <li>
        <Typography.Text strong>Настоящее согласие выдано в целях</Typography.Text>{' '}
        <Typography.Text strong>исполнения Оператором следующих функций</Typography.Text> в соответствии с п. 4. Правил
        взаимодействия иных информационных систем, в том числе:
      </li>
    </ol>
    <Typography.Text>
      а)&nbsp;взаимодействия с подсистемами ЕГИСЗ с целью организации предоставления и получения сведений,
      обрабатываемых в ЕГИСЗ, для медицинских организаций и граждан;
    </Typography.Text>
    <br />
    <Typography.Text>
      б)&nbsp;предоставления своим пользователям информации о медицинских организациях, медицинских работниках и
      возможности получения медицинских услуг, предоставляемых медицинскими организациями;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>в)&nbsp;предоставления сервиса записи на прием к врачу и вызова врача на дом;</Typography.Text>
    <Typography.Text>
      г)&nbsp;оказания медицинской помощи с применением телемедицинских технологий, в том числе обеспечение хранения
      материалов, полученных по результатам дистанционного взаимодействия медицинских работников между собой,
      медицинских работников и пациентов (или их законных представителей), включая материалы, направленные для
      проведения консультации (консилиума врачей), медицинские заключения по результатам консультаций и протоколы
      консилиумов врачей, данные, внесенные в медицинскую документацию пациента, данные, формирующиеся в результате
      дистанционного наблюдения за состоянием здоровья пациента иной информационной системы в соответствии с порядком
      организации и оказания медицинской помощи с применением телемедицинских технологий;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>д)&nbsp;информирования граждан об оказанной им медицинской помощи;</Typography.Text> <br />
    <Typography.Text>
      е) предоставления сервисов доступа медицинских работников к информации по вопросам осуществления медицинской
      деятельности, в том числе к нормативным правовым актам и справочной информации в сфере охраны здоровья;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      ж) получения, обработки и предоставления информации о взаимодействии пользователей с медицинскими организациями и
      медицинскими работниками, а также ведения рейтингов медицинских организаций и медицинских работников;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      з)&nbsp;предоставления информации о медицинских организациях и медицинских услугах;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      и)&nbsp;предоставления сервисов, позволяющих гражданам получать агрегированную информацию о состоянии здоровья, а
      также рекомендации по ведению здорового образа жизни;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      к)&nbsp;организации и осуществления информационного обмена в сфере здравоохранения, в том числе по вопросам
      заключения договора об оказании медицинских услуг, получения информированного добровольного согласия на
      медицинское вмешательство или отказа от медицинского вмешательства, оформления первичной учетной документации и
      осуществления расчетов за оказанные медицинские услуги;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      л)&nbsp;предоставления аналитической информации об оказываемой медицинскими организациями медицинской помощи,
      включая медицинские услуги;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      м)&nbsp;приема жалоб, заявлений и предложений от граждан и медицинских работников по вопросам оказания медицинской
      помощи;
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      н)&nbsp;предоставления сервисов и услуг, сопутствующих оказанию медицинской помощи.
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <ol start={4}>
      <li>
        <Typography.Text strong> Настоящим Пользователь подтверждает, что:</Typography.Text>
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <Typography.Text>
      <Typography.Text strong>
        4.1. Ознакомлен и согласен с тем, что передача Медицинских документов, а также вероятно содержащихся в них
        Персональных данных Пользователя, а также лиц, законным представителем которых является Пользователь, может
        осуществляться Оператором{' '}
      </Typography.Text>
      следующим третьим лицам:
    </Typography.Text>
    <Typography.Text>
      - в объеме, самостоятельно определяемым Пользователем: медицинским организациям, при заполнении и отправке Формы
      &laquo;Информация для врача&raquo; в карточке записи на прием в такую медицинскую организацию на Сайте;
    </Typography.Text>
    <Typography.Text>
      - в объеме, самостоятельно определяемым Пользователем: при формировании и передаче Пользователем по своему
      усмотрению ссылок на просмотр медицинских карт Пользователя и лиц, законным представителем которых является
      Пользователь, отображаемых для просмотра в отдельном окне веб-браузера: широкому кругу лиц. При формировании и
      передаче такой ссылки Пользователь подтверждает, осведомлен и согласен, с тем, что Оператор ПДн не несет
      ответственности за<Typography.Text strong> </Typography.Text>раскрытие персональных данных неопределенному кругу
      лиц самим субъектом персональных данных или его законным представителем, доказательства законности последующего
      распространения или иной обработки таких персональных данных лежит на Пользователе и лицах, законным
      представителем которых является Пользователь либо лице, получившим доступ к персональным данным Пользователя.
    </Typography.Text>
    <Typography.Text>
      {' '}
      <br />
      <Typography.Text strong>4.2. </Typography.Text>Уведомлен и согласен с тем, что{' '}
      <Typography.Text strong>Оператор не несет ответственности за содержание </Typography.Text>
      <Typography.Text strong>Медицинских документов </Typography.Text>(Пользователя и лиц, законных представителем
      которых является Пользователь), а также<Typography.Text strong> </Typography.Text>полноту выгрузки сведений,
      передаваемых медицинскими организациями в рамках информационного взаимодействия. Я информирован, что{' '}
      <Typography.Text strong>
        в случае неполного или некорректного отображения на Сайте и/или в его Сервисах Медицинских документов{' '}
      </Typography.Text>
      (Пользователя и лиц, законных представителем которых является Пользователь), я могу обратиться за разъяснениями в
      медицинскую организацию, где были мной получены соответствующие медицинские услуги. В случае, если медицинская
      организация имеет договорные отношения с Оператором и зарегистрирована на Сайте, я могу направить запрос в нее
      через сервис &laquo;Помощь&raquo;, либо через сервис &laquo;Чат с клиникой&raquo; на Сайте.
    </Typography.Text>
    <Typography.Text>
      <br />
      <Typography.Text strong>4.3</Typography.Text>.{' '}
      <Typography.Text strong>
        Уведомлен, что срок хранения ПДн, обрабатываемых Оператором ПДн при оказании медицинских услуг,{' '}
      </Typography.Text>
      соответствует сроку хранения первичных медицинских документов и составляет 25 лет.
    </Typography.Text>
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <ol start={5}>
      <li>
        <Typography.Text strong> </Typography.Text>Согласие дается мной путем включения соответствующей
        &laquo;галочки&raquo; в разделе &laquo;Согласия на доступ к данным&raquo; Сайта, либо нажатия кнопок
        &laquo;Подписать согласие&raquo; или &laquo;Подписать согласие и добавить медкарты для детей&raquo; в форме
        &laquo;Настройка профиля&raquo;, либо нажатия на кнопку &laquo;Подключить госуслуги&raquo;. Настоящим
        подтверждаю, что иных доказательств для подтверждения моего свободного волеизъявления не потребуется.
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <ol start={6}>
      <li>
        <Typography.Text strong> </Typography.Text>Я согласен с тем, что текст данного мной согласия хранится в
        электронном виде в базе данных Сайта и подтверждает факт согласия на обработку персональных данных в
        соответствии с вышеизложенными положениями и беру на себя ответственность за достоверность предоставления
        персональных данных. Настоящим я гарантирую, что указанные мною на Сайте или его Сервисах персональные данные
        принадлежат мне или лицам, законным представителям которых я являюсь. Под персональными данными понимается любая
        информация, сообщённая о себе Пользователем Сайта, на основании которой возможна его идентификация как субъекта
        персональных данных, либо сообщённая Пользователем Сайта о лице, законным представителем которого Пользователь
        является. При использовании персональных данных Оператор руководствуется статьями 23, 24 Конституции Российской
        Федерации, Федеральным законом от 27.07.2006г. № 152-ФЗ &laquo;О персональных данных&raquo;, Федеральным законом
        от 13.03.2006г. № 38-ФЗ &laquo;О рекламе&raquo;, Политикой конфиденциальности и Правилами обработки персональных
        данных, размещенными на Сайте.
      </li>
    </ol>
    <Typography.Text>&nbsp;</Typography.Text>
    <ol start={7}>
      <li>
        <Typography.Text strong> </Typography.Text>Я подтверждаю, что владею информацией о том, что в любой момент в
        течение всего срока действия настоящего согласия
        <Typography.Text strong> я вправе отозвать согласие</Typography.Text> путем выключения соответствующей
        &laquo;галочки&raquo; в разделе &laquo;Согласия на доступ к данным&raquo; в настройках профиля на Сайте,
        отдельно для себя и для каждого лица, законным представителем которого я являюсь. Я информирован, что при
        возникновении вопросов относительно отказа от согласия, я могу обратиться за помощью, отправив запрос в службу
        технической поддержки Оператора через сервис &laquo;Помощь&raquo; на Сайте. Я подтверждаю и согласен с тем, что
        в случае отмены согласия на просмотр медицинских сведений по соответствующему профилю мне не будет доступен
        просмотр Медицинских документов (моих или лица, законным представителем которого я являюсь) в личном кабинете на
        Сайте и его Сервисах.
      </li>
    </ol>
    <Typography.Text>&nbsp;</Typography.Text>
    <ol start={8}>
      <li>
        Настоящее <Typography.Text strong>согласие предоставляется на неограниченный срок</Typography.Text> при
        отсутствии сведений о его отзыве.
      </li>
    </ol>
  </>
);

export const NotificationsAgreement = (
  <>
    <Typography className={styles.AgreementTitleText}>Согласие пользователя на получение уведомлений</Typography>
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <ol>
      <li>
        <Typography.Text strong> </Typography.Text>Я (субъект персональных данных, дееспособное физическое лицо,
        достигшее 18-летнего возраста, далее &ndash; Пользователь), действуя своей волей и в своем интересе,{' '}
        <Typography.Text strong>
          даю свое согласие Обществу с ограниченной ответственностью &laquo;ЭлНетМед&raquo;{' '}
        </Typography.Text>
        (ООО &laquo;ЭлНетМед&raquo;, ИНН 7842529670, ОГРН 114 784 736 4323) (далее &ndash; Оператор), а также
        медицинским организациям и третьим лицам, состоящим с Оператором в договорных отношениях, на направление мне по
        контактным данным (номеру телефона и/или электронной почте), указанным мной при прохождении процедуры
        регистрации на сайте Mila (Мила) по адресу <a href="http://www.mila.online">www.mila.online</a> (далее &ndash;
        Сайт) или через сервисы Сайта, в том числе виджет записи на прием в медицинскую организацию, отображаемый на
        сайте медицинской организации (далее - Сервисы) путем ручного заполнения формы регистрации или авторизации
        посредством Госуслуг (через Единую Систему Идентификации и Аутентификации) или Сбер ID (сервис авторизации от
        ПАО &laquo;Сбербанк&raquo;), сообщений с целью информирования меня о поступлении новых медицинских сведений в
        медицинскую карту на Сайте (мою или лиц, законным представителем которых я являюсь), об изменении статусов
        записей на прием в медицинские организации (моих или лиц, законным представителем которых я являюсь), получения
        ответов на мои запросы по услугам медицинских организаций, сведения о которых опубликованы на Сайте и его
        Сервисах, предоставления персональных рекомендаций на основании сведений в медицинской карте на Сайте, доведения
        сведений о ранее оказанных медицинских услугах, для запроса оценки мной качества оказанных медицинских услуг, о
        появлении новых Сервисов на Сайте, о доступных мне акциях медицинских организаций и др. сообщений
        информационного и рекламного характера, в виде электронных писем, и/или сообщений внутри Сайта и Сервисов, и/или
        сообщений в мессенджерах, и/или push-уведомлений, и/или посредством телефонных звонков.
      </li>
      <li>
        <Typography.Text strong> </Typography.Text>Согласие дается мной путем включения соответствующей
        &laquo;галочки&raquo; в форме регистрации на Сайте или в разделе &laquo;Согласия на доступ к данным&raquo;
        Сайта, либо нажатия кнопки &laquo;Разрешить уведомления&raquo; в форме &laquo;Настройка профиля&raquo;.
        Настоящим подтверждаю, что иных доказательств для подтверждения моего свободного волеизъявления не потребуется.
      </li>
      <li>
        <Typography.Text strong> </Typography.Text>Я согласен с тем, что текст данного мной согласия хранится в
        электронном виде в базе данных Сайта и подтверждает факт согласия на обработку персональных данных в
        соответствии с вышеизложенными положениями и беру на себя ответственность за достоверность предоставления
        персональных данных. Настоящим я гарантирую, что указанные мною на Сайте или его Сервисах абонентские номера
        телефонов принадлежат мне, в случае прекращения использования мною абонентского номера телефона, указанного на
        Сайте или его Сервисах, я обязуюсь проинформировать об этом Оператора. Под персональными данными понимается
        любая информация, сообщённая о себе Пользователем Сайта, на основании которой возможна его идентификация как
        субъекта персональных данных, либо сообщённая Пользователем Сайта о лице, законным представителем которого
        Пользователь является. При использовании персональных данных Оператор руководствуется статьями 23, 24
        Конституции Российской Федерации, Федеральным законом от 27.07.2006г. № 152-ФЗ &laquo;О персональных
        данных&raquo;, Федеральным законом от 13.03.2006г. № 38-ФЗ &laquo;О рекламе&raquo;, Политикой конфиденциальности
        и Правилами обработки персональных данных, размещенными на Сайте.
      </li>
      <li>
        <Typography.Text strong> </Typography.Text>Я подтверждаю, что владею информацией о том, что{' '}
        <Typography.Text strong>
          в любой момент в течение всего срока действия настоящего согласия я вправе отозвать согласие
        </Typography.Text>{' '}
        путем выключения соответствующей &laquo;галочки&raquo; в настройках профиля на Сайте. Также я информирован, что
        при возникновении вопросов относительно отказа от рассылки, я могу обратиться за помощью, отправив запрос в
        службу технической поддержки Оператора через сервис &laquo;Помощь&raquo; на Сайте.
      </li>
      <li>Настоящее согласие предоставляется на неограниченный срок при отсутствии сведений о его отзыве.</li>
    </ol>
  </>
);

export const PersonalDataAgreement = (
  <>
    <Typography className={styles.AgreementTitleText}>
      Согласие пользователя на обработку персональных данных
    </Typography>
    <Typography.Text>
      В соответствии со статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006 № 152-ФЗ
      &laquo;О персональных данных&raquo;, Политикой конфиденциальности, размещенной на сайте Mila (Мила) по адресу:
      www.mila.online,
    </Typography.Text>
    <Typography.Text>
      Я, субъект персональных данных, именуемый в дальнейшем Пользователь, при прохождении процедуры регистрации путем
      заполнения формы регистрации или авторизации посредством Госуслуг (через Единую Систему Идентификации и
      Аутентификации), или авторизации посредством Сбер ID (сервис авторизации от ПАО &laquo;Сбербанк&raquo;) в целях
      использования сайта Mila (Мила) и его сервисов, в том числе виджета записи на прием в медицинскую организацию,
      отображаемого на сайте медицинской организации (далее - Сайт, Сервисы), и в дальнейшем при использовании Сайта
      и/или его Сервисов, отправляя информацию через электронные формы Сайта и Сервисов (далее, каждая из них и все в
      совокупности - Форма, Формы) выражаю полное, безоговорочное и однозначное Согласие на обработку моих персональных
      данных, а также персональных данных лиц, законным представителем которых я являюсь, (далее - Согласие) на
      следующих условиях:
    </Typography.Text>
    <Typography.Text>
      <Typography.Text strong>&nbsp;</Typography.Text>
    </Typography.Text>
    <ol>
      <li>
        <Typography.Text strong>
          {' '}
          Согласие выдано Обществу с ограниченной ответственностью &laquo;ЭлНетМед&raquo;{' '}
        </Typography.Text>
        (ООО &laquo;ЭлНетМед&raquo;, ИНН 7842529670, ОГРН 114 784 736 4323) (далее &ndash; Оператор ПНд). Я уведомлен,
        что ООО &laquo;ЭлНетМед&raquo; является официальным оператором программного комплекса &laquo;N3.Health&raquo; -
        &laquo;иной информационной системы&raquo;, уполномоченным Министерством здравоохранения Российской Федерации для
        размещения сведений об оказанной медицинской помощи в ЕГИСЗ Минздрава РФ в соответствии с действующим
        законодательством (идентификатор в Реестре Минздрава РФ &ndash; 3 , включения в Реестр - 11.10.2019). Я
        уведомлен, что ООО &laquo;ЭлНетМед&raquo; также является оператором персональных данных, включенным в Реестр
        операторов Роскомнадзора (регистрационный номер 78‑21-008437). Я уведомлен, что программное обеспечение
        N3.Health ООО &laquo;ЭлНетМед&raquo; включено в Реестр российского ПО Министерства цифрового развития, связи и
        массовых коммуникаций Российской Федерации (запись в Реестре № 9016 от 05.02.2021).
        <Typography.Text strong> </Typography.Text>Я уведомлен, что Оператор ПДн имеет право во исполнение своих
        обязательств согласно регламенту электронного взаимодействия медицинских организаций на обмен (прием и передачу)
        персональными данными с медицинскими организациями, имеющими лицензии на осуществление медицинской деятельности
        в Российской Федерации (далее &ndash; Медицинские Организации), с иными прописанными в регламенте участниками
        электронного взаимодействия с использованием машинных носителей информации или по каналам связи, с соблюдением
        мер, обеспечивающих их защиту от несанкционированного доступа, при условии, что их прием и обработка будут
        осуществляться лицом, обязанным сохранять врачебную тайну.
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>
        Мои персональные данные, а также данные моих детей, полученные сервисом Mila (Мила) из ЕСИА или Сбер ID с целью
        идентификации меня как пациента, мною проверены и являются верными.
      </Typography.Text>
    </Typography.Text>
    <ol start={2}>
      <li>
        <Typography.Text strong> Согласие выдано на обработку персональных и иных данных</Typography.Text>, указанных
        Пользователем в Формах путем заполнения соответствующих полей и/или прикрепленных к Формам файлов и/или
        полученных из систем авторизации Госуслуг и Сбер ID, а также информационных систем Медицинских Организаций и
        иных участников электронного взаимодействия, а именно следующих категорий:
      </li>
    </ol>
    <Typography.Text>
      - фамилия, имя, отчество (при наличии), пол, дата рождения, адрес электронной почты (e‑mail), номер телефона,
      адрес регистрации, номер документа, удостоверяющего личность, сведения о дате выдачи указанного документа и
      выдавшем его органе, реквизиты полиса ОМС (ДМС), номер СНИЛС и другая аналогичная информация, сообщённая о себе
      Пользователем Сайта, на основании которой возможна его идентификация как субъекта персональных данных, либо
      сообщённая Пользователем Сайта о лице, законным представителем которого Пользователь является),
    </Typography.Text>
    <Typography.Text>
      - сведения о состоянии здоровья, об оказанных и о планируемых медицинских услугах, в том числе для оценки
      Пользователем качества оказания медицинских услуг (в том числе сведения о записях на прием в Медицинские
      Организации, результатах медицинского обследования, наличии заболевания, об установленном диагнозе и о прогнозе
      развития заболевания, методах оказания медицинской помощи, связанном с ними риске, возможных видах медицинского
      вмешательства, его последствиях и результатах оказания медицинской помощи, сведения об инвалидности,
      биометрические персональные данные, сведения о льготах, иные сведения, прямо относящиеся к моей личности и (или) к
      лицу, законным представителем которого я являюсь),
    </Typography.Text>
    <Typography.Text>
      - данных, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес,
      информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес предыдущей
      страницы);
    </Typography.Text>
    <Typography.Text>- статистики о моих IP-адресах.</Typography.Text>
    <ol start={3}>
      <li>
        <Typography.Text strong> Согласие выдано на обработку персональных данных в целях:</Typography.Text>
      </li>
    </ol>
    <Typography.Text>
      - исполнения соглашений по предоставлению доступа к Сайту, его Содержанию и/или Сервисам (в том числе
      персонализированным);
    </Typography.Text>
    <Typography.Text>- идентификации при регистрации на Сайте и/или при использовании Сервисов;</Typography.Text>
    <Typography.Text>
      - оказания услуг, включая формирование, отмену и перенос записей на прием в медицинские организации, в том числе
      для получения медицинских и информационных консультаций с использованием телемедицинских технологий и чатов;
    </Typography.Text>
    <Typography.Text>
      - возможности формирования медицинских карт из загружаемых Пользователем через Формы на Сайте и его Сервисах
      данных и приложенных к ним файлов;
    </Typography.Text>
    <Typography.Text>
      - возможности формирования и передачи Пользователем по усмотрению Пользователя ссылок на просмотр медицинских
      карт;
    </Typography.Text>
    <Typography.Text>
      - возможности совершения операций оплаты за услуги медицинских и информационных консультаций в медицинских
      организациях с использованием телемедицинских технологий;
    </Typography.Text>
    <Typography.Text>
      - информирования Пользователя, в том числе, направления уведомлений, запросов и информации, касающихся
      использования Сайта и Сервисов, оказания услуг медицинскими организациями;
    </Typography.Text>
    <Typography.Text>
      - возможности обработки запросов и заявок Пользователя службой технической поддержки Оператора ПДн и сотрудниками
      медицинских организаций;
    </Typography.Text>
    <Typography.Text>- установления обратной связи, включая направление уведомлений и запросов;</Typography.Text>
    <Typography.Text>- подтверждения полноты предоставленных персональных данных;</Typography.Text>
    <Typography.Text>- возможности принятия оферт, публикуемых на Сайте;</Typography.Text>
    <Typography.Text>
      - проведения статистических и иных исследований, на основе обезличенных данных и в иных целях, определенных видами
      деятельности Оператора ПДн;
    </Typography.Text>
    <Typography.Text>
      - улучшения качества работы Сайта и/или его Сервиса, удобства их использования и разработки новых сервисов и
      услуг;
    </Typography.Text>
    <Typography.Text>
      - возможности получения маркетинговой (рекламной) информации от Оператора ПДн и медицинских организаций на Сайте и
      и/или его Сервисах.
    </Typography.Text>
    <Typography.Text>
      Пользователь настоящим подтверждает, осведомлен и согласен, что для вышеуказанных целей, Оператор ПДн вправе
      собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в процессе доступа
      Пользователя к Сайту, его Содержанию и/или Сервисам, или от третьих лиц, и включающую в себя данные о технических
      средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сайтом и/или его
      Сервисами (в т. ч. IP-адрес хоста, вид операционной системы Пользователя, тип браузера, географическое положение,
      данные о провайдере и иное), об активности Пользователя при использовании Сайта и/или его Сервисов, cookies, об
      информации об ошибках, выдаваемых Пользователю, о скачанных файлах, видео, инструментах, а также иные данные,
      получаемые установленными Правилами обработки ПДн способами; распоряжаться статистической информацией, связанной с
      функционированием Сайта и/или его Сервисов, а также информацией Пользователя для целей организации
      функционирования и технической поддержки Сайта и/или его Сервисов и исполнения условий законодательства Российской
      Федерации, и разработанных в соответствии с ним Правилами обработки ПДн.
    </Typography.Text>
    <ol start={4}>
      <li>
        <Typography.Text strong>
          Согласие выдано на обработку персональных данных смешанным (автоматизированным и неавтоматизированным)
          способом.
        </Typography.Text>
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>
        В процессе обработки персональных данных Оператор ПДн вправе осуществлять:
      </Typography.Text>{' '}
      сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
      передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
      персональных данных Пользователя.
    </Typography.Text>
    <ol start={5}>
      <li>
        <Typography.Text strong> Настоящим, Пользователь подтверждает, что:</Typography.Text>
      </li>
    </ol>
    <Typography.Text>
      <Typography.Text strong>
        5.1. Ознакомлен и согласен с тем, что передача персональных данных Пользователя, а также лиц, законным
        представителем которых является Пользователь, может осуществляться Оператором ПДн{' '}
      </Typography.Text>
      следующим третьим лицам:
    </Typography.Text>
    <Typography.Text>
      - в объеме,<Typography.Text strong> </Typography.Text>необходимом для идентификации Пользователя и возможности
      организации прямого контакта с ним: медицинским организациям, в которые Пользователь направил заявку на запись на
      прием, обращение, запрос или сообщение в чате посредством Сайта и/или его Сервисов, либо хранящих записи на прием
      Пользователя либо лиц, законным представителем которых является Пользователь,
      <Typography.Text strong> </Typography.Text>в собственных информационных системах; медицинским организациям,
      разместившим сведения об оказанной медицинской помощи в отношении Пользователя или лиц, законным представителем
      которых является Пользователь, в ЕГИСЗ Минздрава РФ в соответствии с действующим законодательством;
    </Typography.Text>
    <Typography.Text>
      - в объеме, самостоятельно определяемым Пользователем: медицинским организациям, при заполнении и отправке Формы
      &laquo;Информация для врача&raquo; в карточке записи на прием в такую медицинскую организацию на Сайте;
    </Typography.Text>
    <Typography.Text>
      - в объеме, самостоятельно определяемым Пользователем: при формировании и передаче Пользователем по своему
      усмотрению ссылок на просмотр медицинских карт Пользователя и лиц, законным представителем которых является
      Пользователь, отображаемых для просмотра в отдельном окне веб-браузера: широкому кругу лиц. При формировании и
      передаче такой ссылки Пользователь подтверждает, осведомлен и согласен, с тем, что Оператор ПДн не несет
      ответственности за раскрытие персональных данных неопределенному кругу лиц самим субъектом персональных данных или
      его законным представителем, доказательства законности последующего распространения или иной обработки таких
      персональных данных лежит на Пользователе и лицах, законным представителем которых является Пользователь либо
      лице, получившим доступ к персональным данным Пользователя<Typography.Text strong>.</Typography.Text>
    </Typography.Text>{' '}
    <br />
    <Typography.Text>
      <Typography.Text strong>
        5.2. Уведомлен, что срок хранения ПДн, обрабатываемых Оператором ПДн при оказании медицинских услуг,{' '}
      </Typography.Text>
      соответствует сроку хранения первичных медицинских документов и составляет 25 лет.
    </Typography.Text>
    <Typography.Text>
      {' '}
      <br />
      <Typography.Text strong>5.3. Проинформирован о возможности отзыва согласия </Typography.Text>в соответствии с
      пунктом 5 статьи 21 Закона о персональных данных посредством составления соответствующего письменного заявления,
      которое должно содержать: фамилию, имя, отчество (при наличии), дату рождения, номер документа, удостоверяющего
      личность, сведения о дате выдачи указанного документа и выдавшем его органе либо номер свидетельства о рождении
      несовершеннолетнего, сведения о дате выдачи указанного документа и выдавшем его органе, и может быть направлено
      Пользователем в адрес Оператора ПДн по почте заказным письмом с уведомлением о вручении либо вручено лично под
      расписку представителю Оператора ПДн.
    </Typography.Text>
    <Typography.Text>
      <br />
      <Typography.Text strong>
        5.4. Проинформирован, что лица, указанные в пункте 1 настоящего Согласия, вправе продолжать обработку
        персональных данных
      </Typography.Text>{' '}
      Пользователя после получения отзыва Согласия при наличии оснований, предусмотренных частью 2 статьи 9 Федерального
      закона от 27.07.2006 № 152‑ФЗ &laquo;О персональных данных&raquo;.
    </Typography.Text>
    <br />
    <Typography.Text>
      <Typography.Text strong>
        5.5. Дает согласие на получение уведомлений и информационных материалов на свою электронную почту{' '}
      </Typography.Text>
      и ознакомлен Оператором ПДн о возможности отзыва такого согласия в настройках профиля на Сайте.
    </Typography.Text>
    <ol start={6}>
      <li>
        <Typography.Text strong>
          {' '}
          Согласие вступает в силу с момента его акцепта Пользователем в сервисе{' '}
        </Typography.Text>
        <Typography.Text strong>Mila</Typography.Text>
        <Typography.Text strong>
          {' '}
          и действует в течение неопределенного срока до момента его отзыва Пользователем
        </Typography.Text>
        . Согласие прекращает свое действие с даты, указанной в заявлении Пользователя об отзыве Согласия на обработку
        ПДн, но не ранее даты, следующей за датой фактического получения Оператором ПДн отзыва Согласия.
      </li>
      <li>
        <Typography.Text strong> Датой и временем формирования, подтверждения и акцепта Согласия</Typography.Text> прошу
        считать момент нажатия на кнопку &laquo;Войти через Госуслуги&raquo; и/или &laquo;Войти по Сбер ID&raquo; и/или
        &laquo;Зарегистрироваться&raquo; на Сайте и/или его Сервисах. Настоящим подтверждаю, что иных доказательств для
        подтверждения моего свободного волеизъявления не потребуется.
      </li>
      <li>
        Я согласен с тем, что{' '}
        <Typography.Text strong>
          текст данного мной согласия хранится в электронном виде в базе данных Сайта
        </Typography.Text>{' '}
        и подтверждает факт согласия на обработку персональных данных в соответствии с вышеизложенными положениями и
        беру на себя ответственность за достоверность предоставления персональных данных. Настоящим я гарантирую, что
        указанные мною на Сайте или его Сервисах персональные данные принадлежат мне или лицам, законным представителям
        которых я являюсь.
      </li>
      <li>
        <Typography.Text strong>
          {' '}
          Руководствуясь ч. 4 ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ &laquo;О&nbsp;персональных данных&raquo;
        </Typography.Text>
        , при прохождении процедуры регистрации на Сайте направляю свои персональные данные через личное мобильное
        устройство или персональный компьютер путем заполнения формы регистрации или путем авторизации посредством
        Госуслуг или Сбер ID.
      </li>
    </ol>
  </>
);
