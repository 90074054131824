import { DictionaryItem, DictionarySearchArgs } from 'src/common/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiDictionarySlice = createApi({
  reducerPath: 'milaDictionaryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}/dictionaries`,
  }),

  endpoints: (builder) => ({
    getDictionary: builder.query<DictionaryItem[], string>({
      query: (type) => ({
        url: `?Type=${type}`,
      }),
    }),

    search: builder.query<DictionaryItem[], DictionarySearchArgs>({
      query: ({ type, query }) => `/search?Type=${type}&Query=${query}`,
    }),

    searchMKB10: builder.query<DictionaryItem[], string>({
      query: (query) => `/search?Type=mkb10&Query=${query}`,
    }),

    getFerSpecialities: builder.query<DictionaryItem[], void>({
      query: () => ({
        url: `?Type=ferSpecialities`,
      }),
    }),
  }),
});

export const { useGetDictionaryQuery, useLazySearchQuery, useLazySearchMKB10Query, useGetFerSpecialitiesQuery } =
  apiDictionarySlice;
