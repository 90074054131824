import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CustomSearch } from 'src/common/components/CustomSearch/CustomSearch';
import { CustomSelector } from 'src/common/components/CustomSelector/CustomSelector';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { CustomSteps } from 'src/common/components/CustomSteps/CustomSteps';
import { Layout, Row } from 'antd';
import { NoDataStub } from 'src/common/components/NoDataStub/NoDataStub';
import { SpecialtiesList } from './SpecialtiesList/SpecialtiesList';
import { Specialty } from 'src/common/types';
import { setBranch } from 'src/common/redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetClinicsQuery, useGetSpecialtiesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

const SpecialtiesChoice = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const branchId = useMemo(() => (searchParams.get('branchId') ? searchParams.get('branchId')! : ''), [searchParams]);
  const stateBranch = useAppSelector((state) => state.filter.branchId);
  const region = useAppSelector((state) => state.filter.region);
  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const showChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>('');

  const { data, isFetching } = useGetSpecialtiesQuery({
    serviceType: serviceRequestType!,
    practiceId: selectedBranchId,
    region: region ?? 'Все регионы',
    serveAge: showChildren ? childrenAge : 18
  });

  const [filteredSpecialties, setFilteredSpecialties] = useState<Specialty[] | undefined>(data);

  const { data: clinics } = useGetClinicsQuery({
    region: region ?? 'Все регионы',
  });

  const clinicOptions = useMemo(
    () =>
      clinics?.length && clinics.length > 0
        ? clinics.map((clinic) => ({ label: clinic.shortName, value: clinic.idMedOrganization }))
        : [],
    [clinics],
  );
  const initialSpecialties = useMemo(() => data ?? [], [data]);

  useEffect(() => {
    if (stateBranch) {
      setSelectedBranchId(stateBranch);
    }
    if (branchId) {
      setSelectedBranchId(branchId);
    }
  }, [stateBranch, branchId]);

  useEffect(() => {
    let filtered: Specialty[] = initialSpecialties;
    if (searchValue) {
      filtered = filtered.filter((el) =>
        el.milaSpecialityName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
      );
    }
    setFilteredSpecialties(filtered);
  }, [initialSpecialties, searchValue]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
  };

  const handleSelectChange = (branchId: string) => {
    setSelectedBranchId(branchId);
    dispatch(setBranch(branchId));
  };

  return (
    <>
      <BackHeader title="Выберите специализацию" />
      <CustomSteps current={1} />

      <AgeToggle containerClassName={styles.TabsContainer} />

      <Row className={styles.InteractiveRow}>
        <CustomSearch
          handleSearch={handleSearch}
          className={styles.SearchInput}
          floatLabel="Специализация"
          inputName="specialty"
          value={searchValue}
        />
        <CustomSelector
          floatLabel="Выберите филиал"
          onChange={handleSelectChange}
          className={styles.Select}
          value={selectedBranchId}
          options={clinicOptions}
          disabled={!!branchId}
        />
      </Row>
      <Layout className={styles.SpecialtiesWrapper}>
        {isFetching ? (
          <CustomSpinner />
        ) : filteredSpecialties && filteredSpecialties.length > 0 ? (
          <Layout className={styles.ListWrapper}>
            <SpecialtiesList
              specialties={filteredSpecialties}
              serviceType={serviceRequestType!}
              listItemClass={styles.SpecialtyChoiceListItem}
            />
          </Layout>
        ) : (
          <NoDataStub />
        )}
      </Layout>
    </>
  );
};

export default SpecialtiesChoice;
