import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { ChooseRegion } from 'src/common/components/ChooseRegion/ChooseRegion';
import { ClinicCatalog } from './ClinicCatalog/ClinicCatalog';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSearch } from 'src/common/components/CustomSearch/CustomSearch';
import { DoctorCatalog } from './DoctorCatalog/DoctorCatalog';
import { GlobalSearch } from '../GlobalSearch/GlobalSearch';
import { MyClinicsPreview } from '../Home/MyClinicsPreview/MyClinicsPreview';
import { Row, Typography } from 'antd';
import { Star2 } from 'src/common/icons';
import { useAppSelector } from 'src/app/hooks';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { useLazyGetSearchQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

const items = [
  { key: 'doctors', label: 'Врачи' },
  { key: 'clinics', label: 'Клиники' },
];

const MAX_CLINICS_SHOW = 7;

export const Catalog = () => {
  const [searchInput, setSearchInput] = useState<string>();
  const debouncedSearch = useDebounce(searchInput || '', 300);
  const region = useAppSelector((state) => state.filter.region);

  const [params, setSearchParams] = useSearchParams();

  const tab = useMemo(() => {
    const urlTab = params.get('tab');
    if (urlTab) {
      return urlTab;
    }
    return 'doctors';
  }, [params]);

  const [search, searchQuery] = useLazyGetSearchQuery();

  useEffect(() => {
    if (debouncedSearch) {
      search({ query: debouncedSearch, region: region ?? 'Все регионы' });
    }
  }, [debouncedSearch, region, search]);

  const handleTabChange = (tab: string) => {
    setSearchParams({ tab: tab });
  };

  const handleSearch = async (input: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(input.target.value);
  };

  return (
    <>
      <BackHeader title="Каталог" />
      <MyClinicsPreview maxShow={MAX_CLINICS_SHOW} showCatalogTitle={true}/>
      <Row className={styles.RegionRow}>
        <Typography className={styles.Title}>Клиники и врачи</Typography>
        <ChooseRegion />
      </Row>
      <CustomSearch floatLabel="Специализация, врач, клиника, акция" handleSearch={handleSearch} value={searchInput} />
      {searchInput ? (
        <Row className={styles.SearchRow}>
          <GlobalSearch
            data={searchQuery.currentData}
            isLoading={searchQuery.isLoading || searchQuery.isFetching}
            searchInput={debouncedSearch}
          />
        </Row>
      ) : (
        <>
          <CustomCardTabs items={items} onChange={handleTabChange} activeKey={tab} />
          {tab === 'doctors' ? <DoctorCatalog /> : <ClinicCatalog />}
        </>
      )}
    </>
  );
};
